import * as React from "react";
import { observer } from "mobx-react-lite";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { onError } from "src/common/onError";
import { Empty, Spin, Tabs } from "antd";
import { sampleControlStore as store } from "./SampleControlStore";
import styles from "./SampleControl.module.less";
import { SampleSummary } from "./SampleSummary";
import { SamplePoints } from "./SamplePoints";

interface PropsSampleControl {
  entityData: EntityCardDataExt;
}

export const SampleControl: React.FC<PropsSampleControl> = observer(
  ({ entityData }) => {
    React.useEffect(() => {
      store.init(entityData).catch(onError);
    }, []);
    const { requestTable, activeRequest, buzy } = store;
    return (
      <div className={styles.mainBox}>
        <div>
          <Tabs
            items={store.tabs}
            type="card"
            activeKey={store.activeTab}
            onChange={(active) => {
              store.setCurTab(active);
            }}
          />
        </div>
        <div className={styles.contentFrame}>
          {!!buzy && (
            <div className={styles.spinBox}>
              <Spin size="large" />
            </div>
          )}
          {requestTable ? (
            <div className={styles.contentInbox}>
              {activeRequest ? (
                <SamplePoints
                  requestId={activeRequest.id}
                  store={store.pointsStore}
                  sizeVersions={store.sizeVersions}
                />
              ) : (
                <SampleSummary store={store} tableStore={requestTable} />
              )}
            </div>
          ) : (
            !buzy && <Empty />
          )}
        </div>
      </div>
    );
  },
);
