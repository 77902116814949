import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { ImportOutlined } from "@ant-design/icons";
import { DropdownChildButton } from "src/components/DropdownChildButton";
import { MultiStepTask } from "src/components/MultiStepTask";
import { onError } from "src/common/onError";
import { MultiStepItem } from "src/components/MultiStepTask/MultiStepItem";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import {
  nextStep,
  prevStep,
  lastStep,
} from "src/components/MultiStepTask/utils";
import { MultiStepEntsTable } from "src/components/MultiStepTask/utils/MultiStepEntsTable";
import { BomPosCopyFromStep2 } from "./BomPosCopyFromStep2";
import { BomPosCopyFromStore } from "./BomPosCopyFromStore";
import { BomPosSelectTable } from "../../BomPosSelectTable";

interface PropsBomPosCopyFrom {
  modelObjectId: number;
  objectServiceId: number;
  targetBomId: number;
  onFinish: () => void;
}

export const BomPosCopyFrom: React.FC<PropsBomPosCopyFrom> = observer(
  (props) => {
    const { onFinish, modelObjectId, objectServiceId, targetBomId } = props;
    const store = React.useMemo(
      () =>
        new BomPosCopyFromStore(modelObjectId, objectServiceId, targetBomId),
      [modelObjectId, objectServiceId, targetBomId],
    );
    const start = () => {
      store.init().catch(onError);
    };

    const steps: MultiStepItem[] = [
      {
        title: t("Model"),
        description: t("Select the source instance to copy"),
        next: nextStep(store.firstDisabled),
        drawContent: () => (
          <MultiStepEntsTable>
            <EntityFiltersTable store={store.store1} />,
          </MultiStepEntsTable>
        ),
      },
      {
        title: "BOM",
        description: t("Select the instance to copy"),
        prev: prevStep(),
        next: nextStep(store.secondDisabled),
        drawContent: () => <BomPosCopyFromStep2 store={store} />,
      },
      {
        title: t("Positions"),
        description: t("Select positions to copy"),
        onActive(fromPrevStep) {
          if (!fromPrevStep) {
            store.onPosActive();
          }
        },
        prev: prevStep(),
        next: nextStep(store.thirdDisabled),
        drawContent: () =>
          store.posStore ? <BomPosSelectTable store={store.posStore} /> : "...",
      },
      lastStep(t("Copying"), store.copyInfo, () => store.copy(onFinish)),
    ];

    return (
      <>
        <DropdownChildButton icon={<ImportOutlined />} onClick={start}>
          {t("Copy from")}
        </DropdownChildButton>
        <MultiStepTask
          title={t("Copy from")}
          taskStore={store.taskStore}
          steps={steps}
        />
      </>
    );
  },
);
