import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { authTask, AuthTaskResult } from "src/appStore";
import { registerPageStore } from "src/pages/RegisterPage/RegisterPageStore";

const shell = async <R extends AuthTaskResult>(
  task: () => Promise<R>,
): Promise<R> => (registerPageStore.settingsId ? task() : authTask(task));

export const rest = {
  get<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return shell(() => axios.get(url, config));
  },
  post<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return shell(() => axios.post(url, data, config));
  },
  put<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return shell(() => axios.put(url, data, config));
  },
  delete<T = void, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return shell(() => axios.delete(url, config));
  },
};

export const stdParamsSerializer = {
  indexes: null,
};
