import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";

export const copyEntities = async (params: {
  entities: number[];
  attributes?: number[];
  parentEntityId: number;
}) => {
  await rest.post(apiObjUrl("/entities/list-copy"), params);
};

export const copyEntitiesWithParents = async (params: {
  entities: number[];
  attributes?: number[];
  parentEntities: number[];
}) => {
  await rest.post(apiObjUrl("/entities/list-copy-with-parents"), params);
};
