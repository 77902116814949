import { EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space, Tag } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import TextArea, { TextAreaProps, TextAreaRef } from "antd/es/input/TextArea";
import { MenuProps } from "antd/lib";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import {
  ZFormulaArg,
  transformFormula4Show,
  makeFormulaAttPartView,
} from "src/common/attrEdit/components/formula/common";

type PropsFormulaTemplateInput = Omit<
  TextAreaProps,
  "name" | "onChange" | "value"
> & {
  objectId: number;
  name: string[];
  value?: string;
  onChange?: (v: string) => void;
  attrsList: ZFormulaArg[];
};

export const FormulaTemplateInput2: React.FC<PropsFormulaTemplateInput> =
  observer(({ attrsList, name, value, onChange, ...props }) => {
    const inputRef = useRef<TextAreaRef>(null);
    const form = useFormInstance();

    const init = async () => {
      const view = transformFormula4Show(value || "", attrsList);
      form.setFields([{ name, touched: false, value: view }]);
    };
    useEffect(() => {
      init();
    }, [attrsList]);

    const isEmpty = attrsList.length === 0;
    if (isEmpty)
      return <Tag color="red">Нет атрибутов для использования в формуле</Tag>;

    const items: MenuProps["items"] = attrsList.map((arg) => ({
      key: arg.id,
      label: arg.name,
      onClick: () => {
        const { current } = inputRef;
        const position =
          current?.resizableTextArea?.textArea?.selectionStart || 0;
        const safeValue = value || "";
        const newFormula =
          safeValue.slice(0, position) +
          makeFormulaAttPartView(arg) +
          safeValue.slice(position);
        onChange?.(newFormula);
      },
    }));
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <TextArea
          {...props}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          ref={inputRef}
        />
        <Dropdown menu={{ items }}>
          <Button>
            <EditOutlined />
          </Button>
        </Dropdown>
      </Space>
    );
  });
