import { hardValue } from "src/common/attrEdit/transforms";
import { zAttrFinancialValue } from "src/common/attributes/valueTypes";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntityField } from "src/types/ZEntity";
import { z } from "zod";
import { createFinancialViewStore } from "../components/FinancialView/createFinancialViewStore";
import { composeLabelsWithEditConfig } from "../createItemView/composeLabelWithConf";
import { entityField2ViewValue } from "../transforms";
import { makePropsFinancialViewStoreStd } from "../components/FinancialView/FinancialViewStoreStd";
import { getViewInfo } from "../getViewInfo";
import { BuildCurrencyLabelStrategy } from "../components/FinancialView/BuildCurrencyLabelStrategy";

export const zAttrFinancialView = z.object({
  view: z.literal("FinancialView"),
  labelAttr: z.string(),
  signLocation: z.string(),
});
export type ZAttrFinancialView = z.infer<typeof zAttrFinancialView>;

export const makeFinancialViewProps = (
  efield: ZEntityField,
  attribute: ZAttribute,
  typesMap: Record<number, string>,
) => {
  const viewInfo = getViewInfo(attribute.viewType);
  if (viewInfo?.component?.view !== "FinancialView") throw Error("wrong view");
  const atts4Show = [viewInfo.component.labelAttr].filter(Boolean).map(String);
  const currencySignLocationAttr = viewInfo.component.signLocation || "";
  const finValue = zAttrFinancialValue.parse(
    entityField2ViewValue(attribute, efield),
  );
  finValue.moneyValue = composeLabelsWithEditConfig(
    attribute,
    hardValue(finValue.moneyValue) || [],
    typesMap,
  );
  const currLabelStrat = new BuildCurrencyLabelStrategy();
  currLabelStrat.bind(finValue, atts4Show, currencySignLocationAttr);
  return {
    store: createFinancialViewStore(
      makePropsFinancialViewStoreStd(currLabelStrat),
    ),
  };
};
