// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-MultiStepTask-MultiStepTask-module__drawer--N2XiR .ant-drawer-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  gap: 24px;
}
.src-components-MultiStepTask-MultiStepTask-module__drawer--N2XiR .ant-steps-item-description {
  max-width: 350px !important;
}
.src-components-MultiStepTask-MultiStepTask-module__drawer--N2XiR .src-components-MultiStepTask-MultiStepTask-module__content--wqXPi {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-components-MultiStepTask-MultiStepTask-module__drawer--N2XiR .src-components-MultiStepTask-MultiStepTask-module__currentStep--uEuRq {
  flex: 1;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/MultiStepTask/MultiStepTask.module.less"],"names":[],"mappings":"AAAA;EAEQ,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;AAAR;AANA;EAUQ,2BAAA;AADR;AATA;EAcQ,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AAFR;AAfA;EAoBQ,OAAA;EACA,cAAA;AAFR","sourcesContent":[".drawer {\n    :global(.ant-drawer-body) {\n        display: flex;\n        flex-direction: column;\n        overflow: hidden;\n        height: 100%;\n        gap: 24px;\n    }\n    \n    :global(.ant-steps-item-description) {\n        max-width: 350px !important;\n    }\n\n    .content {\n        height: 100%;\n        overflow: hidden;\n        display: flex;\n        flex-direction: column;\n    }\n    .currentStep {\n        flex: 1;\n        overflow: auto;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": `src-components-MultiStepTask-MultiStepTask-module__drawer--N2XiR`,
	"content": `src-components-MultiStepTask-MultiStepTask-module__content--wqXPi`,
	"currentStep": `src-components-MultiStepTask-MultiStepTask-module__currentStep--uEuRq`
};
export default ___CSS_LOADER_EXPORT___;
