import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import styles from "./AttrLinked.module.less";
import { AttrLinkedListView } from "./AttrLinkedListView";
import { createAttrLinkedStore } from "./AttrLinkedStore";

/**
 * Выводит значения связанного атрибута. Согласно договорённости с Максом,
 * пока что выводим "сырые" значения простых типов без учета их настроек.
 * Остальная доработка будет в след. спринте
 */
type PropsAttrLinked = {
  attr: ZAttribute;
  entityId?: number;
};

export const AttrLinked: React.FC<PropsAttrLinked> = observer(
  ({ attr, entityId }) => {
    const store = useMemo(() => {
      if (entityId) return createAttrLinkedStore(entityId, attr);
      return null;
    }, [entityId]);
    if (!store) return null;
    return (
      <div className={styles.linkList}>
        <AttrLinkedListView store={store} />
      </div>
    );
  },
);
