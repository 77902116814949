import { observer } from "mobx-react-lite";
import React from "react";
import {
  FormObjectSection,
  FormObjectSectionsStore,
} from "src/components/Forms/FormObjectSection";
import { AsrtTableObjType } from "../AsrtTableSettingsStore";
import { ZAsrtTableSettings } from "../../AsrtTableTypes";

interface PropsAsrtTableSection {
  store: FormObjectSectionsStore<AsrtTableObjType>;
}

export const AsrtTableSection: React.FC<PropsAsrtTableSection> = observer(
  ({ store }) => (
    <FormObjectSection<ZAsrtTableSettings, AsrtTableObjType>
      store={store}
      objKey={["objAssortmentTable"]}
      objType="asrtTable"
      objLabel="Assortment table object"
      title="Assortment table"
    />
  ),
);
