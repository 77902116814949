// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-BomPosSelectTable-BomPosSelectTable-module__table--VXVtF {
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-BomPosSelectTable-BomPosSelectTable-module__thead--MtsY1 {
  display: grid;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Position-BomPosSelectTable-BomPosSelectTable-module__tbody--gA3bS {
  display: grid;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Position/BomPosSelectTable/BomPosSelectTable.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACA;EACI,aAAA;AACJ;AACA;EACI,aAAA;AACJ","sourcesContent":[".table {\n    display: flex;\n    flex-direction: column;\n}\n.thead {\n    display: grid;\n}\n.tbody {\n    display: grid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-BomPosSelectTable-BomPosSelectTable-module__table--VXVtF`,
	"thead": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-BomPosSelectTable-BomPosSelectTable-module__thead--MtsY1`,
	"tbody": `src-businessServices-services-bsBom2-Bom2Control-Bom2Position-BomPosSelectTable-BomPosSelectTable-module__tbody--gA3bS`
};
export default ___CSS_LOADER_EXPORT___;
