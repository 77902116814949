import * as React from "react";
import { observer } from "mobx-react-lite";
import { TableStore } from "src/components/tables/TableStore";
import { Button } from "antd";
import {
  ZEntityFilters,
  ZEntityRow,
} from "src/pages/EntityFiltersPage/EntityList/types";
import axios from "axios";
import { apiObjUrl } from "src/common/apiUrl";
import { appStore } from "src/appStore";
import { ExportIconStd } from "src/common/icons";
import { t } from "i18next";
import { stdParamsSerializer } from "src/common/rest";
import styles from "./EntityExportButton.module.less";
import { formatSortParam } from "../../../../components/tables/utils/formatParams";

interface PropsEntityExportButton {
  store: TableStore<ZEntityRow, ZEntityFilters>;
  objectName: string;
}

const createExportUrl = (
  store: TableStore<ZEntityRow, ZEntityFilters>,
): string => {
  const columnIds = store.finalColumns.map((col) => Number(col.key));
  const params = {
    objectId: store.filters?.objectId,
    stateId: store.filters?.stateId,
    dictionaryValueIds: JSON.stringify(store.filters?.dictionaryValueIds || []),
    query: store.filters?.query,
    sorting: formatSortParam(store.params) ?? undefined,
    columnIds,
    entityIds: store.selected.map(({ id }) => id),
    lang: appStore.curLang,
  };

  return axios.getUri({
    url: apiObjUrl("/entities/export"),
    params,
    paramsSerializer: stdParamsSerializer,
  });
};

export const EntityExportButton: React.FC<PropsEntityExportButton> = observer(
  ({ store, objectName }) => (
    <Button
      className={styles.container}
      type="link"
      icon={<ExportIconStd />}
      download={`${objectName}.xlsx`}
      href={createExportUrl(store)}
    >
      {t("Table export")}
    </Button>
  ),
);
