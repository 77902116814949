// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-MainFrame-Header-ExtNotoficationsPopup-NotifyAttachment-NotifyAttachment-module__stdBox--Kxwel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.src-components-MainFrame-Header-ExtNotoficationsPopup-NotifyAttachment-NotifyAttachment-module__stdBox--Kxwel img {
  padding-right: 4px;
}
.src-components-MainFrame-Header-ExtNotoficationsPopup-NotifyAttachment-NotifyAttachment-module__imgBox--qMq0O {
  display: flex;
  align-items: flex-end;
  gap: 4px;
}
.src-components-MainFrame-Header-ExtNotoficationsPopup-NotifyAttachment-NotifyAttachment-module__msg--e9a9h {
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainFrame/Header/ExtNotoficationsPopup/NotifyAttachment/NotifyAttachment.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AAJA;EAKI,kBAAA;AAEJ;AAEA;EACE,aAAA;EACA,qBAAA;EACA,QAAA;AAAF;AAGA;EACE,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADF","sourcesContent":[".stdBox {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  img {\n    padding-right: 4px;\n  }\n}\n\n.imgBox {\n  display: flex;\n  align-items: flex-end;\n  gap: 4px;\n}\n\n.msg {\n  color: rgba(0, 0, 0, 0.55);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stdBox": `src-components-MainFrame-Header-ExtNotoficationsPopup-NotifyAttachment-NotifyAttachment-module__stdBox--Kxwel`,
	"imgBox": `src-components-MainFrame-Header-ExtNotoficationsPopup-NotifyAttachment-NotifyAttachment-module__imgBox--qMq0O`,
	"msg": `src-components-MainFrame-Header-ExtNotoficationsPopup-NotifyAttachment-NotifyAttachment-module__msg--e9a9h`
};
export default ___CSS_LOADER_EXPORT___;
