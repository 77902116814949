import * as React from "react";
import { Flex } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { StepButton } from "../MultiStepItem";

export const prevStep = (): StepButton => ({
  text: (
    <Flex gap={8}>
      <LeftOutlined />
      <span>{t("Previous step")}</span>
    </Flex>
  ),
});
