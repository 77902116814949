import { TimePicker, TimePickerProps } from "antd";
import { Dayjs } from "dayjs";
import * as React from "react";
import { ed2NotifyTime, notifyTime2Ed, notifyTimeFormat } from "./EdNotifyTime";
import styles from "./NotifyTimePicker.module.less";

export type PropsNotifyTimePicker = Omit<
  TimePickerProps,
  "onChange" | "value"
> & {
  value?: string | null;
  onChange?(newValue: string | null | undefined): void;
};

export const NotifyTimePicker: React.FC<PropsNotifyTimePicker> = (props) => {
  const { value, onChange, ...other } = props;
  const timeValue = notifyTime2Ed(value);
  const onChangeInternal = (dt: Dayjs | null) => onChange?.(ed2NotifyTime(dt));
  return (
    <TimePicker
      className={styles.timePicker}
      value={timeValue}
      onChange={onChangeInternal}
      format={notifyTimeFormat}
      {...other}
    />
  );
};
