import * as React from "react";
import { t } from "i18next";
import {
  ZExtNotification,
  ZFileInNotification,
} from "src/types/ZExtNotification";
import { ImgProportional } from "src/components/ImgProportional";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { createFileAccessUrl } from "src/businessServices/services/bsChat/ChatForm/ChatMsgList/ChatMsgGroup/defineAttachment/createFileAccessUrl";
import { OptionalLink } from "../OptionalLink";
import iconAttachment from "./attachment.svg";
import styles from "./NotifyAttachment.module.less";

interface PropsNotifyAttachment {
  info: ZExtNotification;
  onLink: () => void;
}

let imageTypes: Set<string>;

const makeFileUrl = (file: ZFileInNotification): string => {
  if (file.source === "CORE" && file.path) {
    return imgSrc(file.path);
  }
  if (file.source === "CHAT" && file.path) {
    return createFileAccessUrl(`/file-upload/${file.path}/${file.name}`);
  }
  return "";
};

export const NotifyAttachment: React.FC<PropsNotifyAttachment> = ({
  info,
  onLink,
}) => {
  imageTypes = imageTypes || new Set(["png", "jpg", "jpeg", "gif"]);
  const { file } = info;
  if (!file) return null;
  if (file.path && imageTypes.has(file.format.toLowerCase())) {
    return (
      <div className={styles.imgBox}>
        <ImgProportional
          src={makeFileUrl(file)}
          maxWidth={100}
          maxHeight={100}
        />
        <OptionalLink info={info} onClick={onLink} owner="attachment" />
      </div>
    );
  }
  return (
    <div className={styles.stdBox}>
      <img src={iconAttachment} alt="" />
      <span className={styles.msg}>
        {t("The message contains an attachment")}
      </span>
      <OptionalLink info={info} onClick={onLink} owner="attachment" />
    </div>
  );
};
