import React, { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Button, Drawer, Form, Space, Spin } from "antd";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { ZAttrViewInfo } from "src/common/attrView";
import { makeAutoObservable } from "mobx";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { AsyncTableView } from "src/pages/EntityFiltersPage/AsyncTableView";
import { ZAttribute } from "src/types/ZAttribute";
import { makeDictNameById } from "src/types/AttrType";
import { getIdNames } from "src/references/getIdNames";
import { ZObjectRefTable } from "src/common/attrEdit/components";
import { createColsFromAttrMeta } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore2";
import { loadEntity } from "src/pages/EntityCardPage/apiEntityCard";
import {
  loadObjectStatesInsideRefLikeAtts,
  makeObjectStatesMap,
} from "src/pages/ManagementPage/Obj2Tab/roles/rolesApi";
import { useNavigate } from "react-router-dom";
import { PageUrl } from "src/routes/PageUrl";
import { makeUrl } from "src/routes/makeUrl";
import { onError } from "src/common/onError";
import { ObjectRefTableStore } from "./ObjectRefTableStore";
import { TableStore } from "../tables/TableStore";
import { applyParams } from "../tables/singleLoader";
import styles from "./ObjectRefTable.module.less";

const createSettingKey = (attrId: number, objectId: number) =>
  `objRefTableSettings${attrId}-${objectId}`;

const createStore = () =>
  makeAutoObservable({
    tableStore: null as TableStore<ZEntityRow, {}> | null,
    values: [] as string[],
    setValues(list: string[]) {
      this.values = list;
      this.tableStore?.reload();
    },
    async init(
      objectId: number,
      attrId: number,
      colAtts: ZAttribute[],
      values: string[],
    ) {
      this.tableStore = new TableStore<ZEntityRow, {}>({
        rowKey: "id",
        fnLoad: async (params) => {
          const entityList = await Promise.all(
            this.values.map((id) => loadEntity(Number(id))),
          );
          return applyParams(entityList, params, () => true);
        },
        resizableColumns: true,
        settingsKey: createSettingKey(attrId, objectId),
      });
      const attrTypeMap = makeDictNameById(await getIdNames("attrType"));
      const statesMap = makeObjectStatesMap(
        await loadObjectStatesInsideRefLikeAtts(objectId),
      );
      /**
       * Столбцы формируются таким же образом, как и для всех таблиц, это значит,
       * что учитываются ВСЕ настройки отображения атрибутов
       */
      const cols = createColsFromAttrMeta(colAtts, attrTypeMap, statesMap, {
        withoutStatus: true,
      });
      this.tableStore.setColumns(cols);
      this.setValues(values);
    },
  });

type PropsObjectRefTable = {
  attrInfo: ZAttribute;
  component: ZObjectRefTable;
  viewInfo: ZAttrViewInfo | undefined;
  value?: string[];
  disabled?: boolean;
  onChange?: (value?: string[]) => void;
  label: string;
};

export const ObjectRefTable: React.FC<PropsObjectRefTable> = observer(
  ({ attrInfo, component, value, disabled, viewInfo, onChange, label }) => {
    const [open, setOpen] = useState(false);
    const cmpStore = useMemo(() => new ObjectRefTableStore(), []);
    const listStore = useMemo(() => createStore(), []);
    const form = Form.useFormInstance();
    const filterField: string[] | null | undefined = Form.useWatch(
      String(attrInfo.filter),
      form,
    );
    const selectDisabled =
      (attrInfo.filter && !filterField?.length) || disabled;

    const { filtersPageStore } = cmpStore;
    const { tableStore } = listStore;

    const inited = !!filtersPageStore && !!tableStore;

    const init = async () => {
      if (!viewInfo || !component || !attrInfo || !attrInfo.referenceId) return;
      await cmpStore.init(attrInfo, component, viewInfo, value);
      const { labelAtts } = component;

      const colAtts = labelAtts
        ?.map((id) => cmpStore.attrsMap[Number(id)])
        .filter(Boolean);

      listStore
        ?.init(
          attrInfo.referenceId,
          attrInfo.id,
          colAtts as ZAttribute[],
          value || [],
        )
        .catch(onError);
    };

    useEffect(() => {
      init().catch(onError);
    }, [attrInfo.referenceId]);

    const syncWithValues = (vals: string[]) => {
      listStore.setValues(vals);
      cmpStore.syncSelected(vals);
    };

    useEffect(() => {
      syncWithValues(value || []);
    }, [value]);

    useEffect(() => {
      if (open) {
        cmpStore.addFilters(form);
        cmpStore.setInitialValues(value || []);
      }
    }, [open]);

    const handleClose = () => {
      cmpStore.syncSelected(cmpStore.initialValues || []);
      setOpen(false);
    };

    const handleOk = () => {
      const newValues = filtersPageStore?.tableStore?.selected.map(({ id }) =>
        String(id),
      );
      onChange?.(newValues || []);
      cmpStore.setInitialValues(newValues || []);
      setOpen(false);
    };

    const navigate = useNavigate();

    if (!inited) return <Spin>{t("Loading")}...</Spin>;

    return (
      <div className={styles.box}>
        <AsyncTableView<ZEntityRow, {}>
          store={tableStore}
          columns={tableStore.columns}
          useHeader
          usePagination
          onRowClick={({ id }) => navigate(makeUrl(PageUrl.entityCard, { id }))}
        />
        <Button
          className={styles.selectBtn}
          onClick={() => {
            setOpen(true);
          }}
          disabled={selectDisabled}
          loading={cmpStore.isLoading}
        >
          {t("Select")}
        </Button>
        <Drawer
          width="75vw"
          open={open && !selectDisabled}
          onClose={handleClose}
          className={styles.drawer}
          title={label}
          forceRender={!!attrInfo.filter}
          footer={
            <Space>
              <Button type="primary" onClick={handleOk}>
                {t("Save")}
              </Button>
              <Button onClick={handleClose}>{t("Cancel")}</Button>
            </Space>
          }
        >
          <EntityFiltersTable store={filtersPageStore} />
        </Drawer>
      </div>
    );
  },
);

ObjectRefTable.defaultProps = {
  value: undefined,
  onChange: undefined,
};
