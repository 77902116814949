import React from "react";
import { useForm } from "antd/es/form/Form";
import { Button, Form, Input, Modal, Space } from "antd";
import { onError } from "src/common/onError";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import styles from "./ChatSettingsMenu.module.less";
import { RCNewChatFormData } from "../../bsChat.types";
import { PersonSelectComplex } from "../../NewChatButton/PersonSelectComplex";
import { PersonComplexStore } from "../../NewChatButton/PersonComplexStore";

type PropsChatSettingsModal = {
  onClose: () => void;
  submit: (data: RCNewChatFormData) => Promise<unknown>;
  modalTitle: string;
  okBtnText: string;
  complexStore: PersonComplexStore;
  initial?: Partial<RCNewChatFormData>;
};

export const ChatSettingsModal: React.FC<PropsChatSettingsModal> = observer(
  ({ modalTitle, okBtnText, initial, complexStore, onClose, submit }) => {
    const [form] = useForm();

    const handleCreate = (data: RCNewChatFormData) => {
      submit(data)
        .then(() => {
          form.resetFields();
          onClose();
        })
        .catch(onError);
    };
    return (
      <Modal
        open
        title={
          <div className={styles.header}>
            {modalTitle}
            <Space>
              <Form.Item>
                <Button htmlType="button" onClick={onClose}>
                  {t("Cancel")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => form.submit()}
                >
                  {okBtnText}
                </Button>
              </Form.Item>
            </Space>
          </div>
        }
        closable={false}
        footer={null}
      >
        <Form<RCNewChatFormData>
          form={form}
          layout="vertical"
          className={styles.form}
          onFinish={handleCreate}
          initialValues={initial}
        >
          <Form.Item
            name="name"
            label={t("Heading")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t("Members")} name="userIds">
            <PersonSelectComplex store={complexStore} />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
