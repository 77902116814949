import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { confirmDelete } from "src/components/ModalDelete";
import { Bom2PositionStore } from "./Bom2PositionStore";
import { Bom2Table } from "./Bom2Table";
import styles from "./Bom2Position.module.less";
import { BomPosActions } from "./BomPosActions";

interface PropsBom2Position {
  modelObjectId: number | undefined;
  objectServiceId: number;
  store: Bom2PositionStore;
}

export const Bom2Position: React.FC<PropsBom2Position> = observer((props) => {
  const { modelObjectId, objectServiceId, store } = props;
  return (
    <div className={styles.bomPosBox}>
      <div className={styles.bomPosHeader}>
        <div className={styles.leftTop}>
          {t("Selected lines count", { count: store.selected.size })}
        </div>
        <Button
          danger
          icon={<DeleteOutlined />}
          disabled={!store.canDelete}
          loading={store.buzy === "delete"}
          onClick={() =>
            confirmDelete(t("Are you sure to delete selected positions?"), () =>
              store.doDelete().catch(onError),
            )
          }
        >
          {t("Delete")}
        </Button>
        <BomPosActions
          modelObjectId={modelObjectId}
          objectServiceId={objectServiceId}
          currentBomId={store.bom.id}
          reloadPositions={() => store.reload().catch(onError)}
          selectedPositions={Array.from(store.selected)}
        />
      </div>
      <div className={styles.bomPosNav} />
      <div className={styles.bomPosMain}>
        <Bom2Table store={store} />
      </div>
    </div>
  );
});
