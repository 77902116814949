import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { getAuthRCUserId } from "./apiChat";
import { ChatListStore } from "./ChatListStore";
import { makeCounterStoreStd } from "./CounterUnreadMessages/makeCounterStoreStd";
import { makeСountersStoreEventListenerAdapter } from "./CounterUnreadMessages/makeСountersStoreEventListenerAdapter";
import { makePersonComplexStore } from "./NewChatButton/PersonComplexStore";

/**
 * Стор чатов должен быть инициальзирован еще до открытия табы с БС.
 * Решил оставить вариант с кешем, потому что иначе придется переписать
 * много кода. Все сторы принимают в конструктор некоторые параметры, а для отложенной загрузки
 * придется всем им добавлать init(data), чтобы можно было вызывать на useEffect.
 * Так что при изменении карточки просто создается новый стор
 */
let cache: [string, ChatListStore] | null = null;
export const getCachedChatListStore = (
  info: ObjectServiceExt,
  data: EntityCardDataExt,
) => {
  const entityId = String(data.entity?.id);
  if (cache?.[0] === entityId) {
    return cache[1];
  }
  const countersStore = makeCounterStoreStd({
    entityId,
  });
  const countersEventListener = makeСountersStoreEventListenerAdapter(
    countersStore,
    getAuthRCUserId(),
    entityId,
  );
  const personComplexStore = makePersonComplexStore();
  const chatListStore = new ChatListStore({
    info,
    countersStore,
    countersEventListener,
    personComplexStore,
  });
  chatListStore.rcSocket.addListener(countersEventListener);
  chatListStore.init(entityId, info.name, data);
  cache = [entityId, chatListStore];
  return cache[1];
};
export const clearCachedChatListStore = () => {
  cache = null;
};
