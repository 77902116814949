import { RCEventListener } from "../rocketChat/RCEventListener";
import { RCSChangeEvent } from "../rocketChat/rocketChat.types";
import { CountersStore } from "./CountersStore";

type PropsAdapter = {
  countersStore: CountersStore;
  currentUserId: string;
  listenerId: string;
};

class CountersStoreEventListenerAdapter implements RCEventListener {
  constructor(readonly params: PropsAdapter) {}

  currentChatId: string = "";

  setCurrentChatId(id: string) {
    this.currentChatId = id;
  }

  onNewMessage(msg: RCSChangeEvent): void {
    const { countersStore, currentUserId } = this.params;
    const isCurrentUser = msg.fields.args.find(
      // eslint-disable-next-line no-underscore-dangle
      (a) => a.u?._id === currentUserId,
    );
    const isNewMsg = !msg.fields.args.find((a) => !!a.t);
    if (isCurrentUser || !isNewMsg) return;
    const roomId = msg.fields.eventName;
    this.params.listenerId = roomId;
    const data = countersStore.getCountersData();
    const roomsIds = new Map(data?.unReads.map((e) => [e.roomId, e]));
    const roomWithUnreads = roomsIds.get(this.params.listenerId);
    if (roomWithUnreads) {
      roomWithUnreads.unreadMessagesCount += 1;
    } else {
      roomsIds.set(this.params.listenerId, {
        roomId: this.params.listenerId,
        unreadMessagesCount: 1,
      });
    }
    countersStore.onChange({
      ...data,
      unReads: Array.from(roomsIds.values()),
    });
  }
}

const makeListenerId = (chatId: string) => `${chatId}-counters-listener`;

export const makeСountersStoreEventListenerAdapter = (
  countersStore: CountersStore,
  currentUserId: string,
  chatId: string,
): RCEventListener =>
  new CountersStoreEventListenerAdapter({
    countersStore,
    currentUserId,
    listenerId: makeListenerId(chatId),
  });
