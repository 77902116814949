import * as React from "react";
import { observer } from "mobx-react-lite";
import { AsyncTable } from "src/components/tables/AsyncTable";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { bomSummaryColumns } from "../../Bom2Summary";
import { BomSummaryCopyFromStore } from "./BomSummaryCopyFromStore";

interface PropsBomSummaryCopyFromStep2 {
  store: BomSummaryCopyFromStore;
}

export const BomSummaryCopyFromStep2: React.FC<PropsBomSummaryCopyFromStep2> =
  observer(({ store }) => {
    const { store2 } = store;
    const columns = React.useMemo(bomSummaryColumns, []);
    if (!store2) return null;
    return (
      <AsyncTable
        store={store2}
        columns={columns}
        onRowClick={(row) => selectOnRowClick(row, store2)}
      />
    );
  });
