import { Tag } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { getEditorInfo } from "src/common/attrEdit";
import { getViewInfo, ZAttrViewInfo } from "src/common/attrView";
import { createItemView2 } from "src/common/attrView/createItemView/createItemView2";
import { AttrLinkedStore } from "./AttrLinkedStore";

type PropsAttrLinkedListView = {
  store: AttrLinkedStore;
};

export const AttrLinkedListView: React.FC<PropsAttrLinkedListView> = observer(
  ({ store }) => {
    const { linkedAttrData, attr, entity } = store;
    if (!linkedAttrData || !entity) return null;

    const viewInfo = getViewInfo(linkedAttrData.attribute.viewType);
    const editorInfo = getEditorInfo(attr.viewStyles);
    if (editorInfo?.component?.editor !== "LinkedValue")
      return <Tag color="red">config error</Tag>;
    const newViewInfo = {
      ...viewInfo,
      appearance: {
        ...viewInfo?.appearance,
        view: {
          overflow: "elipsis",
          separator: editorInfo?.component.separator,
        },
      },
    } as ZAttrViewInfo;

    return createItemView2(
      linkedAttrData.attribute,
      store.typesMap,
      {
        ...entity,
        attributeValues: [
          {
            attributeId: linkedAttrData.attribute.id,
            values: linkedAttrData.values,
          },
        ],
      },
      newViewInfo,
    );
  },
);
