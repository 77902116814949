import { ZDelayedNotifyToSave, ZDelayedNotify } from "./ZDelayedNotify";

export type EdDelayedNotify = Omit<ZDelayedNotifyToSave, "stateId">;
export type EdDelayedNotifyDict = Record<number, EdDelayedNotify[]>;

export const delayedNotify2ed = (
  list: ZDelayedNotify[],
): EdDelayedNotifyDict => {
  const dict: EdDelayedNotifyDict = {};
  list.forEach(({ stateId, ...rest }) => {
    dict[stateId] = dict[stateId] ?? [];
    dict[stateId]?.push(rest);
  });
  return dict;
};

export const ed2delayedNotify = (
  dict: EdDelayedNotifyDict,
): ZDelayedNotifyToSave[] => {
  const list: ZDelayedNotifyToSave[][] = Object.entries(dict).map(
    ([stateId, t]: [string, EdDelayedNotify[]]) =>
      t.map((it) => ({ stateId: +stateId, ...it })),
  );
  return list.flatMap((item) => item);
};
