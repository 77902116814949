import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { Avatar, Button, Form, Space, Spin, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { RetweetOutlined, SendOutlined } from "@ant-design/icons";
import { useForm, useWatch } from "antd/es/form/Form";
import { onError } from "src/common/onError";
import { ChatListStore } from "src/businessServices/services/bsChat/ChatListStore";
import { debounce, DebounceCounter } from "src/common/debounce";
import styles from "./ChatForm.module.less";
import { ChatFormStore, RCFormData } from "./ChatFormStore";
import { ChatMsgList } from "./ChatMsgList/ChatMsgList";
import { RCUploader } from "./RCUploader";
import { rcUrl } from "../apiChat";
import { allowedFileTypes } from "./allowedFileTypes";
import { splitMessageIntoChunks } from "./splitMessageIntoChunks";
import { MentionList } from "./MentionList/MentionList";
import { chatFormNames } from "./chatFromNames";
import { hasMention } from "./MentionList/mentionUtils";
import { MemberList } from "./MemberList";

/**
 * Таким образом происходит реплай.
 * Это было выяснено опытным путем, лучше такой опыт не повторять...
 * Сейчас стало понятно, что в реплай нужно указывать ОРИГИНАЛЬНЫЙ адрес чата, при чем вместе с портом...
 * то есть под прикрытием nginx это работать не будет
 */
export const prepMsgReply = (rid: string, msgId: string, msg: string) =>
  `[ ](${window.location.origin}${rcUrl("")}/channel/${rid}?msg=${msgId})\n${msg}`;

const debounceCounter: DebounceCounter = { counter: 0 };
const maxMessageLength = 5000;

type PropsChatForm = {
  store: ChatFormStore;
  headerExtra: React.ReactNode;
  chatListStore: ChatListStore;
};

export const ChatForm: React.FC<PropsChatForm> = observer(
  ({ store, headerExtra, chatListStore }) => {
    const {
      props: { personComplexStore, countersStore },
    } = chatListStore;
    const [form] = useForm();
    const msg = useWatch([chatFormNames.message], form);
    const [isSending, setIsSending] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      const savedMessage = chatListStore.getMessage(store.listenerId);
      form.setFieldsValue({ message: savedMessage });
      store.restoreReplyForChat();
    }, [store.listenerId, form, chatListStore]);

    useEffect(() => {
      store.init();
    }, [store]);

    if (store.loading) return <Spin spinning />;

    const handleSubmit = async (formData: RCFormData) => {
      try {
        setIsSending(true);
        const containInfo = formData.message || formData.file;
        if (!containInfo) return;
        const chunks = splitMessageIntoChunks(formData, maxMessageLength);
        const promChain = chunks.reduce(
          (acc, chunk) =>
            acc.then(() => {
              const actualChunkMsg = store.replyMsgId
                ? prepMsgReply(
                    store.listenerId,
                    store.replyMsgId,
                    chunk.message || "",
                  )
                : chunk.message;
              return store.sendMessage({ ...chunk, message: actualChunkMsg });
            }),
          Promise.resolve<unknown>(undefined),
        );
        await promChain;
        countersStore.readAllChatMessages(store.chatInfo?.id || "");
        form.resetFields();
        store.setReplyMsgId("");
        chatListStore.saveMessage(store.listenerId, "");
      } catch (error) {
        onError(error);
      } finally {
        setIsSending(false);

        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      }
    };

    const handleKeyDown = async (
      e: React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
      if (e.key === "Enter" && !e.shiftKey && !isSending) {
        e.preventDefault();
        const message = form.getFieldValue(chatFormNames.message);
        if (message && message.trim()) {
          form.submit();
        }
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      debounce(debounceCounter, 500, () =>
        chatListStore.saveMessage(store.listenerId, e.target.value),
      );
    };

    const getMemberListOptions = () => {
      const usersInChatSet = new Set(store.chatInfo?.userIds);
      return personComplexStore
        .getOptions()
        .filter((opt) =>
          usersInChatSet.has(opt.value ? String(opt.value) : ""),
        );
    };
    return (
      <div className={styles.box}>
        <Space className={styles.header} align="center">
          <Space align="center">
            <Avatar icon={store.chatName[0]} />
            <div className={styles.headerInfo}>
              <Typography.Text strong>{store.chatName}</Typography.Text>
              <Typography.Text type="secondary">
                {personComplexStore.loading ? (
                  <Spin spinning />
                ) : (
                  <MemberList options={getMemberListOptions()} />
                )}
              </Typography.Text>
            </div>
          </Space>
          {headerExtra}
        </Space>
        <ChatMsgList store={store} />
        <div>
          {store.replyMsgId && <RetweetOutlined />}
          {hasMention(msg) && <MentionList store={store} form={form} />}
          <Form<RCFormData>
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            className={styles.form}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item name={chatFormNames.message}>
                <TextArea
                  placeholder={`${t("Message")}...`}
                  style={{ resize: "none" }}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  ref={textAreaRef}
                />
              </Form.Item>
              {isSending ? (
                <div className={styles.loaderWrapper}>
                  <Spin spinning={isSending} size="large">
                    {`${t("Loading")}...`}
                  </Spin>
                </div>
              ) : (
                <Space>
                  <Button type="primary" htmlType="submit" size="small">
                    <SendOutlined />
                    {t("Send message")}
                  </Button>
                  <Form.Item name={chatFormNames.file}>
                    <RCUploader allowedFileTypes={allowedFileTypes} />
                  </Form.Item>
                </Space>
              )}
            </Space>
          </Form>
        </div>
      </div>
    );
  },
);
