import React from "react";
import styles from "./MultiStepEntsTable.module.less";

interface PropsMultiStepEntsTable {
  children?: React.ReactNode;
}

export const MultiStepEntsTable: React.FC<PropsMultiStepEntsTable> = ({
  children,
}) => <div className={styles.table}>{children}</div>;
