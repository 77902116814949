import { Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ZAttrFinancialValue } from "src/common/attributes/valueTypes";
import { onError } from "src/common/onError";
import {
  EntityInputNumber,
  PropsEntityInputNumber,
} from "src/pages/EntityCardPage/fieldComponents/EntityInputNumber";
import { StoreFinancialInput } from "./StoreFinancialInput";

type PropsFinancialInput = Omit<
  PropsEntityInputNumber,
  "onChange" | "value"
> & {
  store: StoreFinancialInput;
  value?: ZAttrFinancialValue;
  onChange?: (value?: ZAttrFinancialValue) => void;
};

export const FinancialInput: React.FC<PropsFinancialInput> = observer(
  ({ store, value, onChange, ...props }) => {
    useEffect(() => {
      store.init();
    }, []);

    const handleChangeCurrency = (currencyId: DefaultOptionType["value"]) => {
      try {
        const moneyValue = value?.moneyValue ?? [];
        const curMap = new Map(
          store.getCurrencies().map((e) => [String(e.id), e]),
        );
        const currencyEntity = curMap.get(String(currencyId));
        onChange?.({
          moneyValue,
          currencyEntity,
        });
      } catch (error) {
        onError(error);
      }
    };

    const handleChangeMoney = (val: string[] | null | undefined) => {
      const moneyValue = val ?? [];
      onChange?.({
        ...value,
        moneyValue,
      });
    };

    if (store.loading) return <Spin spinning={store.loading}>...</Spin>;
    return (
      <EntityInputNumber
        {...props}
        style={{ width: "100%" }}
        value={store.getMoneyValue(value)}
        onChange={handleChangeMoney}
        addonAfter={
          <Select
            style={{ minWidth: "75px" }}
            value={store.getCurrencyValue(value)}
            disabled={props.disabled}
            options={store.getCurrenciesOptions()}
            onChange={handleChangeCurrency}
          />
        }
      />
    );
  },
);
