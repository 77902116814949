import i18next from "i18next";
import { detectLang } from "src/common/detectLang";
import { PageUrl } from "./PageUrl";

/**
 * Данная функция предназначена для формирования урлов в пределах приложения для использования в <Link to=... />
 * @param urlId
 * @param params
 * @returns
 */
export const makeUrl = <T extends {} = {}>(
  urlId: PageUrl,
  params?: T,
  query?: Record<string, number | string | null | undefined>,
): string => {
  let url = `${urlId}`;
  if (params) {
    Object.entries(params).forEach(([key, val]) => {
      url = url.replace(`:${key}`, String(val));
    });
  }
  if (query) {
    url += "?";
    url += Object.entries(query)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${key}=${encodeURIComponent(value!)}`)
      .join("&");
  }
  return `/${i18next.language || detectLang()}${url}`;
};
