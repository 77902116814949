import { apiAdminUrl, apiObjUrl } from "src/common/apiUrl";
import {
  edValue2EntityField,
  entityField2EdValue,
} from "src/common/attrEdit/transforms";
import { AttributeValue } from "src/common/attributes/valueTypes/AttributeValue";
import { rest } from "src/common/rest";
import { optionalLangParams } from "src/lang/langHdr";
import {
  ZAttribute,
  ZAttrLinkedData,
  zAttrLinkedData,
} from "src/types/ZAttribute";
import {
  ZEntity,
  zEntity,
  ZEntityField,
  zEntityField,
} from "src/types/ZEntity";
import {
  ZEntityAvailableState,
  zEntityAvailableState,
} from "./EntityPage.types";

// Поля формы в виде ассоциативного массива String(attributeId) => value
export type EdCardValues = Record<string, AttributeValue>;

export const makeFieldName = (attrId: number) => String(attrId);

export const ed2entity = (
  ed: EdCardValues,
  entityId: number,
  objectId: number,
  attributesMap: Record<number, ZAttribute>,
): ZEntity => ({
  id: entityId,
  objectId,
  attributeValues: Object.entries(ed)
    .filter(([key, value]) => value !== undefined && !!attributesMap[+key])
    .map(([key, value]) => {
      const attr = attributesMap[+key];
      if (!attr) throw new Error("ed2entity: wrong attribute");
      return edValue2EntityField(attr, value);
    }),
});

/**
 * Форматом для хранения значения поля на сервере является string[]
 * Раньше была попытка конвертировать в формат соответствующего компонента.
 * Но для этого нужно знать описание атрибута. А например атрибуты внутри зависимых групп подгружаются уже после создания формы.
 * Поэтому теперь форма хранит данные в таком же формате, как и сервер.
 * Но для каждого компонента нужен proxy, который конвертирует формат на лету. Они собраны в fieldComponents
 * @param entity
 * @returns
 */
export const entity2ed = (
  entity: ZEntity,
  attributesMap: Record<number, ZAttribute>,
): EdCardValues =>
  entity.attributeValues.reduce((acc, valueField) => {
    const attr = attributesMap[valueField.attributeId];
    if (!attr) return acc;
    return {
      ...acc,
      [makeFieldName(valueField.attributeId)]: entityField2EdValue(
        attr,
        valueField,
      ),
    };
  }, {});

export const createEntity = async (entity: ZEntity): Promise<ZEntity> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...other } = entity;
  const resp = await rest.post(apiObjUrl("/entities"), other);
  return zEntity.parse(resp.data);
};

export const createEntitiesByIterator = async (
  entity: Omit<ZEntity, "id">,
): Promise<ZEntity[]> => {
  const resp = await rest.post(apiObjUrl("/entities/iterator"), entity);
  return zEntity.array().parse(resp.data);
};

export const loadEntity = async (
  entityId: number | string,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZEntity> => {
  const resp = await rest.get(
    apiObjUrl(`/entities/${entityId}`),
    optionalLangParams(options.translate),
  );
  return zEntity.parse(resp.data);
};

export const saveEntity = async (entity: ZEntity): Promise<ZEntity> => {
  const resp = await rest.put(apiObjUrl(`/entities/${entity.id}`), entity);
  return zEntity.parse(resp.data);
};

export const calculateEntityFormulas = async (
  entity: ZEntity,
): Promise<ZEntityField[]> => {
  const resp = await rest.post(apiObjUrl(`/formulas/calculate`), entity);
  return zEntityField.array().parse(resp.data);
};

export const validateEntityFormula = async (
  formula: string,
  objectId: number,
) =>
  rest.get(apiObjUrl(`/formulas/validate`), {
    params: { formula, objectId },
  });

export const deleteEntity = async (entityId: number) => {
  await rest.delete(apiObjUrl(`/entities/${entityId}`));
};

export const loadAvailableStates = async (
  objectId: number,
  stateId: number,
  options: {
    translate?: boolean;
  } = {},
): Promise<ZEntityAvailableState[]> => {
  const resp = await rest.get(apiAdminUrl("/states/available"), {
    params: { objectId, stateId },
    ...optionalLangParams(options.translate),
  });
  return zEntityAvailableState.array().parse(resp.data);
};

export const callChangeEntityState = async (
  entityId: number,
  stateId: number,
) => {
  await rest.put(apiObjUrl(`/entities/${entityId}/state`), { stateId });
};

export const getEntityLinkedValues = async (
  data: Omit<ZAttrLinkedData, "attribute" | "values">[],
) => {
  const resp = await rest.post(apiObjUrl(`/entities/linked-values`), data);
  return zAttrLinkedData.array().parse(resp.data);
};
