import { rest } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import {
  delayedNotify2ed,
  ed2delayedNotify,
  EdDelayedNotifyDict,
} from "./EdDelayedNotify";
import { zDelayedNotify } from "./ZDelayedNotify";

export const loadDelayedNotify = async (
  objectId: number,
): Promise<EdDelayedNotifyDict> => {
  if (objectId < 0) return {};
  const resp = await rest.get(apiObjUrl("/message-template/scheduled"), {
    params: { objectId },
  });
  const list = zDelayedNotify.array().parse(resp.data);
  return delayedNotify2ed(list);
};

export const saveDelayedNotify = async (
  objectId: number,
  dict: EdDelayedNotifyDict,
): Promise<void> => {
  const list = ed2delayedNotify(dict);
  await rest.post(apiObjUrl("/message-template/scheduled"), list, {
    params: { objectId },
  });
};
