import { ZAttribute } from "src/types/ZAttribute";
import { zEntityField, ZEntityField } from "src/types/ZEntity";
import {
  zAttrFinancialValue,
  ZAttrFinancialValue,
} from "../attributes/valueTypes";
import { ZAttrComponentEditor } from "./ZAttrEditInfo";
import { getEditorInfo } from "./getEditorInfo";
import { AttributeValue } from "../attributes/valueTypes/AttributeValue";

export const hardValue = (
  value: string[] | string | null | undefined,
): string[] | null => {
  if (Array.isArray(value)) return value;
  if (typeof value === "string") return [value];
  if (typeof value === "number" || typeof value === "boolean")
    return [String(value)];
  return null;
};

const value2EntityFieldMap: Partial<
  Record<
    ZAttrComponentEditor["editor"],
    (attr: ZAttribute, val: AttributeValue) => ZEntityField
  >
> = {
  FinancialInput: (attr: ZAttribute, val: AttributeValue) => {
    if (val !== undefined && (Array.isArray(val) || typeof val !== "object"))
      throw new Error("wrong value");
    return zEntityField.parse({
      attributeId: attr.id,
      values: hardValue(val?.moneyValue),
      currencyEntity: val?.currencyEntity,
    });
  },
  FormulaWithFinancial: (attr: ZAttribute, val: AttributeValue) => {
    if (val !== undefined && (Array.isArray(val) || typeof val !== "object"))
      throw new Error("wrong value");
    return zEntityField.parse({
      attributeId: attr.id,
      values: hardValue(val?.moneyValue),
      currencyEntity: val?.currencyEntity,
    });
  },
};

const entityField2ValueMap: Partial<
  Record<ZAttrComponentEditor["editor"], (val: ZEntityField) => AttributeValue>
> = {
  FinancialInput: (val: ZEntityField): ZAttrFinancialValue =>
    zAttrFinancialValue.parse({
      currencyEntity: val.currencyEntity,
      moneyValue: val.values,
    }),
  FormulaWithFinancial: (val: ZEntityField): ZAttrFinancialValue =>
    zAttrFinancialValue.parse({
      currencyEntity: val.currencyEntity,
      moneyValue: val.values,
    }),
};

const def = (attr: ZAttribute, val: AttributeValue) => {
  if (typeof val === "object" && !Array.isArray(val))
    throw new Error("wrong value");
  return {
    attributeId: attr.id,
    values: hardValue(val),
  };
};

export const edValue2EntityField = (
  attr: ZAttribute,
  val: AttributeValue,
): ZEntityField => {
  const component = getEditorInfo(attr.viewStyles)?.component;
  if (!component?.editor) return def(attr, val);
  const transformer = value2EntityFieldMap[component.editor];
  if (!transformer) return def(attr, val);
  return transformer(attr, val);
};

export const entityField2EdValue = (
  attr: ZAttribute,
  filed: ZEntityField,
): AttributeValue => {
  const component = getEditorInfo(attr.viewStyles)?.component;
  if (!component?.editor) return filed.values;
  const transformer = entityField2ValueMap[component.editor];
  if (!transformer) return filed.values;
  return transformer(filed);
};
