/* eslint-disable no-param-reassign */
import { Cascader, CascaderProps, Spin, Tag } from "antd";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { makeDictionary } from "src/common/makeDictionary";
import { onError } from "src/common/onError";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";

interface CascaderOption {
  value: number;
  label: React.ReactNode;
  isLeaf: boolean;
  loaded: boolean;
  objectId?: number;
  children?: CascaderOption[];
  selectable?: boolean;
}
// показывать все атрибуты, кроме того, который открыт на редактирование
const filterAllowedAtts = (attributes: ZAttribute[], curAttrId: number) =>
  attributes.filter((attr) => attr.id !== curAttrId);

const isAttrAllowed = (
  attr: ZAttribute,
  attrTypesDict: Record<number, string>,
) =>
  attrTypesDict[attr.valueType] === AttrTypeName.object ||
  attrTypesDict[attr.valueType] === AttrTypeName.childEntities;

const objAtts2Options = (
  list: ZAttribute[],
  attrTypesDict: Record<number, string>,
): CascaderOption[] =>
  list.map((attr) => ({
    label: `${attr.name}(${attr.id})`,
    value: attr.id,
    isLeaf: !isAttrAllowed(attr, attrTypesDict),
    loaded: false,
    objectId: attr.referenceId ?? undefined,
  }));

const createStore = () =>
  makeAutoObservable({
    refsReg: {} as Record<number, ZAttribute>,
    setRefsReg(dict: Record<number, ZAttribute>) {
      this.refsReg = dict;
    },
    options: [] as CascaderOption[],
    setOptions(list: CascaderOption[]) {
      this.options = list;
    },
    init(
      objectId: number,
      currAttrId: number,
      attrTypesDict: Record<number, string>,
    ) {
      return loadObjectAttrinbutesAll(objectId)
        .then((data) => {
          this.setRefsReg(makeDictionary(data, ({ id }) => id));
          this.setOptions(
            objAtts2Options(
              filterAllowedAtts(data || [], currAttrId),
              attrTypesDict,
            ),
          );
          return data;
        })
        .catch((e) => {
          onError(e);
          return null;
        });
    },
  });

type SingleValueType = (number | string)[];

type PropsLinkedValuePath = Omit<
  CascaderProps,
  "multiple" | "onChange" | "options" | "value"
> & {
  objectId: number;
  currAttrId: number;
  attrTypesDict: Record<number, string>;
  value?: SingleValueType;
  onChange?: (v: SingleValueType) => void;
};

export const LinkedValuePath: React.FC<PropsLinkedValuePath> = observer(
  ({ objectId, attrTypesDict, value, currAttrId, ...props }) => {
    const store = useMemo(() => createStore(), []);
    const [loading, setLoading] = useState(false);
    const isEmpty = store.options.length === 0;

    const loadDataForAttr = async (selected: CascaderOption) => {
      const attr = store.refsReg[selected.value];
      const referenceId = attr?.referenceId;
      const isRejected =
        !attr ||
        !selected ||
        selected.loaded ||
        !isAttrAllowed(attr, attrTypesDict) ||
        !referenceId;

      if (isRejected) {
        selected.isLeaf = true;
        store.setOptions([...store.options]);
        return null;
      }
      const atts = filterAllowedAtts(
        await loadObjectAttrinbutesAll(referenceId),
        currAttrId,
      );
      store.setRefsReg({
        ...store.refsReg,
        ...makeDictionary(atts, ({ id }) => id),
      });
      const loaded = objAtts2Options(atts, attrTypesDict);
      selected.children = loaded;
      selected.loaded = true;
      selected.isLeaf = loaded.length === 0;
      store.setOptions([...store.options]);
      return selected.children;
    };

    const loadData = async (selectedOptions: CascaderOption[]) => {
      const lastOption = selectedOptions[selectedOptions.length - 1];
      if (!lastOption) return;
      const attrId = lastOption?.value;
      if (!attrId) {
        lastOption.isLeaf = true;
        store.setOptions([...store.options]);
        return;
      }
      loadDataForAttr(lastOption);
    };

    useEffect(() => {
      store
        .init(objectId, currAttrId, attrTypesDict)
        .then(() => {
          const promChain = value?.reduce(
            (acc, curr) =>
              acc.then((loaded) => {
                const opt = loaded?.find((l) => l.value === curr);
                return opt ? loadDataForAttr(opt) : null;
              }),
            new Promise<CascaderOption[] | null>((res) => {
              res(store.options);
            }),
          );
          if (promChain) {
            setLoading(true);
            promChain?.finally(() => setLoading(false));
          }
        })
        .catch(onError);
    }, [store]);

    if (loading) return <Spin>...</Spin>;
    if (isEmpty) return <Tag color="red">Нет доступных атрибутов</Tag>;
    return (
      <Cascader.Panel
        {...props}
        value={value}
        options={store.options}
        loadData={loadData}
        changeOnSelect
      />
    );
  },
);
