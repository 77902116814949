import { ZAttribute } from "src/types/ZAttribute";
import { ZEntityField } from "src/types/ZEntity";
import { getViewInfo } from "./getViewInfo";
import { ZAttrComponentView } from "./zAttrViewInfo";
import {
  zAttrFinancialValue,
  ZAttrFinancialValue,
} from "../attributes/valueTypes";
import { AttributeValue } from "../attributes/valueTypes/AttributeValue";

const entityField2ValueMap: Partial<
  Record<ZAttrComponentView["view"], (val: ZEntityField) => AttributeValue>
> = {
  FinancialView: (val: ZEntityField): ZAttrFinancialValue =>
    zAttrFinancialValue.parse({
      currencyEntity: val.currencyEntity,
      moneyValue: val.values,
    }),
  FormulaWithFinancialView: (val: ZEntityField): ZAttrFinancialValue =>
    zAttrFinancialValue.parse({
      currencyEntity: val.currencyEntity,
      moneyValue: val.values,
    }),
};

export const entityField2ViewValue = (
  attr: ZAttribute,
  filed: ZEntityField,
): AttributeValue => {
  const viewCmp = getViewInfo(attr.viewType)?.component;
  if (!viewCmp?.view) return filed.values;
  const transformer = entityField2ValueMap[viewCmp.view];
  if (!transformer) return filed.values;
  return transformer(filed);
};
