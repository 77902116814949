import * as React from "react";
import { Button, Empty } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { AColumn, AsyncTable } from "src/components/tables/AsyncTable";
import { t } from "i18next";
import { onError } from "src/common/onError";
import { confirmDelete } from "src/components/ModalDelete";
import { NewBomModal } from "./NewBomModal";
import { ZBom2SummaryRow } from "../../Bom2Types";
import { Bom2ControlStore, NewBomData } from "../Bom2ControlStore";
import styles from "./Bom2Summary.module.less";
import { BomSummaryActions } from "./BomSummaryActions";

interface PropsBom2Summary {
  store: Bom2ControlStore;
}

export const bomSummaryColumns = () => [
  {
    key: "name",
    title: t("Heading"),
    dataIndex: "name",
  },
  {
    key: "supplierName",
    title: t("Supplier"),
    dataIndex: "supplierName",
  },
];

export const Bom2Summary: React.FC<PropsBom2Summary> = observer(({ store }) => {
  const { summaryStore, buzy, boms } = store;
  const [newOpen, setNewOpen] = React.useState(false);
  const columns: AColumn<ZBom2SummaryRow>[] = React.useMemo(
    bomSummaryColumns,
    [],
  );
  const isBuzy = !!buzy;
  const isEmpty = !boms.length || !summaryStore;
  const modelObjectId = store.entityData?.object.id;
  const modelEntityId = store.entityData?.entity?.id;
  return (
    <div className={styles.summaryBox}>
      <div className={styles.summaryToolbar}>
        <div className={styles.firstToolItem} />
        <Button
          danger
          icon={<DeleteOutlined />}
          disabled={!store.canDeleteBoms}
          loading={buzy === "deleteBoms"}
          onClick={() =>
            confirmDelete("Are you sure to delete selected?", () =>
              store.deleteBoms(),
            )
          }
        >
          {t("Delete")}
        </Button>
        {!!modelObjectId && !!modelEntityId && (
          <BomSummaryActions
            modelObjectId={modelObjectId}
            objectServiceId={store.objectServiceId}
            modelEntityId={modelEntityId}
            selectedBomIds={store.selectedBomIds}
            reloadSummary={() => store.createSummaryStore().catch(onError)}
          />
        )}
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setNewOpen(true)}
          disabled={isBuzy}
          loading={buzy === "createBom"}
        >
          {t("Create BOM")}
        </Button>
      </div>
      {isEmpty && (
        <div className={styles.summaryEmpty}>
          <Empty />
        </div>
      )}
      {!isEmpty && (
        <div className={styles.summaryContent}>
          <AsyncTable
            store={summaryStore}
            columns={columns}
            onRowClick={(row) => store.changeTab(row.id)}
          />
        </div>
      )}
      <NewBomModal
        open={newOpen}
        close={() => setNewOpen(false)}
        onSuccess={(values: NewBomData) => {
          store.addBom(values).catch(onError);
        }}
      />
    </div>
  );
});
