import * as React from "react";
import { observer } from "mobx-react-lite";
import { Empty, Spin } from "antd";
import { Bom2PositionStore } from "../Bom2PositionStore";
import styles from "./BomPosSelectTable.module.less";
import { Bom2Cell } from "../Bom2Table/Bom2Cell";
import { BomSelectPos } from "../Bom2Table/Bom2Select";
import { BomPositionItem } from "../Bom2Table/BomPositionItem";

interface PropsBomPosSelectTable {
  store: Bom2PositionStore;
}

export const BomPosSelectTable: React.FC<PropsBomPosSelectTable> = observer(
  ({ store }) => {
    const { positions, columns, columnWidths, collapsed, loading } = store;
    const gridTemplateColumns = columnWidths.join(" ");
    const isEmpty = positions.length === 0;
    return (
      <div className={styles.table}>
        <div className={styles.thead} style={{ gridTemplateColumns }}>
          {columns.map(({ key, title, minWidth }, i) => (
            <Bom2Cell key={key} top left={i === 0} minWidth={minWidth}>
              {title}
            </Bom2Cell>
          ))}
        </div>
        <div className={styles.tbody} style={{ gridTemplateColumns }}>
          {loading && (
            <Bom2Cell
              left
              minWidth={undefined}
              style={{ gridColumn: gridColumn(columns.length) }}
            >
              <Spin />
            </Bom2Cell>
          )}
          {!loading && isEmpty && (
            <Bom2Cell left minWidth={undefined}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Bom2Cell>
          )}
          {!loading &&
            !isEmpty &&
            positions.map((pos) => (
              <React.Fragment key={pos.materialTypeId}>
                <Bom2Cell left minWidth={columns[0]?.minWidth}>
                  <BomSelectPos store={store} pos={pos} />
                </Bom2Cell>
                <BomPositionItem viewMode store={store} pos={pos} />

                {!collapsed.has(pos.materialTypeId) &&
                  pos.rows.map((row) => (
                    <React.Fragment key={row.id}>
                      {columns.map(({ key, view, minWidth }, i) => (
                        <Bom2Cell key={key} left={i === 0} minWidth={minWidth}>
                          {view(row, pos)}
                        </Bom2Cell>
                      ))}
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  },
);

const gridColumn = (n: number) => `span ${n}`;
