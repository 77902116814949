import { apiObjUrl } from "src/common/apiUrl";
import { ZAttrFinancialValue } from "src/common/attributes/valueTypes";
import { onError } from "src/common/onError";
import { restCache } from "src/common/restCache";
import { z } from "zod";
import { Strategy } from "./Strategy";

const zCurrencySignLocationCode = z.enum(["signAfter", "signBefore"]);
type ZCurrencySignLocationCode = z.infer<typeof zCurrencySignLocationCode>;
const zSysVals = z.object({
  valueId: z.string().or(z.number()),
  code: zCurrencySignLocationCode,
});
const joinSignMap: Record<
  ZCurrencySignLocationCode,
  (val: string, sign: string) => string
> = {
  [zCurrencySignLocationCode.Enum.signAfter]: (val: string, sign: string) =>
    `${val} ${sign}`,
  [zCurrencySignLocationCode.Enum.signBefore]: (val: string, sign: string) =>
    `${sign} ${val}`,
};
const getLabel = (code: ZCurrencySignLocationCode, val: string, sign: string) =>
  joinSignMap[code](val, sign);

export class BuildCurrencyLabelStrategy implements Strategy<Promise<string>> {
  private viewValue: ZAttrFinancialValue | null = null;

  private setViewValue(viewValue: ZAttrFinancialValue) {
    this.viewValue = viewValue;
  }

  private currencyViewAttrs: string[] | null = null;

  private setCurrencyViewAttrs(currencyViewAttrs: string[]) {
    this.currencyViewAttrs = currencyViewAttrs;
  }

  private currencySignLocationAttr: string | null = null;

  private setCurrencySignLocationAttr(currencySignLocationAttr: string) {
    this.currencySignLocationAttr = currencySignLocationAttr;
  }

  bind(
    viewValue: ZAttrFinancialValue,
    currencyViewAttrs: string[],
    currencySignLocationAttr: string,
  ): void {
    this.setViewValue(viewValue);
    this.setCurrencyViewAttrs(currencyViewAttrs);
    this.setCurrencySignLocationAttr(currencySignLocationAttr);
  }

  async execute(): Promise<string> {
    try {
      const { viewValue, currencyViewAttrs, currencySignLocationAttr } = this;
      if (
        !viewValue ||
        !viewValue.currencyEntity ||
        !currencyViewAttrs ||
        !currencySignLocationAttr
      )
        throw Error("unconsistent data");
      const sysValsResp = await restCache.get(
        apiObjUrl("/currency/system-values"),
        {},
      );
      const sysValsRespData = zSysVals.array().parse(sysValsResp.data);
      const sysValsMap = new Map(
        sysValsRespData.map((v) => [String(v.valueId), v.code]),
      );
      const eValsMap = new Map(
        viewValue.currencyEntity.attributeValues.map((v) => [
          String(v.attributeId),
          v.values,
        ]),
      );
      const signVal = eValsMap.get(currencySignLocationAttr)?.[0];
      const signLocation = sysValsMap.get(
        signVal || "",
      ) as ZCurrencySignLocationCode;
      const signLabel = eValsMap.get(currencyViewAttrs[0] || "")?.[0];
      return getLabel(
        signLocation,
        viewValue.moneyValue?.[0] || "",
        signLabel || "",
      );
    } catch (error) {
      onError(error);
      return "";
    }
  }
}
