import { makeAutoObservable } from "mobx";
import { copyBomsToEntities } from "src/businessServices/services/bsBom2/apiBom2";
import { multiStepOnRowClick } from "src/components/MultiStepTask/utils";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { MultiStepTaskStore } from "src/components/MultiStepTask/MultiStepTaskStore";
import { RemoteData } from "src/common/RemoteData";
import { delay } from "src/common/delay";

export class BomSummaryCopyToStore {
  constructor(
    public readonly modelObjectId: number,
    public readonly modelEntityid: number,
    public readonly selectedBomIds: number[],
    private onFinish: () => void,
  ) {
    this.entitiesStore = new EntityFiltersPageStore({
      actions: new Set([EntFilterActionType.changeFilterVisible]),
      objectId: this.modelObjectId,
      selectionSettings: { selectionType: "checkbox" },
      onRowClick: (row) => multiStepOnRowClick(row, this.entitiesStore),
    });
    makeAutoObservable(this);
  }

  taskStore = new MultiStepTaskStore();

  async init() {
    this.taskStore.open();
  }

  // step-1
  entitiesStore: EntityFiltersPageStore;

  get targetEntityIds(): number[] {
    return this.entitiesStore.tableStore?.selected.map(({ id }) => id) ?? [];
  }

  get disabled() {
    return !this.targetEntityIds.length;
  }

  // --- copy
  copyInfo: RemoteData<boolean> = { status: "none" };

  setCopyInfo(data: RemoteData<boolean>) {
    this.copyInfo = data;
  }

  async copy() {
    try {
      this.setCopyInfo({ status: "wait" });
      const { selectedBomIds, targetEntityIds, modelEntityid } = this;
      if (!selectedBomIds.length) throw Error("Empty bom list");
      await copyBomsToEntities(selectedBomIds, targetEntityIds);
      this.setCopyInfo({ status: "ready", result: true });
      const targetEntities = new Set(targetEntityIds);
      if (targetEntities.has(modelEntityid)) this.onFinish();
      await delay(500);
      this.taskStore.close();
    } catch (error) {
      this.setCopyInfo({ status: "error", error });
    }
  }
}
