import * as React from "react";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { AColumn, AsyncTable } from "src/components/tables/AsyncTable";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { BoolView } from "src/components/BoolView/BoolView";
import { ZMCPoint } from "../../../ZMeasurementChart";
import { McSelectTableStore } from "./McSelectTableStore";
import { getVersionValueFromRow } from "./McSize";

interface PropsMcSelectTable {
  store: McSelectTableStore;
}

export const McSelectTable: React.FC<PropsMcSelectTable> = observer(
  ({ store }) => (
    <LoaderBox
      remoteData={store.data}
      drawReady={() => <Inner store={store} />}
    />
  ),
);

interface PropsInner {
  store: McSelectTableStore;
}

const Inner: React.FC<PropsInner> = observer(({ store }) => {
  const columns: AColumn<ZMCPoint>[] = [
    {
      key: "point",
      title: "Point",
      render: (_, { point }) => point.name,
    },
    {
      key: "descr",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "qc",
      title: "QC",
      render: (_, { qc }) => <BoolView value={qc} />,
    },
    {
      key: "resizeValueMinus",
      title: "Scale -",
      dataIndex: "resizeValueMinus",
    },
    {
      key: "resizeValuePlus",
      title: "Scale +",
      dataIndex: "resizeValuePlus",
    },
    {
      key: "tolMinus",
      title: "Tol -",
      dataIndex: "tolMinus",
    },
    {
      key: "tolPlus",
      title: "Tol +",
      dataIndex: "tolPlus",
    },
  ];
  store.sizes.forEach(({ id: sizeId, name: sizeName, versions }) => {
    Object.entries(versions).forEach(([verId, verName]) => {
      columns.push({
        key: `${sizeId}:${verId}`,
        title: `${sizeName}: ${verName}`,
        render: (_, row) => getVersionValueFromRow(row, sizeId, +verId),
      });
    });
  });
  return (
    <AsyncTable
      store={store.tableStore}
      columns={columns}
      onRowClick={(row) => selectOnRowClick(row, store.tableStore)}
    />
  );
});
