// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__menu--ENWSP {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -9999px;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 2px 0px 20px 0px #0000001f;
  border-top: 1px solid #e2e2e2;
  background-color: #fff;
  z-index: 100;
}
.src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__open--SaIyz {
  left: 0;
}
.src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__headerInner--nZmag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px #00000014;
}
.src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__content--ec769 {
  flex-grow: 1;
  overflow-y: auto;
}
.src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__footer--bWzFW {
  padding: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/SlideMenu/SlideMenu.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,sCAAA;EACA,6BAAA;EACA,sBAAA;EACA,YAAA;AACF;AAEA;EACE,OAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,qCAAA;AADF;AAGA;EACE,YAAA;EACA,gBAAA;AADF;AAIA;EACE,aAAA;AAFF","sourcesContent":[".menu {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  left: -9999px;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  box-shadow: 2px 0px 20px 0px #0000001f;\n  border-top: 1px solid #e2e2e2;\n  background-color: #fff;\n  z-index: 100;\n}\n\n.open {\n  left: 0;\n}\n\n.headerInner {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 24px;\n  box-shadow: 0px 1px 2px 0px #00000014;\n}\n.content {\n  flex-grow: 1;\n  overflow-y: auto;\n}\n\n.footer {\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__menu--ENWSP`,
	"open": `src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__open--SaIyz`,
	"headerInner": `src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__headerInner--nZmag`,
	"content": `src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__content--ec769`,
	"footer": `src-pages-EntityFiltersPage-SlideMenu-SlideMenu-module__footer--bWzFW`
};
export default ___CSS_LOADER_EXPORT___;
