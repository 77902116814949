import * as React from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { ZLinkInfo } from "src/types/ZLinkInfo";
import { PageUrl } from "src/routes/PageUrl";
import { makeUrl } from "src/routes/makeUrl";
import styles from "./LinkExt.module.less";

/**
 * Требуется возможность по указанной ссылке с расширенными параметрами
 * открывать страницы и активировать нужные элементы.
 * Например, открывать вкладку бизнес-сервиса.
 */

interface PropsLinkExt {
  linkInfo: ZLinkInfo;
  onClick?: () => void;
}

export const LinkExt: React.FC<PropsLinkExt> = ({ linkInfo, onClick }) => {
  let href: string | undefined;
  const text = t("Link");
  switch (linkInfo.type) {
    case "ENTITY":
      href = makeUrl(
        PageUrl.entityCard,
        { id: linkInfo.id },
        { bs: linkInfo.bsId },
      );
      break;
    default:
      break;
  }
  if (!href) return <span className={styles.badLink}>{text}</span>;
  return (
    <Link className={styles.linkExt} to={href} onClick={onClick}>
      {text}
    </Link>
  );
};
