import * as React from "react";
import i18 from "i18next";
import { observer } from "mobx-react-lite";
import { ConfigProvider, notification } from "antd";
import ruAnt from "antd/lib/locale/ru_RU";
import enAnt from "antd/lib/locale/en_US";
import { RouterProvider } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { createMainRouter } from "./MainRouter";
import { appStore } from "./appStore";
import { themeConfig } from "./themeConfig";
import "antd/dist/reset.css";
import "./style.less";
import { AuthChecker } from "./components/AuthChecker";
import { MsgQueue } from "./components/MsgQueue";

notification.config({ placement: "bottomRight" });

const antLocMap: Record<string, typeof ruAnt> = {
  ru: ruAnt,
  en: enAnt,
};
const antLocale = antLocMap[i18.language] || ruAnt;

dayjs.extend(utc);

const App: React.FC = observer(() => {
  React.useEffect(() => {
    appStore.init();
  }, []);

  return (
    // <React.StrictMode>
    <ConfigProvider locale={antLocale} theme={themeConfig}>
      <AuthChecker>
        <RouterProvider router={createMainRouter()} />
      </AuthChecker>
      <MsgQueue />
    </ConfigProvider>
    // </React.StrictMode>
  );
});

export default App;
