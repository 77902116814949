import * as React from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "src/common/classNames";
import { CellPosGenerator } from "src/components/Sheet/CellPosGenerator";
import { SamplePointsStore } from "../SamplePointsStore";
import { SampleColumn } from "./SampleColumn";
import { buildSampleColumns } from "./buildSampleColumns";
import styles from "./SamplesTable.module.less";
import { Subsample } from "../Subsample";

interface PropsSamplesTable {
  store: SamplePointsStore;
}

export const SamplesTable: React.FC<PropsSamplesTable> = observer(
  ({ store }) => {
    const columns: SampleColumn[] = React.useMemo(
      () => buildSampleColumns(store, new CellPosGenerator()),
      [],
    );
    const tableStyles = getSampleTableStyles(columns);
    const { data } = store;
    if (data.status !== "ready") return null;
    return (
      <div className={styles.table}>
        <div className={styles.theader} style={tableStyles}>
          {columns.map((col, i) => (
            <div
              key={col.key}
              style={getColStyles(col)}
              className={classNames([styles.th, [i === 0, styles.firstCol]])}
            >
              {col.title}
            </div>
          ))}
        </div>
        <div className={styles.tbody} style={tableStyles}>
          {data.result.map((row, rowIndex, rows) => {
            const subsampleStore = store.subsamples[row.id];
            const needDivider =
              rowIndex === rows.length - 1 || !!subsampleStore;
            return (
              <React.Fragment key={row.id}>
                {columns.map((col, colIndex) => (
                  <div
                    key={col.key}
                    style={getColStyles(col)}
                    className={classNames([
                      styles.td,
                      [colIndex === 0, styles.firstCol],
                      [col.align === "fit", styles.fit],
                      [needDivider, styles.divider],
                    ])}
                  >
                    {col.cell(row)}
                  </div>
                ))}
                {subsampleStore && (
                  <div style={{ gridColumn: `span ${columns.length}` }}>
                    <Subsample store={subsampleStore} />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  },
);

const getSampleTableStyles = (
  columns: SampleColumn[],
): React.CSSProperties => ({
  gridTemplateColumns: columns
    .map((col) => col.width ?? `${col.minWidth}px`)
    .join(" "),
});
const getColStyles = (column: SampleColumn): React.CSSProperties => ({
  minWidth: column.minWidth,
  alignItems: column.vertAlign,
  justifyContent: column.align === "fit" ? undefined : column.align,
});
