import * as React from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  ImportOutlined,
  LoadingOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { t } from "i18next";

export type McMoreToolsData = {
  copyToModel: {
    disabled: boolean;
    empty: boolean;
    action(): void;
  };
  copyFromModel: {
    action(): void;
  };
  reset: {
    disabled: boolean;
    loading: boolean;
    action(): void;
  };
};

interface PropsMcMoreTools {
  data: McMoreToolsData;
}

export const McMoreTools: React.FC<PropsMcMoreTools> = ({ data }) => {
  const items: MenuProps["items"] = [
    {
      key: "copyToModel",
      icon: <CopyOutlined />,
      label: (
        <Tooltip
          title={data.copyToModel.empty && t("No selected points to copy")}
        >
          {t("Copy to another model")}
        </Tooltip>
      ),
      disabled: data.copyToModel.disabled || data.copyToModel.empty,
      onClick: data.copyToModel.action,
    },
    {
      key: "copyFromModel",
      icon: <ImportOutlined />,
      label: t("Copy from model"),
      onClick: data.copyFromModel.action,
    },
    {
      key: "reset",
      icon: data.reset.loading ? <LoadingOutlined /> : <DeleteOutlined />,
      danger: true,
      label: t("Reset"),
      disabled: data.reset.disabled,
      onClick: data.reset.action,
    },
  ];
  return (
    <Dropdown menu={{ items }}>
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  );
};
