import { ZMCPoint } from "../../../ZMeasurementChart";

export type McSize = {
  id: number;
  name: string;
  versions: Record<number, string>;
};

export const makeSizesFromPoints = (rows: ZMCPoint[]): McSize[] => {
  const dict: Record<number, McSize> = {};
  const getItem = (id: number, name: string): McSize => {
    const oldItem = dict[id];
    if (oldItem) return oldItem;
    const newItem: McSize = { id, name, versions: {} };
    dict[id] = newItem;
    return newItem;
  };
  rows.forEach(({ sizes }) => {
    sizes.forEach(({ id, name, pointValues }) => {
      const item: McSize = getItem(id, name);
      pointValues.forEach(({ version }) => {
        item.versions[version.id] = version.name;
      });
    });
  });
  return Object.values(dict);
};

export const getVersionValueFromRow = (
  row: ZMCPoint,
  sizeId: number,
  verId: number,
): string => {
  const size = row.sizes.find(({ id }) => id === sizeId);
  const ver = size?.pointValues.find(({ version }) => version.id === verId);
  return String(ver?.value ?? "");
};
