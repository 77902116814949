import { z } from "zod";
import { zPermissionsList, ZPermissionsList } from "./Permission";

export const zEntityValue = z.string().array().nullable();
export type ZEntityValue = z.infer<typeof zEntityValue>;

interface ZEntityFieldLazy {
  attributeId: number;
  values: ZEntityValue;
  permissions?: ZPermissionsList | null;
  currencyEntity?: ZEntity | null;
}
export const zEntityField: z.ZodType<ZEntityFieldLazy> = z.lazy(() =>
  z.object({
    attributeId: z.number(),
    values: zEntityValue,
    permissions: zPermissionsList.nullable().optional(),
    currencyEntity: zEntity.optional().nullable(), // используется только в FinancialInput и  FinancialView
  }),
);

export type ZEntityField = z.infer<typeof zEntityField>;

export const zEntity = z.object({
  id: z.number(),
  objectId: z.number(),
  attributeValues: zEntityField.array(),
  permissions: zPermissionsList.nullable().optional(),
  stateId: z.number().nullable().optional(),
  userId: z.string().nullable().optional(),
});

export type ZEntity = z.infer<typeof zEntity>;
