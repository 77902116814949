import * as React from "react";
import { observer } from "mobx-react-lite";
import { msgQueueStore } from "src/components/MsgQueue";
import { notification } from "antd";

export const MsgQueue: React.FC = observer(() => {
  const { list } = msgQueueStore;
  React.useEffect(() => {
    if (list.length > 0) {
      list.forEach((msg) => {
        notification.open(msg);
      });
      msgQueueStore.clear();
    }
  }, [list.length]);
  return null;
});
