import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { zEntity } from "src/types/ZEntity";
import { z } from "zod";

const zEntitySearchData = z.object({
  content: zEntity.array(),
});

/**
 * Нежелательно использовать данный метод, но есть случаи, когда это необходимо.
 * Все такие случаи обговариваются с постановщиками
 */
export const searchEntitiesAll = async (objectId: number | null | undefined) =>
  rest
    .post(apiObjUrl("/entities/search"), {
      objectId,
    })
    .then((resp) => zEntitySearchData.parse(resp.data).content);
