import * as React from "react";
import { observer } from "mobx-react-lite";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { CopyFromV2Store } from "./CopyFromV2Store";

interface PropsCopyFromV2Step1 {
  store: CopyFromV2Store;
}

export const CopyFromV2Step1: React.FC<PropsCopyFromV2Step1> = observer(
  ({ store }) => {
    const { store1 } = store;
    if (!store1) return null;
    return <EntityFiltersTable store={store1} />;
  },
);
