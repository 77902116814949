import { createStoreFormulaWithFinancialInput } from "src/components/FinancialInput";
import { ZAttribute } from "src/types/ZAttribute";
import { z } from "zod";
import { zAttrFormula } from "./ZAttrFormula";

export const zAttrFormulaWithFinancial = zAttrFormula
  .omit({ editor: true })
  .extend({
    editor: z.literal("FormulaWithFinancial"),
    // используется только если в формуле участвует атрибут с типом "Финансовый"
    financialData: z
      .object({
        currencyObject: z.number(),
        labelAttr: z.string(),
        resultCurrency: z.number(), // - id экземпляра целевой валюты ,
        courseSource: z.number().array(), // - путь к атрибуту типа "ссылка на объект", содержащий список экземпляров Курса, которые мы будем использовать для перевода в целевую валюту. Если для какой-то валюты курс будет отсутствовать - выведется исключение.
      })
      .optional()
      .nullable(),
  });

export type ZAttrFormulaWithFinancial = z.infer<
  typeof zAttrFormulaWithFinancial
>;
export const makeFormulaWithFinancialProps = (
  attribute: ZAttribute,
  src: ZAttrFormulaWithFinancial,
) => ({
  store: createStoreFormulaWithFinancialInput(attribute),
  precision: src.precision ?? undefined,
  addonBefore: src.addonBefore ?? undefined,
  addonAfter: src.addonAfter ?? undefined,
});
