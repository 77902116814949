import { AttrTypeName } from "src/types/AttrType";
import { ZAttrComponentEditor } from "./ZAttrEditInfo";

export const editorsByType = {
  [AttrTypeName.boolean]: ["Checkbox", "Switch"],
  [AttrTypeName.date]: ["DatePicker"],
  [AttrTypeName.dateTime]: ["DatePicker"],
  [AttrTypeName.dictMulti]: ["Select"],
  [AttrTypeName.dictSingle]: ["Select"],
  [AttrTypeName.file]: ["Uploader"],
  [AttrTypeName.image]: ["ImageCarousel", "Uploader"],
  [AttrTypeName.int]: ["InputNumber"],
  [AttrTypeName.number]: ["InputNumber"],
  [AttrTypeName.object]: ["ObectRefSelector", "ObjectRefTable"],
  [AttrTypeName.string]: ["Input", "TextArea", "MaskedInput"],
  [AttrTypeName.time]: ["TimePicker"],
  [AttrTypeName.usersList]: ["PersonSelect"],
  [AttrTypeName.childEntities]: ["ChildEntities"],
  [AttrTypeName.externalLink]: ["Link"],
  [AttrTypeName.formula]: ["Formula", "FormulaWithFinancial"],
  [AttrTypeName.contentLanguage]: ["ContentLanguage"],
  [AttrTypeName.linkedValue]: ["LinkedValue"],
  [AttrTypeName.article]: ["Article"],
  [AttrTypeName.financial]: ["FinancialInput"],
} satisfies Record<AttrTypeName, ZAttrComponentEditor["editor"][]>;
