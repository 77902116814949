import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { FinancialViewStore } from "./FinancialViewStore";
import { Strategy } from "./Strategy";

export type PropsFinancialViewStoreStd = {
  currLabelStrat: Strategy<Promise<string>>;
};

export const makePropsFinancialViewStoreStd = (
  currLabelStrat: Strategy<Promise<string>>,
): PropsFinancialViewStoreStd => ({
  currLabelStrat,
});

export class FinancialViewStoreStd implements FinancialViewStore {
  constructor(readonly props: PropsFinancialViewStoreStd) {
    makeAutoObservable(this);
  }

  loading = false;

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  private currLabel: string = "";

  setCurrLabel(label: string) {
    this.currLabel = label;
  }

  async init() {
    try {
      this.setLoading(true);
      const { currLabelStrat } = this.props;
      this.setCurrLabel(await currLabelStrat.execute());
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  getLabel(): string {
    return this.currLabel;
  }
}
