import * as React from "react";
import { t } from "i18next";
import { ExportOutlined } from "@ant-design/icons";
import { DropdownChildButton } from "src/components/DropdownChildButton";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { MultiStepItem } from "src/components/MultiStepTask/MultiStepItem";
import { nextStep, lastStep } from "src/components/MultiStepTask/utils";
import { MultiStepEntsTable } from "src/components/MultiStepTask/utils/MultiStepEntsTable";
import { MultiStepTask } from "src/components/MultiStepTask";
import { BomSummaryCopyToStore } from "./BomSummaryCopyToStore";

interface PropsBomSummaryCopyTo {
  modelObjectId: number;
  modelEntityid: number;
  selectedBomIds: number[];
  onFinish: () => void;
}

export const BomSummaryCopyTo: React.FC<PropsBomSummaryCopyTo> = observer(
  ({ modelObjectId, modelEntityid, selectedBomIds, onFinish }) => {
    const store = React.useMemo(
      () =>
        new BomSummaryCopyToStore(
          modelObjectId,
          modelEntityid,
          selectedBomIds,
          onFinish,
        ),
      [modelObjectId, modelEntityid, selectedBomIds],
    );

    const start = () => {
      store.init().catch(onError);
    };

    const steps: MultiStepItem[] = [
      {
        title: t("Model"),
        description: t(
          "Select the instances to copy previously selected instances",
        ),
        next: nextStep(store.disabled),
        drawContent: () => (
          <MultiStepEntsTable>
            <EntityFiltersTable store={store.entitiesStore} />,
          </MultiStepEntsTable>
        ),
      },
      lastStep(t("Copying"), store.copyInfo, () => store.copy()),
    ];

    return (
      <>
        <DropdownChildButton
          icon={<ExportOutlined />}
          onClick={start}
          disabled={!store.selectedBomIds.length}
        >
          {t("Copy to")}
        </DropdownChildButton>
        <MultiStepTask
          title={t("Copy to")}
          taskStore={store.taskStore}
          steps={steps}
        />
      </>
    );
  },
);
