import { makeAutoObservable } from "mobx";

export class MultiStepTaskStore {
  constructor() {
    makeAutoObservable(this);
  }

  isOpen = false;

  setOpen(value: boolean) {
    this.isOpen = value;
  }

  open() {
    this.setOpen(true);
    this.setCurrent(0);
  }

  close() {
    this.setOpen(false);
  }

  current = 0;

  setCurrent(index: number): void {
    this.current = index;
  }
}
