import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { ChatListStore } from "./ChatListStore";
import { makeCounterUnreadAllMessages } from "./CounterUnreadMessages/CounterUnreadMessages";
import { clearCachedChatListStore } from "./getCachedChatListStore";

type PropsChatList = {
  data: EntityCardDataExt;
  store: ChatListStore;
};

export const ChatLabel: React.FC<PropsChatList> = observer(
  ({
    store,
    data,
    store: {
      props: { info, countersStore, personComplexStore },
    },
  }) => {
    useEffect(
      () => () => {
        clearCachedChatListStore();
        store.dispose();
      },
      [],
    );
    useEffect(() => {
      personComplexStore.init(data);
    }, [data]);
    return makeCounterUnreadAllMessages(countersStore, info.name);
  },
);
