import { Tabs, TabsProps, Typography } from "antd";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { appStore } from "src/appStore";
import { ChatForm } from "./ChatForm/ChatForm";
import { ChatSettingsMenu } from "./ChatForm/chatSettings/ChatSettingsMenu";
import styles from "./ChatListPage.module.less";
import { ChatListStore, isDefaultchat } from "./ChatListStore";
import { makeCounterUnreadChatMessages } from "./CounterUnreadMessages/CounterUnreadMessages";
import { NewChatButton } from "./NewChatButton/NewChatButton";

const isChatOwner = (userId: string) =>
  appStore.userData.status === "ready" &&
  appStore.userData.result.id === userId;

type PropsChatList = {
  store: ChatListStore;
};

export const ChatList: React.FC<PropsChatList> = observer(({ store }) => {
  const {
    activeChatId,
    sortedChats,
    activeChatStore,
    props: { info, countersStore },
  } = store;

  const items: TabsProps["items"] = sortedChats.map((item) => ({
    key: item.listenerId,
    label: makeCounterUnreadChatMessages(
      countersStore,
      item.listenerId,
      item.chatName,
    ),
  }));

  useEffect(() => {
    if (!activeChatId) {
      const firstChatId = store.chats[0]?.listenerId || "";
      handleChatChange(firstChatId);
    }
  }, [store.chats]);

  const handleChatChange = (chatId: string) => {
    store.handleChatChange(chatId, activeChatId ?? "");
  };

  const showSettings =
    !isDefaultchat(activeChatStore?.chatInfo ?? null, info) &&
    isChatOwner(activeChatStore?.chatInfo?.ownerUserId || "");

  return (
    <div className={styles.layout}>
      <div className={styles.sider}>
        <Typography.Text strong>{t("Chat list")}</Typography.Text>
        <Tabs
          tabPosition="left"
          items={items}
          onChange={handleChatChange}
          activeKey={activeChatId ?? undefined}
        />
        <NewChatButton chatListStore={store} />
      </div>
      <div className={styles.content}>
        {activeChatStore?.chatInfo && (
          <ChatForm
            key={activeChatStore.listenerId}
            store={activeChatStore}
            chatListStore={store}
            headerExtra={
              showSettings && <ChatSettingsMenu chatListStore={store} />
            }
          />
        )}
      </div>
    </div>
  );
});
