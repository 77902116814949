import * as React from "react";
import { classNames } from "src/common/classNames";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { appStore } from "src/appStore";
import { ZTranslationMap } from "src/types/ZTranslation";
import { t } from "i18next";
import styles from "./ExtendedTooltipInner.module.less";
import { ZTooltipExtFields } from "./ZTooltipExtFields";

interface PropsExtendedTooltipInner {
  tooltipData: ZTooltipExtFields;
}

const locTranslate = (
  title: string | null | undefined,
  titleLoc: ZTranslationMap | null | undefined,
): string | null | undefined => titleLoc?.[appStore.curLang] || title;

const markup = (text: string | null | undefined): React.ReactNode => {
  if (!text) return text;
  const chunks = text.split("\n");
  return (
    <>
      {chunks.map((row, i) => (
        // Других вариантов нет. Ну и текст меняться не должен. Так что не предполагается коллизий.
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.row} key={i}>
          {row}
        </div>
      ))}
    </>
  );
};

export const ExtendedTooltipInner: React.FC<PropsExtendedTooltipInner> = ({
  tooltipData,
}) => {
  const {
    title,
    titleLoc,
    text,
    textLoc,
    image,
    link,
    linkText,
    linkTextLoc,
    download,
    downloadText,
  } = tooltipData;
  return (
    <div className={styles.box}>
      {!!title && (
        <div className={styles.title}>{locTranslate(title, titleLoc)}</div>
      )}
      {(!!text || !!image) && (
        <div
          className={classNames([
            styles.middle,
            [!!text && !!image, styles.twoCols],
          ])}
        >
          {text && (
            <div className={styles.text}>
              {markup(locTranslate(text, textLoc))}
            </div>
          )}
          {image && <img className={styles.image} src={image} alt="" />}
        </div>
      )}
      {link && (
        <div className={styles.link}>
          <a href={link} target="_blank" rel="noreferrer">
            {locTranslate(linkText, linkTextLoc) || link}
          </a>
        </div>
      )}
      {download && (
        <div className={styles.download}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            href={download}
            target="_blank"
            download={downloadText}
            className={styles.downloadButton}
          >
            {t("Upload")}
          </Button>
        </div>
      )}
    </div>
  );
};
