import { getEditorInfo, ZAttrComponentEditor } from "src/common/attrEdit";
import { ZAttribute } from "src/types/ZAttribute";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import { AttrTypeName } from "src/types/AttrType";
import { viewDate, viewDateTimeUtc, viewTimeUtc } from "src/common/formats";

export const composeLabelsWithEditConfig = (
  attr: ZAttribute,
  values: string[],
  typesMap: Record<number, string>,
): string[] => composeLabel(attr, values, typesMap);

type ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
  typeName?: AttrTypeName,
) => string[];

const pipeTransforms = (
  values: string[],
  component: ZAttrComponentEditor,
  fns: ComposeFn[],
  typeName?: AttrTypeName,
) => fns.reduce((acc, curr) => curr(acc, component, typeName), values);

const joinAddons: ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
) => {
  if (
    !(
      component?.editor === "InputNumber" ||
      component?.editor === "Formula" ||
      component?.editor === "FormulaWithFinancial" ||
      component?.editor === "Input" ||
      component?.editor === "MaskedInput"
    )
  )
    return values;
  const { addonAfter, addonBefore } = component;
  return values?.map((v) => {
    let newV = v;
    newV = addonAfter ? `${newV} ${addonAfter}` : newV;
    newV = addonBefore ? `${addonBefore} ${newV}` : newV;
    return newV;
  });
};

const alignNumber: ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
) => {
  if (
    !(
      component?.editor === "InputNumber" ||
      component?.editor === "Formula" ||
      component?.editor === "FormulaWithFinancial"
    )
  )
    return values;
  const { precision } = component;
  return values?.map((v) => {
    const vNum = Number(v);
    const validPerc = precision !== undefined && precision !== null;
    const canAlign = validPerc && !Number.isNaN(vNum);
    return canAlign ? vNum.toFixed(precision) : v;
  });
};

const formatDate: ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
  typeName?: AttrTypeName,
) => {
  if (!(component?.editor === "DatePicker")) return values;
  return values?.map((v) =>
    typeName === AttrTypeName.date ? viewDate(v) : viewDateTimeUtc(v),
  );
};

const formatTime: ComposeFn = (
  values: string[],
  component: ZAttrComponentEditor,
) => {
  if (!(component?.editor === "TimePicker")) return values;
  return values?.map((v) => viewTimeUtc(v));
};

const composeLabel = (
  attr: ZAttribute,
  values: string[],
  typesMap: Record<number, string>,
): string[] => {
  const editorInfo = getEditorInfo(attr.viewStyles);
  const component = getComponentEditor(editorInfo, typesMap, attr);
  const typeName = typesMap[attr.valueType] as AttrTypeName;
  if (!component) return values;
  switch (component.editor) {
    case "InputNumber":
      return pipeTransforms(values, component, [alignNumber, joinAddons]);
    case "Formula":
      return pipeTransforms(values, component, [alignNumber, joinAddons]);
    case "FormulaWithFinancial":
      return pipeTransforms(values, component, [alignNumber, joinAddons]);
    case "Input":
      return pipeTransforms(values, component, [joinAddons]);
    case "MaskedInput":
      return pipeTransforms(values, component, [joinAddons]);
    case "FinancialInput":
      return pipeTransforms(values, component, [alignNumber]);
    case "DatePicker":
      return pipeTransforms(values, component, [formatDate], typeName);
    case "TimePicker":
      return pipeTransforms(values, component, [formatTime]);
    default:
      return values;
  }
};
