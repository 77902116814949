import * as React from "react";
import { observer } from "mobx-react-lite";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { LoaderBox } from "src/components/LoaderBox";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { onError } from "src/common/onError";
import { Tabs, TabsProps } from "antd";
import { t } from "i18next";
import { Bom2ControlStore, bom2ControlStore } from "./Bom2ControlStore";
import { Bom2Summary } from "./Bom2Summary";
import styles from "./Boms2Control.module.less";
import { Bom2Position } from "./Bom2Position";

interface PropsBom2Control {
  info: ObjectServiceExt;
  entityData: EntityCardDataExt;
}

export const Bom2Control: React.FC<PropsBom2Control> = observer(
  ({ info, entityData }) => {
    React.useEffect(() => {
      bom2ControlStore.init(info.id, entityData).catch(onError);
    }, [info.id]);
    return (
      <LoaderBox
        remoteData={bom2ControlStore.settings}
        drawReady={() => <Boms2View store={bom2ControlStore} />}
      />
    );
  },
);

interface PropsBoms2View {
  store: Bom2ControlStore;
}
const Boms2View: React.FC<PropsBoms2View> = observer(({ store }) => {
  const { positionStore, curBomKey, bomTabs, entityData } = store;
  const items: TabsProps["items"] = [
    { key: "null", label: t("Summary") },
    ...bomTabs,
  ];
  return (
    <div className={styles.mainBox}>
      <Tabs
        items={items}
        type="card"
        className={styles.tabsBox}
        activeKey={curBomKey}
        onChange={(key: string) => store.changeTab(key)}
      />
      <div className={styles.content}>
        {positionStore ? (
          <Bom2Position
            modelObjectId={entityData?.object.id}
            objectServiceId={store.objectServiceId}
            store={positionStore}
          />
        ) : (
          <Bom2Summary store={store} />
        )}
      </div>
    </div>
  );
});
