import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";

export const multiStepOnRowClick = (
  row: ZEntityRow,
  store: EntityFiltersPageStore | null,
) => {
  const tableStore = store?.tableStore;
  if (tableStore) selectOnRowClick(row, tableStore);
};
