import { t } from "i18next";
import { makeAutoObservable } from "mobx";
import { loadObject } from "src/common/api/apiObject";
import { onError } from "src/common/onError";
import { deleteEntity } from "src/pages/EntityCardPage/apiEntityCard";
import { EntityCardStore } from "src/pages/EntityCardPage/EntityCardStore";
import { compoundEntityTableStore2 } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore2";
import {
  ZEntityFilters,
  ZEntityRow,
} from "src/pages/EntityFiltersPage/EntityList/types";
import { loadObjectStatesInsideRefLikeAtts } from "src/pages/ManagementPage/Obj2Tab/roles/rolesApi";
import { ZEntity } from "src/types/ZEntity";
import { TableLoadParams, TableStore } from "../tables/TableStore";
import { ChildEntsActionType } from "./ChildEntities.types";
import { createTableParamsKey } from "./createTableParamsKey";
import { loadChildEntsSummary } from "./loadChildEntsSummary";

export type ZEntityFiltersChild = ZEntityFilters & {
  parentEntityId?: number;
  parentLinkAttributeId?: number;
};

const createStorageKey = (objId: number, entityId: number) =>
  `childEntities-${objId}-${entityId}`;

const saveLoadParams = (
  params: TableLoadParams<ZEntityFilters> | undefined,
  objId: number,
  entityId: number,
) => {
  try {
    localStorage.setItem(
      createStorageKey(objId, entityId),
      JSON.stringify(params),
    );
  } catch (error) {
    onError(error);
  }
};

const getSavedLoadParams = (
  objId: number,
  entityId: number,
): TableLoadParams<ZEntityFilters> | null => {
  try {
    const data = localStorage.getItem(createStorageKey(objId, entityId));
    return data ? JSON.parse(data) : null;
  } catch (error) {
    return null;
  }
};

export class ChildEntitiesStore {
  constructor(
    actions?: Set<ChildEntsActionType>,
    private fnCreate?: (entity: ZEntity) => Promise<ZEntity>,
  ) {
    this.availableActions =
      actions ??
      new Set([
        ChildEntsActionType.changeState,
        ChildEntsActionType.copyIn,
        ChildEntsActionType.copyFrom,
        ChildEntsActionType.create,
        ChildEntsActionType.select,
        ChildEntsActionType.rowClick,
      ]);
    makeAutoObservable(this);
  }

  protected availableActions: Set<ChildEntsActionType>;

  objectId: number = 0;

  tableStore: TableStore<ZEntityRow, ZEntityFiltersChild> | null = null;

  async init(
    objectId: number,
    parentEntityId: number,
    parentLinkAttributeId: number,
    summaryAttrs?: string[],
  ) {
    this.objectId = objectId;
    const objectName = (await loadObject(objectId, { translate: true })).name;
    this.setCurrObjName(objectName);
    this.setSummaryAttrs(summaryAttrs ?? null);

    const savedParams = getSavedLoadParams(objectId, parentEntityId);
    const savedFilters = savedParams?.filters || {};
    const defFilters: ZEntityFiltersChild = {
      objectId,
      parentEntityId,
      parentLinkAttributeId,
    };
    const finalFilters = {
      ...savedFilters,
      ...defFilters,
    };
    const finalParams = { ...savedParams, filters: finalFilters };

    this.tableStore = await compoundEntityTableStore2<
      ZEntityRow,
      ZEntityFiltersChild
    >(
      objectId,
      "id",
      finalParams,
      { keepSelected: true, selectionType: "checkbox" },
      () => {
        if (summaryAttrs?.length) this.checkSummaryChange();
        saveLoadParams(this.tableStore?.params, objectId, parentEntityId);
      },
      () => loadObjectStatesInsideRefLikeAtts(objectId),
    );
  }

  get avalibleActionsSet() {
    return this.availableActions;
  }

  get isPopup(): boolean {
    return !!this.subCardStore;
  }

  subCardStore: EntityCardStore | null = null;

  setSubCardStore(store: EntityCardStore | null) {
    this.subCardStore = store;
  }

  submitText: string = "";

  setSubmitText(text: string) {
    this.submitText = text;
  }

  openCreation(parentAttr: string, parentValue: number) {
    this.setSubmitText(t("Add"));
    const store = new EntityCardStore(this.fnCreate);
    this.setSubCardStore(store);
    store.initNew(this.objectId, { [parentAttr]: [String(parentValue)] });
  }

  openEdit(entityId: number) {
    this.setSubmitText(t("Apply"));
    const store = new EntityCardStore();
    this.setSubCardStore(store);
    store.init(String(entityId));
  }

  closeCreation() {
    this.setSubCardStore(null);
  }

  onUpdate() {
    this.tableStore
      ?.reload()
      .then(() => this.loadSummary())
      .catch(onError);
    this.closeCreation();
  }

  deletingSubEntity = false;

  setDeletingSubEntity(value: boolean) {
    this.deletingSubEntity = value;
  }

  async doDeleteSubEntity() {
    try {
      this.setDeletingSubEntity(true);
      const { subCardStore } = this;
      if (!subCardStore) return;
      const { info } = subCardStore;
      if (info.status !== "ready") return;
      const entityId = info.result.entity?.id;
      if (!entityId) return;
      await deleteEntity(entityId);
      this.onUpdate();
    } catch (e) {
      onError(e);
    } finally {
      this.setDeletingSubEntity(false);
    }
  }

  currObjName: string = t("Add");

  setCurrObjName(name: string) {
    this.currObjName = name;
  }

  // Итоги вычислений в таблице

  summaryAttrs: string[] | null = null;

  setSummaryAttrs(ids: string[] | null) {
    this.summaryAttrs = ids;
  }

  summaryValues: Record<string, number> | null = null;

  setSummaryValues(values: Record<string, number>) {
    this.summaryValues = values;
  }

  lastTableParams: string = "";

  setLastTableParams(params: string) {
    this.lastTableParams = params;
  }

  async loadSummary() {
    const { summaryAttrs, tableStore } = this;
    if (!tableStore || !tableStore.filters) return;

    const { filters } = tableStore;
    const { objectId, parentEntityId, parentLinkAttributeId, query } = filters;
    if (
      !summaryAttrs?.length ||
      !objectId ||
      !parentEntityId ||
      !parentLinkAttributeId
    )
      return;

    const totalValues = (
      await loadChildEntsSummary(
        objectId,
        parentEntityId,
        parentLinkAttributeId,
        summaryAttrs,
        query,
      )
    ).reduce(
      (result, { attributeId, sum }) => ({ ...result, [attributeId]: sum }),
      {},
    );
    this.setSummaryValues(totalValues);
  }

  checkSummaryChange() {
    const { tableStore, lastTableParams } = this;
    if (!tableStore || !tableStore.filters) return;
    const { filters, totalItems } = tableStore;
    const curTableParams = createTableParamsKey(filters, totalItems);
    if (curTableParams === lastTableParams) return;
    this.loadSummary()
      .then(() => this.setLastTableParams(curTableParams))
      .catch(onError);
  }
}
