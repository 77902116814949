import * as React from "react";
import { LinkExt } from "src/components/LinkExt";
import { ZExtNotification } from "src/types/ZExtNotification";

interface PropsOptionalLink {
  info: ZExtNotification;
  onClick: () => void;
  owner: "attachment" | "text";
}

export const OptionalLink: React.FC<PropsOptionalLink> = ({
  info,
  onClick,
  owner,
}) => {
  const { link } = info;
  if (!link) return null;
  if (info.file && owner === "text") return null;
  return (
    <>
      <span style={{ padding: "0 1rem" }}>→</span>
      <LinkExt linkInfo={link} onClick={onClick} />
    </>
  );
};
