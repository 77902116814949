// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ExtendedTooltip-ExtendedTooltipInner-module__box--fGpgr {
  display: flex;
  flex-direction: column;
  word-break: break-all;
  overflow-y: auto;
  max-height: 50vh;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__paddings--sfLNu {
  padding: 8px 12px;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__horizPaddings--zmh99 {
  padding: 0 12px;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__title--h94yS {
  padding: 8px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__middle--oTFdN {
  padding: 8px 12px;
  display: grid;
  grid-template-columns: 1fr;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__middle--oTFdN.src-components-ExtendedTooltip-ExtendedTooltipInner-module__twoCols--p5bZD {
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__middle--oTFdN.src-components-ExtendedTooltip-ExtendedTooltipInner-module__twoCols--p5bZD > * {
  max-width: 100%;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__text--RYyqP {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__image--X4HNE {
  max-width: 100%;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__link--YnyB3 {
  padding: 0 12px;
  line-height: 200%;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__download--m_nCr {
  padding: 0 12px;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__downloadButton--hRgJB {
  width: 100%;
}
.src-components-ExtendedTooltip-ExtendedTooltipInner-module__row--Y8qaP {
  min-height: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ExtendedTooltip/ExtendedTooltipInner.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACA;EACI,iBAAA;AACJ;AACA;EACI,eAAA;AACJ;AAEA;EANI,iBAAA;EAQA,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0CAAA;AAAJ;AAEA;EAfI,iBAAA;EAiBA,aAAA;EACA,0BAAA;AAAJ;AACI;EACI,8BAAA;EACA,eAAA;AACR;AAAQ;EAAO,eAAA;AAGf;AAAA;EACI,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAA;EACI,eAAA;AAEJ;AAAA;EAhCI,eAAA;EAkCA,iBAAA;AAEJ;AAAA;EApCI,eAAA;AAuCJ;AAAA;EACI,WAAA;AAEJ;AAAA;EACI,gBAAA;AAEJ","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    word-break: break-all;\n    overflow-y: auto;\n    max-height: 50vh;\n}\n.paddings {\n    padding: 8px 12px;\n}\n.horizPaddings {\n    padding: 0 12px;\n}\n\n.title {\n    .paddings;\n    color: rgba(0, 0, 0, 0.85);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n    box-shadow: 0px -1px 0px 0px #F0F0F0 inset;\n}\n.middle {\n    .paddings;\n    display: grid;\n    grid-template-columns: 1fr;\n    &.twoCols {\n        grid-template-columns: 1fr 1fr;\n        column-gap: 8px;\n        & > * {max-width: 100%;}\n    }\n}\n.text {\n    color: #333;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;\n}\n.image {\n    max-width: 100%;\n}\n.link {\n    .horizPaddings;\n    line-height: 200%;\n}\n.download {\n    .horizPaddings;\n}\n.downloadButton {\n    width: 100%;\n}\n.row {\n    min-height: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__box--fGpgr`,
	"paddings": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__paddings--sfLNu`,
	"horizPaddings": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__horizPaddings--zmh99`,
	"title": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__title--h94yS`,
	"middle": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__middle--oTFdN`,
	"twoCols": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__twoCols--p5bZD`,
	"text": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__text--RYyqP`,
	"image": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__image--X4HNE`,
	"link": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__link--YnyB3`,
	"download": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__download--m_nCr`,
	"downloadButton": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__downloadButton--hRgJB`,
	"row": `src-components-ExtendedTooltip-ExtendedTooltipInner-module__row--Y8qaP`
};
export default ___CSS_LOADER_EXPORT___;
