import { makeAutoObservable } from "mobx";
import {
  copyBomsToEntity,
  loadBom2Summary,
} from "src/businessServices/services/bsBom2/apiBom2";
import { ZBom2SummaryRow } from "src/businessServices/services/bsBom2/Bom2Types";
import { delay } from "src/common/delay";
import { RemoteData } from "src/common/RemoteData";
import { MultiStepTaskStore } from "src/components/MultiStepTask/MultiStepTaskStore";
import { singleLoader } from "src/components/tables/singleLoader";
import { TableStore } from "src/components/tables/TableStore";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { onError } from "src/common/onError";
import { ifDef } from "src/common/ifDef";
import { multiStepOnRowClick } from "src/components/MultiStepTask/utils";
import { Bom2PositionStore } from "../../Bom2PositionStore";

export class BomPosCopyFromStore {
  constructor(
    public readonly modelObjectId: number,
    public readonly objectServiceId: number,
    public readonly targetBomId: number,
  ) {
    this.store1 = new EntityFiltersPageStore({
      actions: new Set([EntFilterActionType.changeFilterVisible]),
      objectId: this.modelObjectId,
      selectionSettings: { selectionType: "radio" },
      onRowClick: (row) => multiStepOnRowClick(row, this.store1),
    });
    makeAutoObservable(this);
  }

  taskStore = new MultiStepTaskStore();

  async init() {
    this.taskStore.open();
  }

  // --- step 1
  store1: EntityFiltersPageStore;

  get srcModelId(): number | undefined {
    return this.store1.tableStore?.selected[0]?.id;
  }

  get firstDisabled() {
    return !this.bomsStore;
  }

  // --- step 2
  get bomsStore() {
    const { srcModelId } = this;
    if (!srcModelId) return null;
    return new TableStore<ZBom2SummaryRow, {}>({
      rowKey: "id",
      fnLoad: singleLoader(
        () => loadBom2Summary(this.objectServiceId, srcModelId),
        () => true,
      ),
      selectionSettings: {
        keepSelected: true,
        selectionType: "radio",
      },
    });
  }

  get srcBom() {
    return this.bomsStore?.selected[0];
  }

  get secondDisabled() {
    return !this.srcBom;
  }

  // ---- step 3
  onPosActive() {
    const { srcBom } = this;
    this.posStore = srcBom ? new Bom2PositionStore(srcBom) : null;
    this.posStore?.init().catch(onError);
  }

  posStore: Bom2PositionStore | null = null;

  get selectedPosIds(): number[] {
    return ifDef(this.posStore?.selected, (it) => Array.from(it)) ?? [];
  }

  get thirdDisabled() {
    return !this.posStore?.selected.size;
  }

  // --- copy
  copyInfo: RemoteData<boolean> = { status: "none" };

  setCopyInfo(data: RemoteData<boolean>) {
    this.copyInfo = data;
  }

  async copy(onFinish: () => void) {
    try {
      this.setCopyInfo({ status: "wait" });
      const { selectedPosIds } = this;
      if (selectedPosIds.length === 0) throw Error("Empty positions list");
      await copyBomsToEntity(selectedPosIds, this.targetBomId);
      this.setCopyInfo({ status: "ready", result: true });
      onFinish();
      await delay(500);
      this.taskStore.close();
    } catch (error) {
      this.setCopyInfo({ status: "error", error });
    }
  }
}
