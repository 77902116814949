import {
  parseFormulaAtts,
  transformFormula4Save,
} from "src/common/attrEdit/components/formula/common";
import { EdAttribute } from "../EdAttribute";
import { loadFormulaArguments } from "../forms/AttrForm2/MetaInfo/formula/loadFormulaArguments";

export const transformEdAttVals4Save = async (
  values: EdAttribute,
  objectId: number,
) => {
  const newVals = values;
  if (
    newVals.editorInfo?.component?.editor === "Formula" ||
    newVals.editorInfo?.component?.editor === "FormulaWithFinancial"
  ) {
    const argInfo = await loadFormulaArguments(objectId);
    newVals.editorInfo.component.formula = transformFormula4Save(
      newVals.editorInfo.component.formula,
      argInfo.availableAttributes,
    );
    newVals.editorInfo.component.argumentAtts = parseFormulaAtts(
      newVals.editorInfo.component.formula,
      argInfo,
    );
  }
  return newVals;
};
