import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { FinancialViewStore } from "./FinancialViewStore";

interface PropsFinancialView {
  store: FinancialViewStore;
}

export const FinancialView: React.FC<PropsFinancialView> = observer(
  ({ store }) => <Spin spinning={store.loading}>{store.getLabel()}</Spin>,
);
