import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { TableStore } from "src/components/tables/TableStore";
import { ZMCPoint, ZMeasurementChart } from "../../../ZMeasurementChart";
import { loadMeasurementChart } from "../../../apiMeasurementChart";
import { makeSizesFromPoints, McSize } from "./McSize";

export class McSelectTableStore {
  constructor() {
    makeAutoObservable(this);
  }

  data: RemoteData<ZMeasurementChart> = { status: "none" };

  setData(data: RemoteData<ZMeasurementChart>) {
    this.data = data;
  }

  get points(): ZMCPoint[] {
    const { data } = this;
    if (data.status !== "ready") return [];
    return data.result.tableDto?.rows.map(({ mcPoint }) => mcPoint) ?? [];
  }

  get tableStore() {
    const { points: rows } = this;
    return new TableStore<ZMCPoint, {}>({
      rowKey: "id",
      fnLoad: async () => ({
        rows,
        totalItems: rows.length,
      }),
      selectionSettings: {
        selectionType: "checkbox",
        keepSelected: true,
      },
    });
  }

  async init(modelId: number | undefined) {
    try {
      this.setData({ status: "wait" });
      if (!modelId) throw Error("Model is not selected");
      const result = await loadMeasurementChart(modelId);
      this.setData({ status: "ready", result });
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  get sizes(): McSize[] {
    return makeSizesFromPoints(this.points);
  }
}
