import { Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { classNames } from "src/common/classNames";
import styles from "./CheckList.module.less";
import { CheckListOption, CheckListStore } from "./CheckListStore";

export type PropsCheckList = {
  store: CheckListStore;
  onChange?: (value: string, checked: boolean, selected: React.Key[]) => void;
  elementExtra?: (option: CheckListOption) => React.ReactElement;
};

export const CheckList: React.FC<PropsCheckList> = observer(
  ({ store, onChange, elementExtra }) => {
    React.useEffect(() => {
      store.reset();
    }, []);

    return (
      <div className={styles.layout}>
        {store.finalOptions.map((option) => (
          <div
            key={option.value}
            className={classNames([
              styles.item,
              [store.isSelected(option.value), styles.itemActive],
            ])}
          >
            <Checkbox
              checked={store.isSelected(option.value)}
              value={option.value}
              onChange={({ target }) => {
                const { value, checked } = target;
                if (checked) {
                  store.check(value);
                } else {
                  store.uncheck(value);
                }
                onChange?.(value, checked, store.selectedKeys);
              }}
              disabled={store.isDisabled(option.value)}
            >
              {option.label}
            </Checkbox>
            {elementExtra?.(option)}
          </div>
        ))}
      </div>
    );
  },
);
