import { makeAutoObservable } from "mobx";
import { ZIdName } from "src/types/ZIdName";

type PropsFormulaWithFinancialMetaStore = {
  objectOptionsLoader: () => Promise<ZIdName[]>;
  name: string[];
  objectId: number;
  curAttrId: number;
  attrTypesDict: Record<number, string>;
};

export class FormulawithFinancialMetaStore {
  constructor(readonly props: PropsFormulaWithFinancialMetaStore) {
    makeAutoObservable(this);
  }
}
