import * as React from "react";

interface PropsImgProportional {
  maxWidth?: number | string;
  maxHeight: number | string;
  src: string;
}

export const ImgProportional: React.FC<PropsImgProportional> = (props) => {
  const { src, maxWidth = "100%", maxHeight } = props;
  return (
    <img
      alt=""
      src={src}
      style={{ maxWidth, maxHeight, objectFit: "contain" }}
    />
  );
};
