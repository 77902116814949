import { loadObjectAttrinbutesReduced } from "src/pages/ManagementPage/objectsApi";

export const loadAllObjectAttsOptions = async (refId: string) =>
  loadObjectAttrinbutesReduced(refId).then((atts) =>
    atts
      .map((attr) => ({
        label: attr.name.trim(),
        value: String(attr.id),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );
