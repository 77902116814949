import { ZObjState } from "src/types/ZObjState";
import { ZTranslationMap } from "src/types/ZTranslation";
import { ZPermissions } from "./roles/roleTypes";
import { ZLightObject } from "./Obj2Nodes";
import { loadObjectPermissions } from "./roles/rolesApi";
import { EdNotifyTemplateDict } from "./NotifyTemplates/EdNotifyTemplate";
import { EdDelayedNotifyDict } from "./DelayedNotify/EdDelayedNotify";

export type EdObjStates = Record<string, ZPermissions>; // key = String(ZObjState.id)

/**
 * Тип данных для формы редактирования объекта.
 */
export type EdObject = ZLightObject & {
  rolesMap?: EdObjStates;
  translation?: ZTranslationMap;
  // Актуальный список состояний. Меняется путём добавления/удаления состояний на форме
  states?: ZObjState[];
  templatesMap?: EdNotifyTemplateDict;
  delayedNotifyMap?: EdDelayedNotifyDict;
};

export const obj2edit = (
  obj: ZLightObject,
  states: ZObjState[] | undefined,
  rolesMap: EdObjStates | undefined,
  translation: ZTranslationMap,
  templatesMap: EdNotifyTemplateDict,
  delayedNotifyMap: EdDelayedNotifyDict,
): EdObject => ({
  ...obj,
  states,
  rolesMap,
  translation,
  templatesMap,
  delayedNotifyMap,
});

export const edit2obj = (
  edit: EdObject,
): {
  lightObject: ZLightObject;
  states: ZObjState[] | undefined;
  rolesMap: EdObjStates | undefined;
  translation: ZTranslationMap | undefined;
  templatesMap: EdNotifyTemplateDict | undefined;
  delayedNotifyMap: EdDelayedNotifyDict | undefined;
} => {
  const {
    rolesMap,
    states,
    translation,
    templatesMap,
    delayedNotifyMap,
    ...lightObject
  } = edit;
  return {
    lightObject,
    rolesMap,
    states,
    translation,
    templatesMap,
    delayedNotifyMap,
  };
};

export const makeRolesMap = async (
  objectId: number,
  states: ZObjState[],
): Promise<EdObjStates> => {
  const permissions: ZPermissions[] = await Promise.all(
    states.map(({ id }) => loadObjectPermissions(objectId, id)),
  );
  const res: EdObjStates = {};
  permissions.forEach((permission, i) => {
    res[states[i]!.id] = permission;
  });
  return res;
};
