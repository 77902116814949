import * as React from "react";
import { observer } from "mobx-react-lite";
import { Empty, Spin } from "antd";
import { Bom2PositionStore } from "../Bom2PositionStore";
import { BomSelectPos } from "./Bom2Select";
import { BomPositionItem } from "./BomPositionItem";
import { Bom2Cell } from "./Bom2Cell";
import styles from "./Bom2Table.module.less";

interface PropsBom2Table {
  store: Bom2PositionStore;
}

export const Bom2Table: React.FC<PropsBom2Table> = observer(({ store }) => {
  const { columns, positions, columnWidths, collapsed, loading } = store;
  const gridTemplateColumns = columnWidths.join(" ");
  const isEmpty = positions.length === 0;
  const refHeader = React.useRef<HTMLDivElement>(null);
  const refBody = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const body = refBody.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === body) {
          if (refHeader.current) {
            refHeader.current.style.width = `${entry.contentRect.width}px`;
          }
        }
      });
    });
    if (body) {
      resizeObserver.observe(body);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [refBody.current]);
  return (
    <div className={styles.box}>
      {loading && <Spin className={styles.spin} size="large" />}
      <div
        ref={refHeader}
        className={styles.header}
        style={{ gridTemplateColumns }}
      >
        {columns.map(({ key, title, minWidth }, i) => (
          <Bom2Cell key={key} top left={i === 0} minWidth={minWidth}>
            {title}
          </Bom2Cell>
        ))}
      </div>
      <div
        ref={refBody}
        className={styles.cells}
        style={{ gridTemplateColumns }}
        onScroll={(e) => {
          if (refHeader.current) {
            refHeader.current.scrollLeft = e.currentTarget.scrollLeft;
          }
        }}
      >
        {isEmpty && (
          <Bom2Cell
            left
            style={{ gridColumn: `span ${columns.length}` }}
            minWidth={undefined}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ width: "100%" }}
            />
          </Bom2Cell>
        )}
        {!isEmpty &&
          positions.map((pos) => (
            <React.Fragment key={pos.materialTypeId}>
              <Bom2Cell left minWidth={columns[0]?.minWidth}>
                <BomSelectPos store={store} pos={pos} />
              </Bom2Cell>
              <BomPositionItem store={store} pos={pos} />
              {!collapsed.has(pos.materialTypeId) &&
                pos.rows.map((row) => (
                  <React.Fragment key={row.id}>
                    {columns.map(({ key, view, edit, minWidth }, i) => (
                      <Bom2Cell key={key} left={i === 0} minWidth={minWidth}>
                        {(edit ?? view)(row, pos)}
                      </Bom2Cell>
                    ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
});
