import z from "zod";

export const zAsrtTableSettings = z.object({
  objAssortmentTable: z.number().nullable(),
  dictCollection: z.number().nullable(),
  dictDrop: z.number().optional().nullable(),
  dictCategory: z.number().optional().nullable(),
});

export type ZAsrtTableSettings = z.infer<typeof zAsrtTableSettings>;
