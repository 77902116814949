import { makeAutoObservable } from "mobx";
import { getEntityCachedRequest } from "src/common/entity/getEntityCachedRequest";
import { onError } from "src/common/onError";
import { getEntityLinkedValues } from "src/pages/EntityCardPage/apiEntityCard";
import { getIdLabels } from "src/references/getIdNames";
import { makeDictNameById } from "src/types/AttrType";
import { ZAttribute, ZAttrLinkedData } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";

export class AttrLinkedStore {
  constructor(
    readonly entityId: number,
    readonly attr: ZAttribute,
  ) {
    makeAutoObservable(this);
  }

  async init() {
    try {
      const [linkedData, entity, typesMap] = await Promise.all([
        getEntityLinkedValues([
          { entityId: this.entityId, linkAttributeId: this.attr.id },
        ]).then((data) => data.find((d) => d.linkAttributeId === this.attr.id)),
        getEntityCachedRequest(this.entityId),
        getIdLabels("attrType", "attrType").then(makeDictNameById),
      ]);
      this.setEntity(entity);
      this.setTypesMap(typesMap);
      if (linkedData) this.setLinkedAttrData(linkedData);
    } catch (error) {
      onError(error);
    }
  }

  linkedAttrData: ZAttrLinkedData | null = null;

  setLinkedAttrData(data: ZAttrLinkedData) {
    this.linkedAttrData = data;
  }

  entity: ZEntity | null = null;

  setEntity(entity: ZEntity) {
    this.entity = entity;
  }

  typesMap: Record<number, string> = {};

  setTypesMap(list: Record<number, string>) {
    this.typesMap = list;
  }
}

export const createAttrLinkedStore = (entityId: number, attr: ZAttribute) => {
  const store = new AttrLinkedStore(entityId, attr);
  store.init();
  return store;
};
