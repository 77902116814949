import * as React from "react";
import { observer } from "mobx-react-lite";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { BomSummaryCopyFromStore } from "./BomSummaryCopyFromStore";

interface PropsBomSummaryCopyFromStep1 {
  store: BomSummaryCopyFromStore;
}

export const BomSummaryCopyFromStep1: React.FC<PropsBomSummaryCopyFromStep1> =
  observer(({ store }) => <EntityFiltersTable store={store.store1} />);
