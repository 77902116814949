import { makeAutoObservable } from "mobx";
import { delay } from "src/common/delay";
import { RemoteData } from "src/common/RemoteData";
import { MultiStepTaskStore } from "src/components/MultiStepTask/MultiStepTaskStore";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { onError } from "src/common/onError";
import { McSelectTableStore } from "../McSelectTable/McSelectTableStore";
import { copyMcPoints } from "../../../apiMeasurementChart";

export class McCopyFromStore {
  constructor() {
    makeAutoObservable(this);
  }

  taskStore = new MultiStepTaskStore();

  async start(modelObjectId: number, modelId: number) {
    this.setModelId(modelId);
    this.setStore1(
      new EntityFiltersPageStore({
        actions: new Set([EntFilterActionType.changeFilterVisible]),
        objectId: modelObjectId,
        selectionSettings: { selectionType: "radio" },
        onRowClick: (row) => {
          const tableStore = this.store1?.tableStore;
          if (tableStore) selectOnRowClick(row, tableStore);
        },
      }),
    );
    this.store1?.init(modelObjectId);
    this.taskStore.open();
  }

  modelId = 0;

  setModelId(id: number) {
    this.modelId = id;
  }

  // ---- 1
  store1: EntityFiltersPageStore | null = null;

  setStore1(instance: EntityFiltersPageStore | null) {
    this.store1 = instance;
  }

  get srcModelId(): number | undefined {
    return this.store1?.tableStore?.selected[0]?.id;
  }

  get firstDisabled() {
    return !this.srcModelId;
  }

  // ---- 2
  store2 = new McSelectTableStore();

  initStep2() {
    this.store2.init(this.srcModelId).catch(onError);
  }

  get selectedPointIds(): number[] {
    return this.store2.tableStore.selected.map(({ id }) => id);
  }

  get secondDisabled() {
    return !this.selectedPointIds.length;
  }

  // --- copy
  copyInfo: RemoteData<boolean> = { status: "none" };

  setCopyInfo(data: RemoteData<boolean>) {
    this.copyInfo = data;
  }

  async copy(onFinish: () => void) {
    try {
      this.setCopyInfo({ status: "wait" });
      const { srcModelId, selectedPointIds } = this;
      if (!srcModelId) throw Error("srcModelId is empty");
      if (!selectedPointIds.length) throw Error("No one selected points");
      await copyMcPoints(srcModelId, [this.modelId], selectedPointIds);
      this.setCopyInfo({ status: "ready", result: true });
      onFinish();
      await delay(500);
      this.taskStore.close();
    } catch (error) {
      this.setCopyInfo({ status: "error", error });
    }
  }
}
