import * as React from "react";
import { imgSrc } from "src/components/PlmUploader/imgSrc";
import { EdBomRow } from "../../ZBom2Position";

interface PropsBomColorCellView {
  colorModelId: number;
  row: EdBomRow;
}

export const BomColorCellView: React.FC<PropsBomColorCellView> = (props) => {
  const { colorModelId, row } = props;
  const colorModel = row.colorModels.find(({ id }) => id === colorModelId);
  if (!colorModel) return null;
  const { bomPositionColorModel: cm } = colorModel;
  const { color } = cm;
  return (
    <div>
      {!!cm.colorReference.value && (
        <div>
          <img
            src={imgSrc(cm.colorReference.value)}
            alt=""
            style={{ maxWidth: "100%", maxHeight: 64, objectFit: "contain" }}
          />
        </div>
      )}
      <div>{cm.colorManual.value}</div>
      <div>{cm.comment.value}</div>
      {!!color.image.value && (
        <div>
          <img
            src={imgSrc(color.image.value)}
            alt=""
            style={{ maxWidth: "100%", maxHeight: 64, objectFit: "contain" }}
          />
        </div>
      )}
      <div>{color.article.value}</div>
      <div>{color.name.value}</div>
    </div>
  );
};
