import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { onError } from "src/common/onError";
import { TableStore } from "src/components/tables/TableStore";
import { singleLoader } from "src/components/tables/singleLoader";
import { ZBom2SummaryRow, ZBom2Supplier } from "../Bom2Types";
import { createBom2, deleteBom2, loadBom2Summary } from "../apiBom2";
import { Bom2PositionStore } from "./Bom2Position/Bom2PositionStore";

type BomData = {};
type FilterSummary = {};
export type NewBomData = {
  name: string;
  supplier: ZBom2Supplier;
};

type Bom2Buzy = "createBom" | "deleteBoms" | false;

export const bom2ControlStore = makeAutoObservable({
  objectServiceId: 0,
  setObjectServiceId(id: number) {
    this.objectServiceId = id;
  },

  buzy: false as Bom2Buzy,
  setBuzy(type: Bom2Buzy) {
    this.buzy = type;
  },

  settings: { status: "none" } as RemoteData<BomData>,
  setSettings(data: RemoteData<BomData>) {
    this.settings = data;
  },

  curBomId: null as number | null,
  setCurBomId(id: number | null) {
    this.curBomId = id;
  },
  get curBomKey(): string {
    return String(this.curBomId);
  },

  entityData: null as EntityCardDataExt | null,
  setEntityData(data: EntityCardDataExt | null) {
    this.entityData = data;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async init(objectServiceId: number, entityData: EntityCardDataExt) {
    try {
      this.setSettings({ status: "wait" });
      this.setPositionStore(null);
      this.setObjectServiceId(objectServiceId);
      this.setCurBomId(null);
      this.setEntityData(null);
      this.setEntityData(entityData);
      await this.createSummaryStore();
      this.setSettings({ status: "ready", result: {} });
    } catch (error) {
      this.setSettings({ status: "error", error });
    }
  },
  async createSummaryStore() {
    const result = await loadBom2Summary(
      this.objectServiceId,
      this.entityModelIdEx,
    );
    this.setBoms(result);
    this.summaryStore = new TableStore<ZBom2SummaryRow, FilterSummary>({
      rowKey: "id",
      fnLoad: singleLoader(
        async () => result,
        () => true,
      ),
    });
  },

  summaryStore: null as TableStore<ZBom2SummaryRow, FilterSummary> | null,

  async addBom(values: NewBomData) {
    try {
      this.setBuzy("createBom");
      await createBom2(
        this.objectServiceId,
        this.entityModelIdEx,
        values.name,
        values.supplier?.id,
      );
      this.createSummaryStore();
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  },
  get canDeleteBoms(): boolean {
    return !!this.summaryStore?.selected.length && !this.buzy;
  },
  async deleteBoms() {
    const { summaryStore } = this;
    if (!summaryStore) return;
    try {
      this.setBuzy("deleteBoms");
      const ids = this.summaryStore?.selected.map(({ id }) => id);
      if (ids && ids.length > 0) {
        await Promise.all(
          ids.map((id) => deleteBom2(this.objectServiceId, id)),
        );
        this.createSummaryStore();
      }
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  },

  get bomTabs(): { key: string; label: string }[] {
    return this.boms.map((bom) => ({
      key: String(bom.id),
      label: this.getBomName(bom),
    }));
  },
  getBomName(bom: ZBom2SummaryRow): string {
    return bom.name ?? "";
  },

  changeTab(keyOrId: number | string) {
    const bomObjId = +keyOrId || null;
    const bom = this.boms.find(({ id }) => id === bomObjId);
    this.setCurBomId(bomObjId);
    this.setPositionStore(bom ? new Bom2PositionStore(bom) : null);
  },

  get entityModelIdEx(): number {
    const entity = this.entityData?.entity;
    if (!entity) throw Error("Expected model entity");
    return entity.id;
  },

  boms: [] as ZBom2SummaryRow[],

  setBoms(value: ZBom2SummaryRow[]) {
    this.boms = value;
  },

  get selectedBomIds(): number[] {
    return this.summaryStore?.selected.map(({ id }) => id) ?? [];
  },

  positionStore: null as Bom2PositionStore | null,
  setPositionStore(store: Bom2PositionStore | null) {
    this.positionStore = store;
  },
});

export type Bom2ControlStore = typeof bom2ControlStore;
