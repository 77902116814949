import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, ButtonProps, Drawer, Space, Steps } from "antd";
import { ifs } from "src/common/ifs";
import { MultiStepTaskStore } from "./MultiStepTaskStore";
import styles from "./MultiStepTask.module.less";
import { MultiStepItem, MultiStepStatus, StepButton } from "./MultiStepItem";

interface PropsMultiStepTask {
  title: React.ReactNode;
  taskStore: MultiStepTaskStore;
  steps: MultiStepItem[];
}

const drawButton = (
  buttonProps: StepButton | undefined,
  defaultType: ButtonProps["type"],
  onClick: () => void,
): React.ReactNode => {
  if (!buttonProps) return null;
  return (
    <Button
      type={buttonProps.type ?? defaultType}
      disabled={buttonProps.disabled}
      onClick={onClick}
    >
      {buttonProps.text}
    </Button>
  );
};

export const MultiStepTask: React.FC<PropsMultiStepTask> = observer((props) => {
  const { taskStore, title: mainTitle, steps } = props;
  const { isOpen, current } = taskStore;
  const curStep = steps[current];
  const changeStep = (newIndex: number) => {
    const fromPrevStep = current > newIndex;
    taskStore.setCurrent(newIndex);
    steps[newIndex]?.onActive?.(fromPrevStep);
  };
  React.useEffect(() => {
    changeStep(0);
  }, [isOpen]);
  return (
    <Drawer
      width="100vw"
      open={isOpen}
      onClose={() => taskStore.close()}
      className={styles.drawer}
      title={mainTitle}
      extra={
        <Space>
          {drawButton(curStep?.prev, "default", () => changeStep(current - 1))}
          {drawButton(curStep?.next, "primary", () => changeStep(current + 1))}
        </Space>
      }
    >
      <div className={styles.content}>
        <Steps
          items={steps.map(({ title, description }, i) => ({
            title,
            description,
            status: ifs<MultiStepStatus>(
              [
                [i < current, "finish"],
                [i > current, "wait"],
              ],
              curStep?.status ?? "process",
            ),
          }))}
        />
        <div className={styles.currentStep}>{curStep?.drawContent?.()}</div>
      </div>
    </Drawer>
  );
});
