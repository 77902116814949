import { CellPos } from "./CellPos";

/* eslint class-methods-use-this: "off" */
/* eslint no-plusplus: "off" */

export class CellPosGenerator {
  protected x: number = -1;

  protected y: number = -1;

  protected prevRowKey: unknown;

  // Заглушка
  //   eslint-disable-next-line  @typescript-eslint/no-unused-vars
  next(_rowKey: unknown): CellPos | undefined {
    return undefined;
  }

  nextReal(rowKey: unknown): CellPos | undefined {
    if (rowKey !== this.prevRowKey) {
      this.x = 0;
      this.y++;
      this.prevRowKey = rowKey;
    }
    return {
      x: this.x++,
      y: this.y,
    };
  }
}
