import { makeAutoObservable } from "mobx";
import { getEditorInfo } from "src/common/attrEdit";
import { ZAttrFinancialValue } from "src/common/attributes/valueTypes";
import { composeEntityLabel } from "src/common/entity/composeEntityLabel/composeEntityLabel";
import { onError } from "src/common/onError";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { CurrencyOptionType, StoreFinancialInput } from "./StoreFinancialInput";
import { searchEntitiesAll } from "./searchEntitiesAll";

let currenciesRespCache: Promise<ZEntity[]> | null = null;

export class StoreFinancialInputStd implements StoreFinancialInput {
  constructor(readonly attribute?: ZAttribute) {
    makeAutoObservable(this);
  }

  loading = false;

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  currencyOptions: CurrencyOptionType[] = [];

  setCurrencyOptions(list: CurrencyOptionType[]) {
    this.currencyOptions = list;
  }

  currencies: ZEntity[] = [];

  setCurrencies(list: ZEntity[]) {
    this.currencies = list;
  }

  getCurrenciesOptions(): CurrencyOptionType[] {
    return this.currencyOptions;
  }

  getCurrencies(): ZEntity[] {
    return this.currencies;
  }

  getInitialCurrencyValue(): CurrencyOptionType["value"] {
    const val = this.currencyOptions[0]?.value;
    return val ? String(val) : undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getCurrencyValue(src?: ZAttrFinancialValue): CurrencyOptionType["value"] {
    const val = src?.currencyEntity?.id;
    return val ? String(val) : undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getMoneyValue(src?: ZAttrFinancialValue): string[] | undefined {
    const val = src?.moneyValue;
    if (!val) return undefined;
    return typeof val === "string" ? [val] : val;
  }

  async init() {
    try {
      this.setLoading(true);
      if (!this.attribute)
        throw new Error("StoreFinancialInput: attribute is empty");
      const editor = getEditorInfo(this.attribute.viewStyles);
      if (editor?.component?.editor !== "FinancialInput")
        throw new Error("StoreFinancialInput: wrong editor");
      const atts4Show = [editor.component.labelAttr]
        .filter(Boolean)
        .map(String);
      if (!currenciesRespCache)
        currenciesRespCache = searchEntitiesAll(this.attribute.referenceId);
      const entities = await currenciesRespCache;
      const options = await Promise.all(
        entities.map(async (e) => ({
          label: await composeEntityLabel(e, atts4Show),
          value: String(e.id),
        })),
      );

      this.setCurrencies(entities);
      this.setCurrencyOptions(options);
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }
}
