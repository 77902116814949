/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { ChatList } from "./ChatList";
import { ChatLabel } from "./ChatLabel";
import { getCachedChatListStore } from "./getCachedChatListStore";

export const bsChat: BusinessServiceDescriptor = {
  key: "chat",
  viewer(info, data) {
    return <ChatList store={getCachedChatListStore(info, data)} />;
  },
  async loadSettings(info) {
    return info;
  },
  renderSettings(info, store) {
    return <>В разработке...</>;
  },
  async saveSettings(info, values) {
    // eslint-disable-next-line no-console
    console.log("save");
  },
  renderTabLabel(info, data) {
    return <ChatLabel store={getCachedChatListStore(info, data)} data={data} />;
  },
};
