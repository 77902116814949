import { SelectProps, notification } from "antd";
import { makeAutoObservable } from "mobx";
import * as React from "react";
import { RemoteData } from "src/common/RemoteData";
import { onError } from "src/common/onError";
import { ModelessFormStore } from "src/components/ModelessForm";
import { IdLabel, getIdLabels } from "src/references/getIdNames";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import {
  GroupType,
  ZIdGroupLabel,
  ZIdGroupType,
  zGroupType,
  zIdGroupLabel,
} from "src/types/ZGroup";
import { ZObjState } from "src/types/ZObjState";
import { ZObjectItem } from "src/types/ZObjectItem";
// eslint-disable-next-line import/no-extraneous-dependencies
import RcTree from "rc-tree";
import { BusinessServiceDescriptor } from "src/businessServices";
import {
  createServiceForObject,
  deleteServiceForObject,
  loadServicesForObject,
} from "src/businessServices/apiBusinessServices";
import {
  ObjectServiceExt,
  ZObjectService,
} from "src/businessServices/businessServises.types";
import { loadObject } from "src/common/api/apiObject";
import {
  loadTranslation,
  saveTranslation,
} from "src/common/api/apiTranslation";
import { delay } from "src/common/delay";
import { ZIdName } from "src/types/ZIdName";
import { findNodeByKey } from "../../../common/findNodeByKey";
import { findNodeOwnerByKey } from "../../../common/findNodeOwnerByKey";
import {
  apiAddExistingAttr,
  createLightObject,
  deleteAttribute,
  deleteAttributeFromValue,
  deleteGroup,
  deleteObject,
  loadObjectAttrinbutesAll,
  loadObjects,
  loadRestrictions,
  saveLightObject,
} from "../objectsApi";
import { EdAttribute, attr2edit } from "./EdAttribute";
import { group2ed } from "./EdGroup";
import {
  EdObjStates,
  EdObject,
  edit2obj,
  makeRolesMap,
  obj2edit,
} from "./EdObject";
import { EdNotifyTemplateDict } from "./NotifyTemplates/EdNotifyTemplate";
import {
  loadNotifyTemplates,
  saveNotifyTemplates,
} from "./NotifyTemplates/apiNotifyTemplates";
import {
  Actions,
  AttributeO2,
  CommonNodeO2,
  GroupO2,
  ObjectO2,
  ValueO2,
  ZLightGroup,
  ZLightObject,
  getNodeName,
  makeObjKey2,
  newItemId,
  visitO2,
  zLightObject,
} from "./Obj2Nodes";
import { Obj2OrdersStore } from "./Obj2Orders/Obj2OrdersStore";
import { SelectDictionaryStore } from "./forms/AttrForm2/SelectDictionary";
import { EdBusinessService } from "./forms/ObjectForm2/EdBusinessService";
import { createPermissions } from "./roles/createPermissions";
import { ZPermissions, ZRolesGroup, ZTypeActions } from "./roles/roleTypes";
import {
  loadAttributePermissions,
  loadObjectStates,
  loadRoleGroups,
  loadTypeActions,
  saveObjectRolesMap,
  smartDeleteObjectStates,
} from "./roles/rolesApi";
import {
  activateValueNodeO2,
  addNodeToValue,
  createAttrNode2,
  createGroupNode2,
  createMainTree,
  createObjNode2,
  loadValues,
  updateAllAttrNodes,
  updateAttrNode2,
  updateGroupNode2,
  updateObjNode2,
} from "./utils/createMainTree";
import { findAttrById } from "./utils/findAttrById";
import { findNodeOwnerObject } from "./utils/findNodeOwnerObject";
import { commonAttrSave, commonGroupSave } from "./utils/groupAndAttrSave";
import { transformEdAttVals4Save } from "./utils/transforms";
import { initTypeIcons } from "./utils/typeIcons";
import {
  loadDelayedNotify,
  saveDelayedNotify,
} from "./DelayedNotify/apiDelayedNotify";
import { EdDelayedNotifyDict } from "./DelayedNotify/EdDelayedNotify";

export class Obj2TabStore {
  constructor() {
    this.formStore = new ModelessFormStore(
      async (values) => {
        await this.save(values);
      },
      () => {
        this.killNewEntity();
      },
    );
    makeAutoObservable(this, {
      treeRef: false,
    });
  }

  formStore: ModelessFormStore;

  objOrderStore = new Obj2OrdersStore();

  selectDictionaryStore = new SelectDictionaryStore();

  async loadObjectOptions(): Promise<ZIdName[]> {
    return this.treeData.reduce((acc, node) => {
      if (node.type !== "obj") return acc;
      return [...acc, { id: node.object.id, name: node.object.name }];
    }, [] as ZIdName[]);
  }

  get currentObjectId(): number | null {
    const { curNode } = this;
    if (!curNode) return null;
    if (curNode.type === "obj") {
      return curNode.object.id;
    }
    const objNode = findNodeOwnerObject(curNode.key, this.treeData);
    return objNode?.type === "obj" ? objNode.object.id : null;
  }

  // Ссылка на компонент дерева. Используется
  treeRef: RcTree | null = null;

  setTreeRef(ref: RcTree | null) {
    this.treeRef = ref;
  }

  // Справочник атрибутов

  attrTypesList: IdLabel[] = [];

  setAttrTypesList(list: IdLabel[]) {
    this.attrTypesList = list;
  }

  get attrTypesDict(): Record<number, string> {
    return makeDictNameById(this.attrTypesList);
  }

  // Справочник групп
  groupTypesList: ZIdGroupLabel[] = [];

  setGroupTypesList(list: ZIdGroupLabel[]) {
    this.groupTypesList = list;
  }

  get groupTypeByName(): Record<GroupType, ZIdGroupType> {
    return this.groupTypesList.reduce(
      (acc, it) => ({ ...acc, [it.name as GroupType]: it }),
      {} as Record<GroupType, ZIdGroupType>,
    );
  }

  // Группы и Роли
  roleGroups: ZRolesGroup[] = [];

  setRoleGroups(data: ZRolesGroup[]) {
    this.roleGroups = data;
  }

  // Типы и акции
  typeActions: ZTypeActions[] = [];

  setTypeActions(data: ZTypeActions[]) {
    this.typeActions = data;
  }

  get objectTypeActions(): ZTypeActions[] {
    return this.typeActions.filter(
      ({ type }) => type === "OBJECT" || type === "ENTITY",
    );
  }

  get attrTypeActions(): ZTypeActions[] {
    return this.typeActions.filter(({ type }) => type === "ATTRIBUTE");
  }

  data: RemoteData<CommonNodeO2[]> = { status: "none" };

  setData(newData: RemoteData<CommonNodeO2[]>) {
    this.data = newData;
  }

  get treeData(): CommonNodeO2[] {
    const { data } = this;
    return data.status === "ready" ? data.result : [];
  }

  refreshTree() {
    if (this.data.status === "ready") {
      this.data.result = [...this.data.result];
    }
  }

  // Инициализация вызывается каждый раз при переключении на вкладку
  async init() {
    try {
      this.setData({ status: "wait" });
      if (this.attrTypesList.length === 0) {
        this.setAttrTypesList(await getIdLabels("attrType", "attrType"));
        initTypeIcons(this.attrTypesDict);
      }
      if (this.groupTypesList.length === 0) {
        const rawGroupTypes = await getIdLabels("groupType", "groupType");
        const rightTypes = rawGroupTypes.filter(
          // eslint-disable-next-line no-underscore-dangle
          ({ name }) => name in zGroupType._def.values,
        );
        this.setGroupTypesList(zIdGroupLabel.array().parse(rightTypes));
        // Контроль содержимого справочника
        [GroupType.Mnemonic, GroupType.ByDictionary].forEach((name) => {
          if (!this.groupTypeByName[name])
            throw Error(`В справочнике типов групп отсутствует ${name}`);
        });
      }
      this.setRoleGroups(await loadRoleGroups());
      if (this.typeActions.length === 0) {
        this.setTypeActions(await loadTypeActions());
      }
      const objects = await loadObjects();
      const result = createMainTree(objects);
      this.setData({ status: "ready", result });
      // При переключении на вкладку нужно выбрть текуший node, чтобы згрузить его данные из loadNodeData
      if (this.curNode) {
        this.unsafeSelect(this.curNode.key);
      }
    } catch (error) {
      this.setData({ status: "error", error });
    }
  }

  async save(values: unknown): Promise<void> {
    const { curNode } = this;
    if (!curNode) return;
    await visitO2(curNode, {
      attr: async (it) => this.saveAttribute(it, values),
      group: async (it) => this.saveGroup(it, values),
      obj: (it) => this.saveObject(it.object, values),
      value: async () => {
        throw Error("Нельзя сохранять значение");
      },
    });
  }

  // ----- expanding
  expanded = new Set<React.Key>();

  get expandedKeys(): React.Key[] {
    return Array.from(this.expanded);
  }

  expand(key: React.Key, isExpand: boolean) {
    if (isExpand) {
      const node = findNodeByKey(key, this.treeData);
      if (node)
        visitO2(node, {
          obj: () => undefined,
          attr: () => undefined,
          group: (it) => this.onExpandGroup(it),
          value: (it) => this.activateValue(it),
        });
      this.expanded.add(key);
    } else {
      this.expanded.delete(key);
    }
  }

  onExpandGroup(groupNode: GroupO2) {
    groupNode
      .onExpand?.(groupNode)
      .catch(onError)
      .finally(() => this.refreshTree());
  }

  // ----- selection
  curSelect: React.Key | null = null;

  setCurSelect(key: React.Key | null) {
    this.curSelect = key;
  }

  get selectedKeys(): React.Key[] {
    return this.curSelect ? [this.curSelect] : [];
  }

  get curNode(): CommonNodeO2 | null {
    if (!this.curSelect) return null;
    return findNodeByKey(this.curSelect, this.treeData) ?? null;
  }

  get curObjectId(): number | null {
    const { curNode } = this;
    if (!curNode) return null;
    if (curNode.type === "obj") return curNode.object.id;
    const owner = findNodeOwnerObject(curNode.key, this.treeData);
    return owner?.type === "obj" ? owner.object.id : null;
  }

  get curObjectName(): string | null {
    const { curNode } = this;
    if (!curNode) return null;
    if (curNode.type === "obj") return curNode.object.name;
    const owner = findNodeOwnerObject(curNode.key, this.treeData);
    return owner?.type === "obj" ? owner.object.name : null;
  }

  get curAttrValue(): ValueO2 | null {
    const { curNode } = this;
    if (!curNode || curNode.type !== "attr") return null;
    const owner = findNodeOwnerByKey(curNode.key, this.treeData)?.owner;
    return owner?.type === "value" ? owner : null;
  }

  safeSelect(key: React.Key | null) {
    this.formStore.safeAction(() => this.unsafeSelect(key));
  }

  unsafeSelect(key: React.Key | null, isNewItem?: boolean) {
    this.setCurSelect(key);
    const node = key && findNodeByKey(key, this.treeData);
    if (!node) {
      this.formStore.unsafeLoad(null);
    } else {
      this.loadNodeData(node, isNewItem);
    }
    if (key) {
      // Похоже, что такая фича работает только для virtual
      delay(10)
        .then(() => this.treeRef?.scrollTo({ key }))
        .catch(onError);
    }
  }

  safeUpToObject() {
    const { curNode } = this;
    if (!curNode || curNode.type === "obj") return;
    const obj = findNodeOwnerObject(curNode.key, this.treeData);
    if (!obj) return;
    this.safeSelect(obj.key);
  }

  loadCounter = 0;

  async loadNodeData(node: CommonNodeO2, isNewItem?: boolean) {
    // eslint-disable-next-line no-plusplus
    const currentCounter = ++this.loadCounter;
    const testStates = (states: ZObjState[]): ZObjState[] => {
      const ids = new Set<number>();
      let bad = false;
      states.forEach(({ id }) => {
        if (ids.has(id)) bad = true;
        ids.add(id);
      });
      if (bad) {
        notification.warning({
          message:
            "Список состояний содержит некорректные данные. Это может вызвать проблемы в работе приложения",
        });
      }
      return states;
    };
    try {
      this.setNodeData({ status: "wait" });
      const result = await visitO2<Promise<unknown>>(node, {
        obj: async (it) => {
          const objectId = it.object.id;
          await this.updateServList(it);
          if (objectId !== newItemId) {
            // eslint-disable-next-line no-param-reassign
            it.attrList = await loadObjectAttrinbutesAll(objectId);
          }
          const states: ZObjState[] | undefined =
            objectId !== newItemId
              ? testStates(await loadObjectStates(objectId))
              : undefined;
          const translation = await loadTranslation(it.object.name);
          const rolesMap: EdObjStates | undefined = states
            ? await makeRolesMap(objectId, states)
            : undefined;
          const templatesMap = await loadNotifyTemplates(objectId);
          const delayedNotifyMap = await loadDelayedNotify(objectId);

          return obj2edit(
            it.object,
            states,
            rolesMap,
            translation.translations,
            templatesMap,
            delayedNotifyMap,
          );
        },
        attr: async (it) => {
          const attrId = it.attr.id;
          const objectNode = findNodeOwnerObject(it.key, this.treeData);
          if (!objectNode) throw Error("Не найден объект для атрибута");
          if (objectNode.type !== "obj")
            throw Error("Неправильный тип узла объекта");
          const objectId = objectNode.object.id;
          const states = testStates(await loadObjectStates(objectId));
          // eslint-disable-next-line no-param-reassign
          it.states = states; // тут будет warning в консоли
          const restrictions = await loadRestrictions(attrId);
          type RoleDef = [number, ZPermissions];
          const createRoleDef = async (stateId: number): Promise<RoleDef> => [
            stateId,
            attrId === newItemId
              ? createPermissions(this.attrTypeActions, this.roleGroups)
              : await loadAttributePermissions(attrId, stateId),
          ];
          const roleDefs: RoleDef[] = await Promise.all(
            states.map(({ id }) => createRoleDef(id)),
          );
          const rolesMap: EdObjStates = {};
          roleDefs.forEach(([stateId, permissions]) => {
            rolesMap[String(stateId)] = permissions;
          });
          const { translations } = await loadTranslation(it.attr.name);
          return attr2edit(it.attr, rolesMap, restrictions, translations);
        },
        group: async (it) => {
          const { translations } = await loadTranslation(it.group.name);
          return group2ed({ group: it.group, translations });
        },
        value: async (it) => {
          this.activateValue(it);
          // Для value формы нет, т.к. редактирование справочников в другой вкладке
          // но нужно выдать непустое значение, т.к. иначе ModelessForm не покажет содержимое.
          return it.value;
        },
      });
      if (currentCounter === this.loadCounter) {
        this.setNodeData({ status: "ready", result });
        this.formStore.unsafeLoad(result, isNewItem);
      }
    } catch (error) {
      if (currentCounter === this.loadCounter) {
        this.setNodeData({ status: "error", error });
      }
    }
  }

  nodeData: RemoteData<unknown> = { status: "none" };

  setNodeData(data: RemoteData<unknown>) {
    this.nodeData = data;
  }

  get stateOptions(): SelectProps["options"] | null {
    const { nodeData, curNode } = this;
    if (nodeData.status === "ready" && curNode?.type === "obj") {
      return (
        (nodeData.result as EdObject).states?.map(({ id, name }) => ({
          value: id,
          label: name,
        })) || null
      );
    }
    return null;
  }

  get objectOptions(): SelectProps["options"] | null {
    const { data } = this;
    return data.status === "ready"
      ? data.result.reduce<SelectProps["options"]>((result, node) => {
          if (node.type === "obj") {
            const { id, name } = node.object;
            result?.push({ value: id, label: name });
          }
          return result;
        }, [])
      : null;
  }

  /**
   * Добавление узлов к текущему выбранному узлу (кроме объектов т.к. они не имеют владельцев)
   */
  safeAddNewNode(what: ZAttribute | ZLightGroup) {
    this.formStore.safeAction(() => this.unsafeAddNewNode(what));
  }

  unsafeAddNewNode(what: ZAttribute | ZLightGroup) {
    const { curNode } = this;
    if (curNode) {
      const actions: Actions = {
        update: true,
        delete: true,
      };
      const newNode: CommonNodeO2 =
        "groupType" in what
          ? createGroupNode2(what, actions)
          : createAttrNode2(what, actions, curNode.key as string);
      curNode.children = [...(curNode.children ?? []), newNode];
      curNode.isLeaf = false;
      this.refreshTree();
      this.expand(curNode.key, true);
      this.unsafeSelect(newNode.key, true);
    }
  }

  // ------ objects
  safeCreateObject() {
    this.formStore.safeAction(() => this.unsafeCreateObject());
  }

  unsafeCreateObject() {
    if (this.data.status === "ready") {
      const obj: ZObjectItem = {
        id: newItemId,
        name: "",
        attributes: [],
      };
      const node = createObjNode2(obj);
      this.data.result = [...this.data.result, node];
      this.unsafeSelect(node.key, true);
    }
  }

  // Для случаев, когда новый объект кем-то создан снаружи
  onNewObject(obj: ZObjectItem) {
    if (this.data.status === "ready") {
      const node = createObjNode2(obj);
      this.data.result = [...this.data.result, node];
      this.sortObjects();
      this.unsafeSelect(node.key, false);
    }
  }

  async saveObject(obj: ZLightObject, values: unknown) {
    const {
      lightObject,
      rolesMap,
      states,
      translation,
      templatesMap,
      delayedNotifyMap,
    } = edit2obj(values as EdObject);

    const objFields = zLightObject.partial().parse(lightObject);
    const srcObject = { ...obj, ...objFields };
    const { id, ...other } = srcObject;
    const isNew = id === newItemId;
    let createdObject: ZObjectItem | undefined;
    let newObject: ZLightObject;
    if (isNew) {
      createdObject = await createLightObject(other);
      newObject = createdObject;
    } else {
      newObject = await saveLightObject(id, srcObject);
    }
    const tasks: Promise<unknown>[] = [];

    if (translation) {
      tasks.push(
        saveTranslation(obj.name, {
          value: objFields.name ?? "",
          translations: translation,
        }),
      );
    }

    if (rolesMap) {
      tasks.push(saveObjectRolesMap(newObject.id, rolesMap));
    }
    // Если были удалены какие-то состояния, то нужно выполнить соответствующие запросы
    if (states) {
      tasks.push(smartDeleteObjectStates(newObject.id, states));
    }

    // Это потребовалось по той причине, что внутри формы могут потеряться эти данные из-за бага в Ant Design
    // Из-за вложенности Collapse -> Tabs -> FormList
    // Используется тот факт, что если данные потерялись, то в карте для них будет undefined
    // Это отличается от случая, когда пользователь удалил все шаблоны. Тогда будет пустой список.
    const saveTemplatesPatch = async (
      objectId: number,
      newMap: EdNotifyTemplateDict,
    ) => {
      const oldMap = await loadNotifyTemplates(objectId);
      Object.entries(newMap).forEach(([stateId, content]) => {
        if (content) oldMap[+stateId] = content;
      });
      await saveNotifyTemplates(objectId, oldMap);
    };
    if (templatesMap) {
      tasks.push(saveTemplatesPatch(newObject.id, templatesMap));
    }

    const saveDelayedNotifyPatch = async (
      objectId: number,
      newMap: EdDelayedNotifyDict,
    ) => {
      const oldMap = await loadDelayedNotify(objectId);
      Object.entries(newMap).forEach(([stateId, content]) => {
        if (content) oldMap[+stateId] = content;
      });
      await saveDelayedNotify(objectId, oldMap);
    };
    if (delayedNotifyMap) {
      tasks.push(saveDelayedNotifyPatch(newObject.id, delayedNotifyMap));
    }

    await Promise.all(tasks);
    const node = findNodeByKey(makeObjKey2(id), this.treeData);
    updateObjNode2(node, newObject);

    // Если создан новый объект, то у него сразу появляются некоторые атрибуты.
    if (node?.children) {
      createdObject?.attributes.forEach((newAttr) => {
        node.children?.push(
          createAttrNode2(newAttr, node.actions, node.key as string),
        );
        node.isLeaf = false;
      });
    }

    // Так как могло поменяться имя объекта, нужно отсортировать список заново.
    this.sortObjects();
    this.unsafeSelect(makeObjKey2(newObject.id));
  }

  sortObjects() {
    const key = (node: CommonNodeO2) => getNodeName(node).toUpperCase();
    const cmp = (a: string, b: string) => {
      if (a < b) return -1;
      if (b < a) return 1;
      return 0;
    };
    if (this.data.status === "ready") {
      this.data.result.sort((a, b) => cmp(key(a), key(b)));
    }
    this.refreshTree();
  }

  // -------- groups
  safeAddGroup(type: GroupType) {
    this.formStore.safeAction(() => this.safeAddNewNode(this.newGroup(type)));
  }

  async saveGroup(groupNode: GroupO2, values: unknown) {
    const oldKey = groupNode.key;
    const { attributeId } = groupNode.group;
    const newGroup = await commonGroupSave(groupNode, values, this.treeData);
    updateGroupNode2(groupNode, newGroup);
    // Если изменился attributeId, то надо обновлять значения
    if (newGroup.attributeId !== attributeId) {
      await loadValues(groupNode);
    }
    this.refreshTree();
    if (oldKey !== groupNode.key) this.unsafeSelect(groupNode.key);
  }

  // --------- attributes
  safeAddAttr() {
    this.formStore.safeAction(() => this.safeAddNewNode(newAttr()));
  }

  async addExistingAttr(valueNode: ValueO2, attrId: number) {
    try {
      const { owner } = findNodeOwnerByKey(valueNode.key, this.treeData) ?? {};
      if (!owner || owner.type !== "group")
        throw Error("Не найдена группа-владелец");
      const attr = findAttrById(attrId, [owner]);
      if (!attr) throw Error("Не найден атрибут по id");
      await apiAddExistingAttr(owner.group.id, valueNode.value.id, attrId);
      const newNode = createAttrNode2(
        attr,
        valueNode.actions,
        valueNode.key as string,
      );
      addNodeToValue(newNode, valueNode);
      this.expand(valueNode.key, true);
      this.unsafeSelect(newNode.key);
    } catch (e) {
      onError(e);
    }
  }

  async saveAttribute(attrNode: AttributeO2, values: unknown) {
    const oldKey = attrNode.key;
    const transformedValues = await transformEdAttVals4Save(
      values as EdAttribute,
      Number(this.curObjectId),
    );
    const { editorInfo, withRestrictions } = transformedValues;
    const { attr: newAttr, owner } = await commonAttrSave(
      attrNode,
      transformedValues,
      this.treeData,
    );
    updateAttrNode2(attrNode, newAttr, owner.key);
    const objNode = findNodeOwnerObject(attrNode.key, this.treeData);
    if (objNode?.type !== "obj") return;

    if (
      withRestrictions ||
      editorInfo?.itemProps?.copyByValueRule?.groupIdsToCopy?.length
    ) {
      const newObj = await loadObject(objNode.object.id);
      this.internalDeleteNode(objNode);
      const node = createObjNode2(newObj);
      if (this.data.status === "ready") {
        this.data.result = [...this.data.result, node];
        this.sortObjects();
      }
    } else updateAllAttrNodes(newAttr, objNode.children, objNode.key);
    this.refreshTree();
    if (oldKey !== attrNode.key) this.unsafeSelect(attrNode.key);
  }

  // --------- values
  activateValue(valueNode: ValueO2) {
    activateValueNodeO2(valueNode)
      .finally(() => this.refreshTree())
      .catch(onError);
    this.refreshTree();
  }

  // ---- delete
  safeDeleteNode(node: CommonNodeO2) {
    this.setDeletingNode(node);
  }

  safeDeleteCurNode() {
    if (this.curNode) this.safeDeleteNode(this.curNode);
  }

  killNewEntity() {
    const { curNode } = this;
    if (curNode) {
      const isNew = visitO2(curNode, {
        obj: (it) => it.object.id === newItemId,
        group: (it) => it.group.id === newItemId,
        attr: (it) => it.attr.id === newItemId,
        value: () => false,
      });
      if (isNew) {
        this.internalDeleteNode(curNode);
        this.unsafeSelect(null);
      }
    }
  }

  deletingNode: CommonNodeO2 | null = null;

  setDeletingNode(node: CommonNodeO2 | null) {
    this.deletingNode = node;
  }

  deletingWait = false;

  setDeletingWait(flag: boolean) {
    this.deletingWait = flag;
  }

  internalDeleteNode(node: CommonNodeO2) {
    const { data } = this;
    if (data.status === "ready") {
      const treeData = data.result;
      if (node.type === "obj") {
        data.result = data.result.filter(({ key }) => key !== node.key);
      } else {
        const res = findNodeOwnerByKey(node.key, treeData);
        if (res) {
          res.owner.children?.splice(res.index, 1);
        }
      }
    }
    this.refreshTree();
  }

  get messageAboutDelete() {
    const { deletingNode } = this;
    if (!deletingNode) return "Удалить?";
    return visitO2(deletingNode, {
      obj: (it) =>
        it.object.name
          ? `Удалить объект "${it.object.name}"`
          : `Удалить объект?`,
      attr: () => "Удалить атрибут?",
      group: () => "Удалить группу атрибутов?",
      value: () => "Удалить значение?",
    });
  }

  async doDelete() {
    const { deletingNode, curAttrValue } = this;
    if (!deletingNode) return;
    try {
      this.setDeletingWait(true);
      await visitO2<Promise<void>>(deletingNode, {
        obj: async (it) => {
          if (it.object.id !== newItemId) await deleteObject(it.object.id);
        },
        attr: async (it) => {
          if (curAttrValue) {
            await this.deleteAttrFromValue(it);
          } else if (it.attr.id !== newItemId)
            await deleteAttribute(it.attr.id);
        },
        group: async (it) => {
          if (it.group.id !== newItemId) {
            await deleteGroup(it.group.id);
          }
        },
        value: async () => {
          throw Error("Нельзя удалить значение");
        },
      });
      this.internalDeleteNode(deletingNode);
      this.setDeletingNode(null);
      if (this.curNode && this.curNode.key === this.deletingNode?.key) {
        this.unsafeSelect(null);
      }
    } catch (e) {
      onError(e);
    } finally {
      this.setDeletingWait(false);
    }
  }

  async deleteAttrFromValue(node: AttributeO2) {
    const { curAttrValue } = this;
    if (!curAttrValue) return;
    const group = findNodeOwnerByKey(curAttrValue.key, this.treeData)?.owner;
    if (group?.type !== "group") return;
    await deleteAttributeFromValue(
      group.group.id,
      curAttrValue.value.id,
      node.attr.id,
    );
  }

  newGroup(t: GroupType): ZLightGroup {
    const ext = this.groupTypeByName[t];
    if (!ext) throw Error(`Не найдено описание типа группы ${t}`);
    return {
      id: newItemId,
      name: "",
      groupType: { id: ext.id, name: ext.name },
    };
  }

  /**
   * Изменение порядка следования подчинённых узлов (приходит из Obj2Orders)
   * @param ownerKey
   * @param order
   */
  updateOrder(ownerKey: React.Key, order: React.Key[]) {
    const ownerNode = findNodeByKey(ownerKey, this.treeData);
    if (!ownerNode) return;
    const srcNodes: CommonNodeO2[] = ownerNode.children ?? [];
    const nodesMap = srcNodes.reduce(
      (acc, node) => ({ ...acc, [String(node.key)]: node }),
      {} as Record<string, CommonNodeO2>,
    );
    const updateIsLeaf = (owner: CommonNodeO2) => {
      if (owner.type === "group") {
        const { children } = owner;
        // eslint-disable-next-line no-param-reassign
        owner.isLeaf = children?.length === 0;
      }
    };
    let dstNodes: CommonNodeO2[] = [];
    order.forEach((key) => {
      const node = nodesMap[String(key)];
      if (node) {
        dstNodes.push(node);
        delete nodesMap[String(key)];
      } else {
        const dragResult = findNodeOwnerByKey(key, this.treeData);
        if (dragResult) {
          const { owner, index } = dragResult;
          const newNode = owner.children?.splice(index, 1)[0];
          updateIsLeaf(owner);
          if (newNode) {
            dstNodes.push(newNode);
          }
        }
      }
    });
    dstNodes = [...dstNodes, ...Object.values(nodesMap)];
    ownerNode.children = dstNodes;
    updateIsLeaf(ownerNode);
    this.refreshTree();
  }

  // Используется для фильтрации атрибутов, на которые может ссылаться группа со словарём
  // Множественный словарь не допускается, т.к. пока не понятно как это может работать.
  isDictionary1Type(typeId: number): boolean {
    return this.attrTypesDict[typeId] === AttrTypeName.dictSingle;
  }

  // async addObjState(node: ObjectO2, name: string): Promise<{state: ZObjState, permissions: ZPermissions}> {
  //   const state: ZObjState = await createObjectState(node.object.id, name);
  //   node.states = [...(node.states ?? []), state];
  //   return {state, permissions: createPermissions(this.typeActions, this.roleGroups)};
  // }
  // deleteObjState(node: ObjectO2, stateId: number): number {
  //   const states = (node.states ?? []).filter(({id}) => id !== stateId);
  //   node.states = states;
  //   const id0 = states[0]?.id;
  //   return id0 ? id0 : stateId;
  // }

  // ----- бизнес-сервисы

  async createService(objNode: ObjectO2, values: EdBusinessService) {
    const objectId = objNode.object.id;
    if (objectId === newItemId)
      throw Error("Нельзя связать сервис с еще не сохранённым объектом");
    const { nameLoc, ...restServInfo } = values;
    const newServInfo = await createServiceForObject({
      ...restServInfo,
      objectId,
    });
    await saveTranslation(newServInfo.name, {
      value: newServInfo.name,
      translations: nameLoc,
    });
    await this.updateServList(objNode);
  }

  servUpdating = false;

  setServUpdating(on: boolean) {
    this.servUpdating = on;
  }

  async updateService(
    objNode: ObjectO2,
    bs: BusinessServiceDescriptor,
    servInfo: ZObjectService,
    values: unknown,
  ) {
    try {
      this.setServUpdating(true);
      const tasks: Promise<void>[] = [];
      // Сохранение названия
      const { name, nameLoc } = values as EdBusinessService;
      if (name && name !== servInfo.name) {
        throw Error("Пока нет метода для изменения названия сервиса");
        // Когда появится - нужен tasks.push(...)
      }
      // Локализация названия
      if (nameLoc) {
        tasks.push(
          saveTranslation(servInfo.name, {
            value: name,
            translations: nameLoc,
          }),
        );
      }

      // Сохранение данных бизнес-сервиса
      tasks.push(bs.saveSettings(servInfo, values));
      await Promise.all(tasks);
      const serv = objNode.services?.find(({ id }) => servInfo.id === id);
      if (serv) {
        serv.name = name; // Поменять название вкладки
      }
    } catch (e) {
      onError(e);
    } finally {
      this.setServUpdating(false);
    }
  }

  async deleteService(objNode: ObjectO2, servInfo: ZObjectService) {
    await deleteServiceForObject(servInfo);
    await this.updateServList(objNode);
  }

  async updateServList(objNode: ObjectO2) {
    // Если не новый объект, то подгрузить связанные бизнес-сервисы
    const objectId = objNode.object.id;
    if (objectId !== newItemId) {
      this.setObjServices(objNode, await loadServicesForObject(objectId));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setObjServices(objNode: ObjectO2, services: ObjectServiceExt[]) {
    // eslint-disable-next-line no-param-reassign
    objNode.services = services;
  }
}

const newAttr = (): ZAttribute => ({
  id: newItemId,
  name: "",
  valueType: undefined as unknown as number,
});
