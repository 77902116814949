import { makeAutoObservable } from "mobx";
import { ObjectServiceExt } from "src/businessServices/businessServises.types";
import { onError } from "src/common/onError";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import {
  clearRCAuth,
  createEntityChat,
  deleteEntityChat,
  loadEntityChats,
  loginChat,
  setRCAuth,
  updateEntityChat,
} from "./apiChat";
import { ZChatInfo, zChatInfo } from "./bsChat.types";
import { ChatFormStore } from "./ChatForm/ChatFormStore";
import { CountersStore } from "./CounterUnreadMessages/CountersStore";
import { getUniqUserValsFromAttsUL } from "./getUniqUserValsFromAttsUL";
import { PersonComplexStore } from "./NewChatButton/PersonComplexStore";
import { RCEventListener } from "./rocketChat/RCEventListener";
import { RCSocket } from "./rocketChat/RCSocket";

export const isDefaultchat = (ci: ZChatInfo | null, info: ObjectServiceExt) =>
  ci?.name === info.name;

export type PropsChatListStore = {
  info: ObjectServiceExt;
  countersStore: CountersStore;
  countersEventListener: RCEventListener;
  personComplexStore: PersonComplexStore;
};

export class ChatListStore {
  constructor(public props: PropsChatListStore) {
    makeAutoObservable(this);
  }

  setProps(newProps: PropsChatListStore) {
    this.props = newProps;
  }

  rcSocket = new RCSocket();

  chats: ChatFormStore[] = [];

  setChats(list: ChatFormStore[]) {
    this.chats = list;
  }

  chatMessages: Record<string, string> = {};

  saveMessage(chatId: string, message: string) {
    this.chatMessages[chatId] = message;
  }

  getMessage(chatId: string) {
    return this.chatMessages[chatId] || "";
  }

  activeChatId: string | null = null;

  setActiveChatId(id: string | null) {
    this.activeChatId = id;
  }

  entityId: string | null = null;

  setEntityId(id: string) {
    this.entityId = id;
  }

  get sortedChats() {
    const { info } = this.props;
    return this.chats
      .slice()
      .sort((chat) => (isDefaultchat(chat.chatInfo, info) ? -1 : 1));
  }

  get activeChatStore() {
    return this.sortedChats.find((c) => c.listenerId === this.activeChatId);
  }

  handleChatChange(chatId: string, prevChatId: string) {
    const { countersStore } = this.props;
    if (chatId) {
      this.setActiveChatId(chatId);
      countersStore.readAllChatMessages(chatId);
    }
    if (prevChatId) countersStore.readAllChatMessages(prevChatId);
  }

  clear() {
    this.setChats([]);
    this.setActiveChatId(null);
  }

  async init(
    entityId: string,
    defaultChatName: string,
    data: EntityCardDataExt,
  ) {
    try {
      this.clear();
      const allowedChats = await loadEntityChats(entityId);
      await this.rcSocket.init([]);
      this.setEntityId(entityId);
      const isEmptyChats = () =>
        allowedChats.length === 0 && this.chats.length === 0;
      /**
       * если список чатов пуст, то создаем дефолтный чат.
       * дефолтный чат создается автоматически из настроек бизнесс-процесса
       */
      if (isEmptyChats()) {
        const allUsers = await getUniqUserValsFromAttsUL(data);
        await this.createChat(defaultChatName, allUsers);
      }
      /**
       * Если и после попытки создания дефолтного чата список пуст - это говорит
       * об ошибке
       */
      if (isEmptyChats()) throw new Error("init chat error");
      /**
       * и только теперь логнимся в системе рокет чата
       * потому что мы уверены, что наш токен свежий.
       * Это костыль. Тут мы сналала логинимся через рест и получаем токен (authToken рокет чата),
       * а потом только делаем рефреш с полученным токеном через сокет.
       * это приходится делать потому что нет информации о том, как сделать логин через сокет с нашим
       * внутренним токеном, связанным с кейклок, хотя для рест логина такая информация есть.
       */
      const authData = await loginChat();
      setRCAuth(authData);
      this.setChats([
        ...this.chats,
        ...allowedChats.map((chatInfo) => new ChatFormStore(chatInfo)),
      ]);

      this.chats.forEach((chat) => this.joinChat(chat));
    } catch (error) {
      onError(error);
    }
  }

  async createChat(name: string, userIds: string[]) {
    try {
      if (this.entityId) {
        const newChatInfo = zChatInfo.parse(
          (await createEntityChat(this.entityId, name, userIds)).data,
        );
        const newChatFromStore = new ChatFormStore(newChatInfo);
        this.setChats([...this.chats, newChatFromStore]);
        return newChatFromStore;
      }
      return null;
    } catch (error) {
      onError(error);
      return null;
    }
  }

  joinChat(store: ChatFormStore) {
    this.rcSocket.addListener(store);
    this.rcSocket.joinRoom(store.listenerId);
  }

  async updateChat(chatId: string, name: string, userIds: string[]) {
    try {
      const updatedChat = await updateEntityChat(chatId, name, userIds);
      const oldChat = this.chats.find((c) => c.listenerId === updatedChat.id);
      oldChat?.setChatInfo(updatedChat);
    } catch (error) {
      onError(error);
    }
  }

  async deleteChat(chatId: string) {
    const { countersStore } = this.props;
    await countersStore.readAllChatMessages(chatId);
    await deleteEntityChat(chatId);
    this.rcSocket.removeListener(chatId);
    this.setChats(this.chats.filter((c) => c.listenerId !== chatId));
    this.setActiveChatId(this.chats[0]?.listenerId ?? null);
  }

  dispose() {
    clearRCAuth();
    this.rcSocket.close();
  }
}
