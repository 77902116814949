import { ZAttribute } from "src/types/ZAttribute";
import {
  loadObjectAttrinbutesAll,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { loadTableData } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore2";
import { apiObjUrl } from "src/common/apiUrl";
import { getEditorInfo } from "src/common/attrEdit";
import { getViewInfo } from "src/common/attrView";
import { makeDictionary } from "src/common/makeDictionary";
import { AttrLabelLoader, AttrLabelLoaderParams } from "../types";
import { loadAttrLabelData } from "../loadAttrLabelData";
import { AttrLabelTreeData, defAttrLabelTreeData } from "../AttrLabelTreeData";

export const labelLoaderChildEntities: AttrLabelLoader = async (
  _: string[],
  attr: ZAttribute,
  params?: AttrLabelLoaderParams,
) => {
  const recursionRegister = params?.recursionRegister;
  if (!attr) return null;
  if (recursionRegister?.has(attr.id))
    return defAttrLabelTreeData([], attr, undefined, true);
  const editorInfo = getEditorInfo(attr.viewStyles);
  const viewInfo = getViewInfo(attr.viewType);
  const cmpEditor = editorInfo?.component;
  const cmpView = viewInfo?.component;
  const isRightComponent =
    cmpEditor?.editor === "ChildEntities" &&
    cmpView?.view === "ChildEntitiesCellInfo";
  if (!isRightComponent) return null;
  const ctx = params?.ctx;
  const objectId = attr?.referenceId;
  const entityId = ctx?.entityId;
  const linketAttrId = cmpEditor?.attrId;
  const isDataConsisted = !!objectId && !!entityId && !!linketAttrId;
  if (!isDataConsisted) return null;
  const attrMetaMap = makeObjectAttributesMap(
    await loadObjectAttrinbutesAll(objectId, { translate: true }),
  );
  const { content } = await loadTableData(apiObjUrl("/entities/search"), {
    page: 0,
    pageSize: 30, // по договоренности с Максом
    filters: {
      objectId,
      entityId,
      parentLinkAttributeId: Number(linketAttrId),
    },
  });
  const labels = content.map(({ attributeValues }) => {
    const entAttsDict = makeDictionary(
      attributeValues,
      ({ attributeId }) => attributeId,
    );
    return cmpView.labelAtts
      .map((attributeId) => {
        const nestedAttr = attrMetaMap[Number(attributeId)];
        const nestedVals = (
          entAttsDict[Number(attributeId)]?.values || []
        ).filter(Boolean);
        const nestedViewInfo = getViewInfo(nestedAttr?.viewType);
        if (
          nestedVals.length === 0 ||
          !nestedViewInfo ||
          !nestedAttr ||
          !nestedViewInfo.appearance
        )
          return null;
        nestedViewInfo.appearance.view = viewInfo?.appearance?.view;
        return loadAttrLabelData(nestedVals, nestedAttr, {
          recursionRegister: recursionRegister || new Set([attr.id]),
        });
      })
      .filter(Boolean) as Promise<AttrLabelTreeData | null>[];
  });
  const flat = await Promise.all(labels.flat());
  return defAttrLabelTreeData(
    [],
    attr,
    flat.filter(Boolean) as AttrLabelTreeData[],
  );
};
