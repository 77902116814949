import * as React from "react";
import {
  Button,
  Empty,
  Form,
  InputNumber,
  Mentions,
  Radio,
  Select,
  Switch,
} from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { ZOption } from "src/types/ZOption";
import { onError } from "src/common/onError";
import { RoleSelect } from "src/components/RoleSelect";
import { appStore } from "src/appStore";
import { getLangName } from "src/types/ZLanguageProps";
import { ZAttribute } from "src/types/ZAttribute";
import { EdObject } from "../EdObject";
import { ZRolesGroup } from "../roles/roleTypes";
import { EdDelayedNotify } from "./EdDelayedNotify";
import { loadTemplatePlaceholders } from "../NotifyTemplates/loadTemplatePlaceholders";
import styles from "./DelayedNotify.module.less";
import { ZNotifyType } from "../NotifyTemplates/ZNotifyTemplate";
import { NotifyTimePicker } from "./NotifyTimePicker";

interface PropsDelayedNotify {
  stateId: string;
  roles: ZRolesGroup[];
  userListAttrs: ZAttribute[];
}

const root: keyof EdObject = "delayedNotifyMap";

const fKey = (key: keyof EdDelayedNotify) => key;

const typesMap: Record<ZNotifyType, string> = {
  [ZNotifyType.push]: "Push",
  [ZNotifyType.email]: "Email",
  [ZNotifyType.all]: "Все",
};
const typeOptions = (): { value: number; label: string }[] =>
  Object.entries(typesMap).map(([key, label]) => ({ value: +key, label }));

export const DelayedNotify: React.FC<PropsDelayedNotify> = ({
  stateId,
  roles: roleGroups,
  userListAttrs,
}) => {
  const [placeholders, setPlaceholders] = React.useState<ZOption[]>([]);
  React.useEffect(() => {
    loadTemplatePlaceholders().then(setPlaceholders).catch(onError);
  }, []);

  return (
    <Form.List name={[root, stateId]}>
      {(fields, { add, remove }) => (
        <div className={styles.formListItem}>
          {fields.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {fields.map((field, index) => (
            <React.Fragment key={field.key}>
              <div className={styles.header}>
                <div className={styles.title}>Шаблон {index + 1}</div>
                <Button
                  size="small"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => remove(field.name)}
                  title="Удалить шаблон"
                />
              </div>
              <Form.Item
                name={[field.name, fKey("active")]}
                label="Отправка уведомления"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name={[field.name, fKey("days")]}
                label="Отправить через раб. дней"
                rules={[{ required: true }]}
              >
                <InputNumber
                  className={styles.daysInput}
                  precision={0}
                  min={1}
                />
              </Form.Item>
              <Form.Item
                name={[field.name, fKey("time")]}
                label="Время отправки"
                rules={[{ required: true }]}
              >
                <NotifyTimePicker />
              </Form.Item>
              <Form.Item
                name={[field.name, fKey("receiverAttrIds")]}
                label="Адресат (получатель)"
                rules={[{ required: true }]}
              >
                <Select
                  options={userListAttrs.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  showSearch
                  optionFilterProp="name"
                  allowClear
                  placeholder="Выбрать"
                  mode="multiple"
                />
              </Form.Item>
              <Form.Item name={[field.name, fKey("roleIds")]} label="Роль">
                <RoleSelect roleGroups={roleGroups} />
              </Form.Item>
              <Form.Item
                name={[field.name, fKey("messageType")]}
                label="Тип уведомления"
                rules={[{ required: true }]}
              >
                <Radio.Group
                  options={typeOptions()}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              {appStore.contentLanguages.map((lang) => (
                <Form.Item
                  key={lang.code}
                  name={[field.name, fKey("templateText"), lang.code]}
                  label={`Текст уведомления ${getLangName(lang)}`}
                  rules={[trimRequired()]}
                >
                  <Mentions
                    allowClear
                    rows={3}
                    prefix="%"
                    options={placeholders}
                  />
                </Form.Item>
              ))}
            </React.Fragment>
          ))}
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => add(defaulDelayedNotify)}
          >
            {t("Add")}
          </Button>
        </div>
      )}
    </Form.List>
  );
};

const defaulDelayedNotify: EdDelayedNotify = {
  active: true,
  roleIds: [],
  receiverAttrIds: [],
  messageType: undefined as unknown as number,
  templateText: {},
  days: undefined as unknown as number,
  time: undefined as unknown as string,
};
