import * as React from "react";
import { ZChildEntities } from "src/common/attrEdit/components";
import { ChildEntities } from "../../ChildEntities";
import { CopyFromV2Store } from "./CopyFromV2Store";

interface PropsCopyFromV2Step2 {
  store: CopyFromV2Store;
  editorInfo: ZChildEntities | undefined;
}

export const CopyFromV2Step2: React.FC<PropsCopyFromV2Step2> = ({
  store,
  editorInfo,
}) => {
  const { srcEntity, parentObject, store2 } = store;
  if (!srcEntity || !editorInfo) return null;
  return (
    <ChildEntities
      parentObjId={parentObject.id}
      editorInfo={{ ...editorInfo, summaryAttrs: [] }}
      entityId={srcEntity.id}
      disableResize
      outerStore={store2}
    />
  );
};
