import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { DropdownChildButton } from "src/components/DropdownChildButton";
import { ImportOutlined } from "@ant-design/icons";
import { MultiStepTask } from "src/components/MultiStepTask";
import { onError } from "src/common/onError";
import { MultiStepItem } from "src/components/MultiStepTask/MultiStepItem";
import {
  lastStep,
  nextStep,
  prevStep,
} from "src/components/MultiStepTask/utils";
import { BomSummaryCopyFromStep1 } from "./BomSummaryCopyFromStep1";
import { BomSummaryCopyFromStep2 } from "./BomSummaryCopyFromStep2";
import { BomSummaryCopyFromStore } from "./BomSummaryCopyFromStore";

/*
 * модели загружаются через сервис ms-object,
 * затем выбранный экземпляр используется для загрузки списка bom.
 * А потом из него выбираются те, которые войдут в запрос /copy-boms-to-entity
 */

interface PropsBomSummaryCopyFrom {
  modelObjectId: number;
  objectServiceId: number;
  targetModelEntityId: number;
  onFinish: () => void;
}

export const BomSummaryCopyFrom: React.FC<PropsBomSummaryCopyFrom> = observer(
  (props) => {
    const { modelObjectId, objectServiceId, targetModelEntityId, onFinish } =
      props;
    const store = React.useMemo(
      () =>
        new BomSummaryCopyFromStore(
          modelObjectId,
          objectServiceId,
          targetModelEntityId,
        ),
      [modelObjectId, objectServiceId, targetModelEntityId],
    );
    const start = () => {
      store.init().catch(onError);
    };

    const steps: MultiStepItem[] = [
      {
        title: "Модель",
        description: "Выберите экземпляр-источник для копирования",
        next: nextStep(store.firstDisabled),
        drawContent: () => <BomSummaryCopyFromStep1 store={store} />,
      },
      {
        title: "BOM",
        description: "Выберите экземпляр для копирования",
        prev: prevStep(),
        next: nextStep(store.secondDisabled),
        drawContent: () => <BomSummaryCopyFromStep2 store={store} />,
      },
      lastStep(t("Copying"), store.copyInfo, () => store.copy(onFinish)),
    ];

    return (
      <>
        <DropdownChildButton icon={<ImportOutlined />} onClick={start}>
          {t("Copy from")}
        </DropdownChildButton>
        <MultiStepTask
          title={t("Копировать ИЗ")}
          taskStore={store.taskStore}
          steps={steps}
        />
      </>
    );
  },
);
