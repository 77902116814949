// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__box--rf5Tn {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__toolbar--xkyGF {
  display: flex;
  gap: 8px;
  padding: 0 24px 16px;
  border-top: 1px solid #f0f0f0;
}
.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__leftTool--yZlAs {
  flex: 1;
}
.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__table--NFgIC {
  flex: 1;
  overflow: hidden;
  padding: 0 24px;
}
.src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__tableEmpty--XbcPU {
  padding-top: 42px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsSample/SampleControl/SampleSummary/SampleSummary.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACA;EACI,aAAA;EACA,QAAA;EACA,oBAAA;EACA,6BAAA;AACJ;AACA;EACI,OAAA;AACJ;AAEA;EACI,OAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AAGA;EACI,iBAAA;AADJ","sourcesContent":[".box {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n.toolbar {\n    display: flex;\n    gap: 8px;\n    padding: 0 24px 16px;\n    border-top: 1px solid #f0f0f0;;\n}\n.leftTool {\n    flex: 1;\n}\n\n.table {\n    flex: 1;\n    overflow: hidden;\n    padding: 0 24px;\n}\n\n.tableEmpty {\n    padding-top: 42px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__box--rf5Tn`,
	"toolbar": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__toolbar--xkyGF`,
	"leftTool": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__leftTool--yZlAs`,
	"table": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__table--NFgIC`,
	"tableEmpty": `src-businessServices-services-bsSample-SampleControl-SampleSummary-SampleSummary-module__tableEmpty--XbcPU`
};
export default ___CSS_LOADER_EXPORT___;
