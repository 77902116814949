// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryBox--aFi2s {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryToolbar--hCqn2 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__firstToolItem--remEJ {
  flex: 1;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryContent--VBP1Q {
  flex: 1;
  padding: 16px 24px;
}
.src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryEmpty--PENFM {
  padding-top: 42px;
}
`, "",{"version":3,"sources":["webpack://./src/businessServices/services/bsBom2/Bom2Control/Bom2Summary/Bom2Summary.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,gCAAA;AAAJ;AAEA;EACI,OAAA;AAAJ;AAGA;EACI,OAAA;EACA,kBAAA;AADJ;AAIA;EACI,iBAAA;AAFJ","sourcesContent":[".summaryBox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    overflow: auto;\n}\n\n.summaryToolbar {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    padding: 16px 24px;\n    border-bottom: 1px solid #f0f0f0;;\n}\n.firstToolItem {\n    flex: 1;\n}\n\n.summaryContent {\n    flex: 1;\n    padding: 16px 24px;\n}\n\n.summaryEmpty {\n    padding-top: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryBox": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryBox--aFi2s`,
	"summaryToolbar": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryToolbar--hCqn2`,
	"firstToolItem": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__firstToolItem--remEJ`,
	"summaryContent": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryContent--VBP1Q`,
	"summaryEmpty": `src-businessServices-services-bsBom2-Bom2Control-Bom2Summary-Bom2Summary-module__summaryEmpty--PENFM`
};
export default ___CSS_LOADER_EXPORT___;
