import React, { useState } from "react";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { PlusCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { onError } from "src/common/onError";
import { getCurrentUserInfo } from "src/appStore";
import { ChatListStore } from "../ChatListStore";
import { ChatSettingsModal } from "../ChatForm/chatSettings/ChatSettingsModal";
import { RCNewChatFormData } from "../bsChat.types";

type PropsNewChatButton = {
  chatListStore: ChatListStore;
};

export const NewChatButton: React.FC<PropsNewChatButton> = observer(
  ({ chatListStore }) => {
    const {
      props: { personComplexStore },
    } = chatListStore;
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const openModal = () => setOpen(true);
    const handleCreateNewChat = (data: RCNewChatFormData) =>
      chatListStore
        .createChat(data.name, data.userIds)
        .then((newChat) => {
          if (newChat) chatListStore.joinChat(newChat);
          return newChat;
        })
        .catch(onError);

    const currUserId = getCurrentUserInfo().id;
    return (
      <>
        <Button onClick={openModal}>
          <PlusCircleOutlined />
          {t("Create a chat")}
        </Button>
        {open && (
          <ChatSettingsModal
            initial={{
              userIds: currUserId ? [currUserId] : undefined,
            }}
            modalTitle="Создать новый чат"
            okBtnText={t("Create")}
            submit={handleCreateNewChat}
            onClose={closeModal}
            complexStore={personComplexStore}
          />
        )}
      </>
    );
  },
);
