import { AttrTypeName } from "src/types/AttrType";
import { TreeLikeData } from "src/types/TreeLikeData";
import { ZAttribute } from "src/types/ZAttribute";

type AttrLabelMetaObjectRef = {
  type: AttrTypeName.object;
};
type AttrLabelMetaUsersList = {
  type: AttrTypeName.usersList;
  userId: string;
};
type AttrLabelMeta = AttrLabelMetaObjectRef | AttrLabelMetaUsersList;

type AttrLabelData = {
  labels: string[];
  attr: ZAttribute;
  attrLabelMeta?: AttrLabelMeta;
  recursion?: boolean;
};

export type AttrLabelTreeData = TreeLikeData<AttrLabelData>;
/**
 * с ключами непростая ситуация, мы не можем брать за ключ никакие данные атрибута,
 * потому что атрибуты в дереве могут повторяться из-за циклических зависимостей или, например
 * при отображении лейблов атрибути с типом childEntities
 */
const keyReg = new Map<number, number>();
const getKey = (attrId: number) => {
  const makeKey = (key: number) => `${attrId}_${key}`;
  if (keyReg.has(attrId)) {
    const count = Number(keyReg.get(attrId)) + 1;
    keyReg.set(attrId, count);
    return makeKey(count);
  }
  keyReg.set(attrId, 1);
  return makeKey(1);
};

export const defAttrLabelTreeData = (
  labels: string[],
  attr: ZAttribute,
  children?: AttrLabelTreeData[],
  recursion?: boolean,
  attrLabelMeta?: AttrLabelMeta,
): AttrLabelTreeData => ({
  labels,
  key: getKey(attr.id),
  attr,
  children,
  recursion,
  attrLabelMeta,
});
export const defUsersListAttrLabelTreeData = (
  labels: string[],
  attr: ZAttribute,
  userId: string,
  children?: AttrLabelTreeData[],
  recursion?: boolean,
): AttrLabelTreeData =>
  defAttrLabelTreeData(labels, attr, children, recursion, {
    type: AttrTypeName.usersList,
    userId,
  });
