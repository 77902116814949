import React from "react";
import { AttrTypeMap } from "src/common/attributes";
import { ZAttrViewInfo } from "src/common/attrView";
import { createItemView2 } from "src/common/attrView/createItemView/createItemView2";
import { getSeparator } from "src/common/attrView/viewFormItems/Separator";
import { classNames } from "src/common/classNames";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import styles from "./GroupCell.module.less";

type PropsGroupCell = {
  attrsList: ZAttribute[];
  attrTypeMap: AttrTypeMap;
  viewInfoMap: Record<string, ZAttrViewInfo | undefined>;
  row: ZEntity;
};

export const GroupCell2: React.FC<PropsGroupCell> = ({
  attrsList,
  attrTypeMap,
  viewInfoMap,
  row,
}) => {
  const valsMap = new Map(
    row.attributeValues.map(({ attributeId, values }) => [attributeId, values]),
  );
  const labels = attrsList
    .map((attribute) => {
      const vals = valsMap.get(attribute.id);
      if (!vals || vals.length === 0) return null;
      const viewInfo = viewInfoMap[attribute.id || ""];
      const curSeparator = viewInfo?.appearance?.column?.group?.separator;
      const separator = getSeparator(curSeparator);
      return [
        createItemView2(attribute, attrTypeMap, row, viewInfo),
        <div
          className={classNames([
            [curSeparator === "newLine", styles.withNewLineSep],
          ])}
        >
          {separator}
        </div>,
      ];
    })
    .filter((e) => e?.[0]);

  // полследним всегда будет разделитель, он не нужен
  const labelsWithSeparator = labels.flat();
  labelsWithSeparator.pop();
  return <div className={styles.box}>{labelsWithSeparator}</div>;
};
