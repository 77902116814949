import * as React from "react";
import { observer } from "mobx-react-lite";
import { AColumn, AsyncTable } from "src/components/tables/AsyncTable";
import { ZBom2SummaryRow } from "src/businessServices/services/bsBom2/Bom2Types";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { bomSummaryColumns } from "../../../Bom2Summary";
import { BomPosCopyFromStore } from "./BomPosCopyFromStore";

interface PropsBomPosCopyFromStep2 {
  store: BomPosCopyFromStore;
}

export const BomPosCopyFromStep2: React.FC<PropsBomPosCopyFromStep2> = observer(
  ({ store }) => {
    const columns: AColumn<ZBom2SummaryRow>[] = React.useMemo(
      bomSummaryColumns,
      [],
    );
    const { bomsStore } = store;
    if (!bomsStore) return null;
    return (
      <AsyncTable
        store={bomsStore}
        columns={columns}
        onRowClick={(row) => selectOnRowClick(row, bomsStore)}
      />
    );
  },
);
