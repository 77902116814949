import dayjs, { Dayjs } from "dayjs";
import { ifDef } from "src/common/ifDef";

export const notifyTimeFormat = "HH:mm";

export const notifyTime2Ed = (date?: string | null): Dayjs | null => {
  if (!date) return null;
  const utcTime = dayjs.utc(date, notifyTimeFormat);
  if (!utcTime.isValid()) return null;
  return utcTime.local();
};

export const ed2NotifyTime = (date?: Dayjs | null): string | null =>
  ifDef(date, (it) => it.utc().format(notifyTimeFormat)) ?? null;
