// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-MultiStepTask-utils-MultiStepEntsTable-MultiStepEntsTable-module__table--VrLOX {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/MultiStepTask/utils/MultiStepEntsTable/MultiStepEntsTable.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AACF","sourcesContent":[".table {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `src-components-MultiStepTask-utils-MultiStepEntsTable-MultiStepEntsTable-module__table--VrLOX`
};
export default ___CSS_LOADER_EXPORT___;
