import React from "react";
import { Button, Dropdown, MenuProps } from "antd";
import { SettingsIconStd } from "src/common/icons";
import { DeleteChatButton } from "./DeleteChatButton";
import { ChatListStore } from "../../ChatListStore";
import { EditChatButton } from "./EditChatButton";

type PropsChatSettingsMenu = {
  chatListStore: ChatListStore;
};

export const ChatSettingsMenu: React.FC<PropsChatSettingsMenu> = ({
  chatListStore,
}) => {
  const items: MenuProps["items"] = [
    {
      key: "edit",
      label: <EditChatButton chatListStore={chatListStore} />,
    },
    {
      key: "delete",
      label: <DeleteChatButton chatListStore={chatListStore} />,
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <Button>
        <SettingsIconStd />
      </Button>
    </Dropdown>
  );
};
