import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { DropdownChildButton } from "src/components/DropdownChildButton";
import { MultiStepTask } from "src/components/MultiStepTask";
import { onError } from "src/common/onError";
import { MultiStepItem } from "src/components/MultiStepTask/MultiStepItem";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import {
  nextStep,
  prevStep,
  lastStep,
} from "src/components/MultiStepTask/utils";
import { ImportOutlined } from "@ant-design/icons";
import { MultiStepEntsTable } from "src/components/MultiStepTask/utils/MultiStepEntsTable";
import { BomPosCopyToStore } from "./BomPosCopyToStore";
import { BomPosCopyToStep2 } from "./BomPosCopyToStep2";

interface PropsBomPosCopyTo {
  modelObjectId: number;
  objectServiceId: number;
  targetBomId: number;
  selectedPosIds: number[];
  onFinish: () => void;
}

export const BomPosCopyTo: React.FC<PropsBomPosCopyTo> = observer((props) => {
  const {
    modelObjectId,
    objectServiceId,
    targetBomId,
    selectedPosIds,
    onFinish,
  } = props;
  const store = React.useMemo(
    () =>
      new BomPosCopyToStore(
        modelObjectId,
        objectServiceId,
        targetBomId,
        selectedPosIds,
        onFinish,
      ),
    [modelObjectId, objectServiceId, targetBomId, selectedPosIds],
  );
  const start = () => {
    store.init().catch(onError);
  };

  const steps: MultiStepItem[] = [
    {
      title: t("Model"),
      description: t(
        "Select an instance to copy previously selected instances",
      ),
      next: nextStep(store.firstDisabled),
      drawContent: () => (
        <MultiStepEntsTable>
          <EntityFiltersTable store={store.store1} />,
        </MultiStepEntsTable>
      ),
    },
    {
      title: "BOM",
      description: t(
        "Select the instances where to move the copied instances to",
      ),
      prev: prevStep(),
      next: nextStep(store.secondDisabled),
      drawContent: () => <BomPosCopyToStep2 store={store} />,
    },
    lastStep(t("Copying"), store.copyInfo, () => store.copy()),
  ];

  return (
    <>
      <DropdownChildButton
        icon={<ImportOutlined />}
        onClick={start}
        disabled={!store.selectedPosIds.length}
      >
        {t("Copy to")}
      </DropdownChildButton>
      <MultiStepTask
        title={t("Copy to")}
        taskStore={store.taskStore}
        steps={steps}
      />
    </>
  );
});
