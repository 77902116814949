import { Form, Space } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { composeEntityLabel } from "src/common/entity/composeEntityLabel/composeEntityLabel";
import { onError } from "src/common/onError";
import { searchEntitiesAll } from "src/components/FinancialInput/searchEntitiesAll";
import { SelectFromReference } from "src/components/SelectFromReference";
import { loadObjectAttrinbutesAll } from "src/pages/ManagementPage/objectsApi";
import { LinkedValuePath } from "../../../AttrCommonFields/LinkedValuePath";
import { SelectObject } from "../../../SelectObject";
import { FormulawithFinancialMetaStore } from "./FormulaWithFinancialMetaStore";

const loadEntitiesAsOptions = async (objId: number, labelIds: string[]) => {
  try {
    const resp = await searchEntitiesAll(objId);
    const options = await Promise.all(
      resp.map(async (e) => ({
        label: await composeEntityLabel(e, labelIds),
        value: e.id,
      })),
    );
    return options;
  } catch (error) {
    onError(error);
    return [];
  }
};

const loadObjectAttsAsOptions = (currencyObject?: string) => async () =>
  currencyObject
    ? loadObjectAttrinbutesAll(currencyObject).then((data) =>
        data.map((attr) => ({
          label: attr.name,
          value: String(attr.id),
        })),
      )
    : [];

interface PropsFormulaWithFinancialMeta {
  store: FormulawithFinancialMetaStore;
}

export const FormulaWithFinancialMeta: React.FC<PropsFormulaWithFinancialMeta> =
  observer(({ store }) => {
    const form = Form.useFormInstance();
    const { objectOptionsLoader, name, objectId, curAttrId, attrTypesDict } =
      store.props;
    const makeFormName = (fieldName: string) => [...name, fieldName];
    const formNames = {
      currencyObject: makeFormName("currencyObject"),
      labelAttr: makeFormName("labelAttr"),
      resultCurrency: makeFormName("resultCurrency"),
      courseSource: makeFormName("courseSource"),
    };

    const currencyObject = Form.useWatch(formNames.currencyObject);
    const labelAttr = Form.useWatch(formNames.labelAttr);

    useEffect(() => {
      if (form.isFieldTouched(formNames.currencyObject)) {
        form.setFieldValue(formNames.labelAttr, undefined);
        form.setFieldValue(formNames.resultCurrency, undefined);
      }
    }, [currencyObject]);
    return (
      <Space direction="vertical">
        <Form.Item
          name={formNames.currencyObject}
          label="Объект целевой валюты по умолчанию (ответ формулы)"
          rules={[{ required: true }]}
        >
          <SelectObject loadObjectOptions={objectOptionsLoader} />
        </Form.Item>
        <Form.Item
          label="Атрибут для отображения валюты"
          name={formNames.labelAttr}
          rules={[{ required: true }]}
        >
          <SelectFromReference
            loader={loadObjectAttsAsOptions(currencyObject)}
          />
        </Form.Item>
        <Form.Item
          label="Экземпляр целевой валюты по умолчанию (ответ формулы)"
          name={formNames.resultCurrency}
          rules={[{ required: true }]}
        >
          <SelectFromReference
            loader={() =>
              currencyObject
                ? loadEntitiesAsOptions(currencyObject, [labelAttr])
                : Promise.resolve([])
            }
          />
        </Form.Item>
        <Form.Item
          label="Источник смены курса валют"
          name={formNames.courseSource}
          rules={[{ required: true }]}
        >
          <LinkedValuePath
            currAttrId={curAttrId}
            objectId={objectId}
            attrTypesDict={attrTypesDict}
          />
        </Form.Item>
      </Space>
    );
  });
