import { optionalLangParams } from "src/lang/langHdr";
import { ZObjectItem, zObjectItem } from "src/types/ZObjectItem";
import { ZAttribute } from "src/types/ZAttribute";
import { ZGroup } from "src/types/ZGroup";
import { apiObjUrl } from "../apiUrl";
import { rest } from "../rest";

export const loadObject = async (
  objectId: number | string,
  options: {
    stateId?: number | null;
    translate?: boolean;
  } = {},
) => {
  const { stateId, translate } = options;
  let url = apiObjUrl(`/objects/${objectId}`);
  if (stateId) url += `/${stateId}`;
  const resp = await rest.get(url, optionalLangParams(translate));
  return zObjectItem.parse(resp.data);
};

export const getAttributesFromObject = (
  object: ZObjectItem,
): Record<number, ZAttribute> => {
  const attrMap: Record<number, ZAttribute> = {};
  const addAtributes = (attrs?: ZAttribute[] | null) =>
    attrs?.forEach((a) => {
      attrMap[a.id] = a;
    });
  addAtributes(object.attributes);
  const onGroup = (group: ZGroup) => {
    addAtributes(group.attributes);
    group.groups?.forEach(onGroup);
  };
  object.groups?.forEach(onGroup);
  return attrMap;
};
