import { Tag } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  seedSeparators,
  zSeparator,
} from "src/common/attrView/viewFormItems/Separator";

interface PropsMemberList {
  options: DefaultOptionType[];
}

export const MemberList: React.FC<PropsMemberList> = observer(({ options }) => {
  const membersCount = options.length;
  const labels = seedSeparators(
    options.map(({ label, value }) => ({
      content: <Tag style={{ marginRight: 0 }}>{label}</Tag>,
      key: String(value),
    })),
    zSeparator.Values.comma,
  );
  return (
    <span>
      {t("Members")} ({membersCount})<span>: {labels}</span>
    </span>
  );
});
