import { rest, stdParamsSerializer } from "src/common/rest";
import { apiObjUrl } from "src/common/apiUrl";
import { ZPermissions } from "../roleTypes";

export const saveCopyOnAtts = async (
  stateId: number,
  attributeIds: number[],
  permissions: ZPermissions,
) =>
  rest.put(apiObjUrl("/permissions/copy-for-state"), permissions, {
    params: { stateId, attributeIds },
    paramsSerializer: stdParamsSerializer,
  });
