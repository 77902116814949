import * as React from "react";
import { Button, Dropdown, MenuProps } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { BomPosCopyTo } from "./BomPosCopyTo";
import { BomPosCopyFrom } from "./BomPosCopyFrom";

interface PropsBomPosActions {
  modelObjectId: number | undefined;
  objectServiceId: number;
  currentBomId: number;
  selectedPositions: number[];
  reloadPositions: () => void;
}

export const BomPosActions: React.FC<PropsBomPosActions> = observer((props) => {
  const {
    modelObjectId,
    objectServiceId,
    currentBomId,
    selectedPositions,
    reloadPositions,
  } = props;
  const menuProps: MenuProps = {
    items: [
      {
        key: "copyTo",
        label: modelObjectId ? (
          <BomPosCopyTo
            modelObjectId={modelObjectId}
            objectServiceId={objectServiceId}
            targetBomId={currentBomId}
            selectedPosIds={selectedPositions}
            onFinish={reloadPositions}
          />
        ) : (
          "..."
        ),
      },
      {
        key: "copyFrom",
        label: modelObjectId ? (
          <BomPosCopyFrom
            modelObjectId={modelObjectId}
            objectServiceId={objectServiceId}
            targetBomId={currentBomId}
            onFinish={reloadPositions}
          />
        ) : (
          "..."
        ),
      },
    ],
  };
  return (
    <Dropdown menu={menuProps} placement="bottomRight">
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  );
});
