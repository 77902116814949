import { createStoreFinancialInput } from "src/components/FinancialInput";
import { ZAttribute } from "src/types/ZAttribute";
import { z } from "zod";

export const zAttrFinancialInput = z.object({
  editor: z.literal("FinancialInput"),
  labelAttr: z.string(),
});
export type ZAttrFinancialInput = z.infer<typeof zAttrFinancialInput>;
export const makeFinancialInputProps = (attribute: ZAttribute) => ({
  store: createStoreFinancialInput(attribute),
});
