import React from "react";
import { getEditorInfo } from "src/common/attrEdit";
import {
  ZAttrViewInfo,
  viewsByType,
  zAttrComponentView,
} from "src/common/attrView";
import {
  makeChildEntitiesCellInfoProps,
  makeDictRefProps2,
  makeFileListProps,
  makeFinancialViewProps,
  makeImgListProps,
  makeLinkStdProps,
  makeObectRefProps,
  makePersonCellInfoProps,
  makeSimpleTextProps,
  makeTagProps,
} from "src/common/attrView/componentsDefs";
import { AttrLabelBuilderCtx } from "src/common/attributes/composeAttrLabel2";
import { onError } from "src/common/onError";
import { AttrLinked } from "src/components/AttrLinked/AttrLinked";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import {
  ContentLangInfo,
  DictRef2,
  FileList,
  ImgList,
  LinkStd,
  LinkView,
  ObjectRef,
  PersonCellInfo,
  SimpleText,
  Tag,
} from "../components";
import { ChildEntitiesCellInfo } from "../components/ChildEntitiesCellInfo/ChildEntitiesCellInfo";
import { FinancialView } from "../components/FinancialView/FinancialView";
import { seedSeparators } from "../viewFormItems/Separator";
import { composeLabelsWithEditConfig } from "./composeLabelWithConf";
import { defineAttrView } from "./defineView";
import { makeFormulaWithFinancialViewProps } from "../componentsDefs/ZAttrFormulaWithFinancialView";

export const createItemView2 = (
  attr: ZAttribute,
  typesMap: Record<number, string>,
  entity: ZEntity,
  viewInfo: ZAttrViewInfo | undefined,
  ctx?: AttrLabelBuilderCtx,
): React.ReactNode => {
  try {
    let component = viewInfo?.component;
    const eFieldsMap = new Map(
      entity.attributeValues.map((v) => [v.attributeId, v]),
    );
    const eField = eFieldsMap.get(attr.id);
    const values = eField?.values || [];
    const configuredVals =
      composeLabelsWithEditConfig(attr, values, typesMap) || [];
    const editorInfo = getEditorInfo(attr.viewStyles);
    if (!component) {
      const typeName = typesMap[attr.valueType];
      const list = viewsByType[typeName as AttrTypeName];
      const view = list?.[0];
      if (view) {
        try {
          component = zAttrComponentView.parse({ view });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e); // для отладки
        }
      }
    }
    if (!component) return undefined;
    switch (component.view) {
      case "SimpleText": {
        const isTextArea = editorInfo?.component?.editor === "TextArea";
        const newValues = seedSeparators(
          (isTextArea && configuredVals[0]
            ? configuredVals[0].split("\n")
            : configuredVals
          ).map((v, i) => ({ content: v, key: `${i}:${v}` })),
          isTextArea ? "newLine" : viewInfo?.appearance?.view?.separator,
        );
        return defineAttrView({
          Component: SimpleText,
          cmpProps: makeSimpleTextProps(newValues, viewInfo),
        });
      }
      case "ObectRef":
        return defineAttrView({
          Component: ObjectRef,
          cmpProps: makeObectRefProps(
            attr,
            component,
            configuredVals,
            viewInfo,
          ),
        });
      case "ContentLangInfo":
        return defineAttrView({
          Component: ContentLangInfo,
          cmpProps: { values: configuredVals },
        });
      case "DictRef":
        return defineAttrView({
          Component: DictRef2,
          cmpProps: makeDictRefProps2(configuredVals, attr),
        });
      case "ImgList":
        return defineAttrView({
          Component: ImgList,
          cmpProps: makeImgListProps(configuredVals),
        });
      case "LinkStd":
        return defineAttrView({
          Component: LinkStd,
          cmpProps: makeLinkStdProps(configuredVals, viewInfo, attr),
        });
      case "LinkView":
        return defineAttrView({
          Component: LinkView,
          cmpProps: { values: configuredVals, viewInfo },
        });
      case "Tag":
        return defineAttrView({
          Component: Tag,
          cmpProps: makeTagProps(component, configuredVals, viewInfo),
        });
      case "PersonCellInfo":
        return defineAttrView({
          Component: PersonCellInfo,
          cmpProps: makePersonCellInfoProps(configuredVals, attr),
        });
      case "FileList":
        return defineAttrView({
          Component: FileList,
          cmpProps: makeFileListProps(configuredVals, viewInfo),
        });
      case "AttrLinked":
        return defineAttrView({
          Component: AttrLinked,
          cmpProps: { attr, entityId: ctx?.entityId },
        });
      case "ChildEntitiesCellInfo": {
        return defineAttrView({
          Component: ChildEntitiesCellInfo,
          cmpProps: makeChildEntitiesCellInfoProps(
            attr,
            viewInfo,
            editorInfo,
            ctx,
          ),
        });
      }
      case "AttrFormulaCell": {
        // в формуле приходят сразу просчитаные значения, поэтому отображаем их с настройками
        return configuredVals;
      }
      case "FinancialView": {
        if (!eField) return undefined;
        return defineAttrView({
          Component: FinancialView,
          cmpProps: makeFinancialViewProps(eField, attr, typesMap),
        });
      }
      case "FormulaWithFinancialView": {
        if (!eField) return undefined;
        return defineAttrView({
          Component: FinancialView,
          cmpProps: makeFormulaWithFinancialViewProps(eField, attr, typesMap),
        });
      }
      default:
        return values;
    }
  } catch (error) {
    onError(error);
    return undefined;
  }
};
