import { z } from "zod";
import {
  zObectRef,
  zSimpleText,
  zDictRef,
  zImgList,
  zLinkStd,
  zPersonCellInfo,
  zAttrFinancialView,
  zAttrFormulaCell,
  zAttrLinked,
  zChildEntitiesCellInfo,
  zContentLangInfo,
  zFileList,
  zLinkView,
  zTag,
  zAttrArticleView,
} from "./componentsDefs";

import { zAttrViewAppearance } from "./ZAttrViewAppearance";
import { zAttrViewStyles } from "./ZAttrViewStyles";
import { zAttrFormulaWithFinancialView } from "./componentsDefs/ZAttrFormulaWithFinancialView";

export const zAttrComponentView = z.discriminatedUnion("view", [
  zDictRef,
  zObectRef,
  zPersonCellInfo,
  zSimpleText,
  zImgList,
  zLinkStd,
  zLinkView,
  zTag,
  zFileList,
  zContentLangInfo,
  zAttrLinked,
  zChildEntitiesCellInfo,
  zAttrFormulaCell,
  zAttrArticleView,
  zAttrFinancialView,
  zAttrFormulaWithFinancialView,
]);
export type ZAttrComponentView = z.infer<typeof zAttrComponentView>;
export type AttrViewCompName = ZAttrComponentView["view"];

export const zAttrViewInfo = z.object({
  ver: z.literal(1),
  appearance: zAttrViewAppearance.optional().nullable(),
  styles: zAttrViewStyles.optional().nullable(),
  component: zAttrComponentView.optional().nullable(),
});
export type ZAttrViewInfo = z.infer<typeof zAttrViewInfo>;
