import { makeAutoObservable } from "mobx";
import { ArgsProps } from "antd/lib/notification";

export type Msg = ArgsProps;

export const msgQueueStore = makeAutoObservable({
  list: [] as Msg[],
  add(msg: Msg) {
    this.list.push(msg);
  },
  clear() {
    this.list.length = 0;
  },
});
