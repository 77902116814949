// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Используется стиль с этого макета 
  https://www.figma.com/design/MlEfzlXtWE0vZIdf08TqNA/OMNI?node-id=10898-21267&m=dev 
  Если вдруг появятся ссылки с другим дизайном, надо будет вводить дополнительные классы
*/
.src-components-LinkExt-LinkExt-module__common--kB4B4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.src-components-LinkExt-LinkExt-module__linkExt--DyZMy {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #E43D00;
  cursor: pointer;
}
.src-components-LinkExt-LinkExt-module__badLink--hNX6L {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: gray;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkExt/LinkExt.module.less"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;AAEA;EANE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAKA,cAAA;EACA,eAAA;AAGF;AAAA;EAZE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAWA,WAAA;AAKF","sourcesContent":["/* Используется стиль с этого макета \n  https://www.figma.com/design/MlEfzlXtWE0vZIdf08TqNA/OMNI?node-id=10898-21267&m=dev \n  Если вдруг появятся ссылки с другим дизайном, надо будет вводить дополнительные классы\n*/\n.common {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}\n\n.linkExt {\n  .common;\n  color: #E43D00;\n  cursor: pointer;\n}\n\n.badLink {\n  .common;\n  color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `src-components-LinkExt-LinkExt-module__common--kB4B4`,
	"linkExt": `src-components-LinkExt-LinkExt-module__linkExt--DyZMy`,
	"badLink": `src-components-LinkExt-LinkExt-module__badLink--hNX6L`
};
export default ___CSS_LOADER_EXPORT___;
