import { apiObjUrl } from "src/common/apiUrl";
import {
  zFormulaArgsInfo,
  ZFormulaArgsInfo,
} from "src/common/attrEdit/components/formula/common";
import { rest } from "src/common/rest";
import { AttrTypeName } from "src/types/AttrType";

export const loadFormulaArguments = async (
  objectId: number,
): Promise<ZFormulaArgsInfo> => {
  const res = await rest.get(
    apiObjUrl("/objects/available-attributes-types-by-type"),
    { params: { valueType: AttrTypeName.formula, objectId } },
  );
  return zFormulaArgsInfo.parse(res.data);
};
