import { DefaultOptionType } from "antd/es/select";
import { loadPersonsOptions } from "src/components/PersonSelect/loadPersonsOptions";
import { ZAttribute } from "src/types/ZAttribute";
import { EntityCardData } from "src/pages/EntityCardPage/EntityCardStore";
import { chatGetRequiredUsersIds } from "./NewChatButton/getRequiredUserIds";

const rolesRequestCache: Record<string, Promise<DefaultOptionType[]>> = {};
const makeKey = (attr: ZAttribute, ids: string[]) =>
  `${attr.id}-${ids.join("-")}`;

const getCached = async (attr: ZAttribute, ids: string[]) => {
  const cacheKey = makeKey(attr, ids);
  let request = rolesRequestCache[cacheKey];
  if (request) return request;
  request = loadPersonsOptions({ ...attr, roleIds: undefined }, ids);
  rolesRequestCache[cacheKey] = request;
  return request;
};

interface ParamsGetRolesCachedRequest {
  attr: ZAttribute;
  cardData: EntityCardData;
  isDisabled: (id: string) => boolean;
  selectedIds: string[];
}

export const getRolesCachedRequest = async (
  params: ParamsGetRolesCachedRequest,
): Promise<DefaultOptionType[]> => {
  const { attr, cardData, isDisabled, selectedIds } = params;
  const ids = [...selectedIds, ...chatGetRequiredUsersIds(cardData)];
  const requestAttr = { ...attr, roleIds: undefined };
  return getCached(requestAttr, ids).then((data) =>
    data
      .map((opt) => ({
        ...opt,
        disabled: isDisabled(String(opt.value)),
      }))
      .sort((a, b) => Number(b.disabled) - Number(a.disabled)),
  );
};
