import * as React from "react";
import { RemoteData } from "src/common/RemoteData";
import { LoaderBox } from "src/components/LoaderBox";
import { Alert, Flex, Spin } from "antd";
import { onError } from "src/common/onError";
import { t } from "i18next";
import { prevStep } from "./prevStep";
import { MultiStepItem } from "../MultiStepItem";

export const lastStep = (
  title: string,
  task: RemoteData<unknown>,
  start: () => Promise<void>,
): MultiStepItem => ({
  title,
  prev: task.status === "error" ? prevStep() : undefined,
  onActive: () => start().catch(onError),
  drawContent: () => (
    <LoaderBox
      remoteData={task}
      drawWait={() => (
        <Flex gap={20} justify="center" align="center">
          <Spin size="large" />
          <span style={{ fontSize: "1.5rem" }}>{title}...</span>
        </Flex>
      )}
      drawReady={() => (
        <Alert type="success" showIcon message={t("Copying was successful")} />
      )}
    />
  ),
});
