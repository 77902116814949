import React from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { t } from "i18next";
import { confirmDelete } from "src/components/ModalDelete";
import { ChatListStore } from "../../ChatListStore";

type PropsDeleteChatButton = {
  chatListStore: ChatListStore;
};

export const DeleteChatButton: React.FC<PropsDeleteChatButton> = ({
  chatListStore,
}) => {
  const chatFormStore = chatListStore.chats.find(
    ({ listenerId: chatId }) => chatId === chatListStore.activeChatId,
  );
  const chatInfo = chatFormStore?.chatInfo;
  if (!chatInfo) return null;

  const handleDelete = () => {
    const modal = confirmDelete(
      t("Do you really want to delete the chat ?"),
      () => {
        modal.update({ okButtonProps: { loading: true } });
        chatListStore
          .deleteChat(chatInfo.id)
          .then(() => modal.destroy())
          .finally(() => {
            modal.update({ okButtonProps: { loading: false } });
          })
          .catch(onError);
      },
    );
  };

  return (
    <Button type="text" danger onClick={handleDelete}>
      <DeleteOutlined />
      {t("Delete chat")}
    </Button>
  );
};
