import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Flex, Spin } from "antd";
import { asrtTableSettingsStore } from "./AsrtTableSettingsStore";
import { AsrtTableDictSection } from "./sections/AsrtTableDictSection";
import { AsrtTableSection } from "./sections/AsrtTableSection";

interface PropsAsrtTableSettings {
  typesMap: Record<number, string>;
}

export const AsrtTableSettings: React.FC<PropsAsrtTableSettings> = observer(
  ({ typesMap }) => {
    useEffect(() => {
      asrtTableSettingsStore.init(typesMap);
    }, []);
    const sectionsStore = asrtTableSettingsStore.formSectionsStore;
    const { loading } = sectionsStore;
    return (
      <Spin spinning={loading}>
        <Flex gap={12} vertical>
          <AsrtTableSection store={sectionsStore} />
          <AsrtTableDictSection store={asrtTableSettingsStore.selectDict} />
        </Flex>
      </Spin>
    );
  },
);
