import { apiAsrtTable } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import { zAsrtTableSettings, ZAsrtTableSettings } from "./AsrtTableTypes";

export const loadAsrtTableSettings = async (
  objectId: number,
): Promise<ZAsrtTableSettings> => {
  const resp = await rest.get(apiAsrtTable("/settings"), {
    params: { objectId },
  });
  return zAsrtTableSettings.parse(resp.data);
};

export const saveAsrtTableSettings = async (
  objectId: number,
  objectServiceId: number,
  version: number,
  settings: ZAsrtTableSettings,
) => {
  await rest.post(apiAsrtTable("/settings"), settings, {
    params: { objectId, version, serviceId: objectServiceId },
  });
};
