import { DefaultOptionType } from "antd/es/select";
import { makeAutoObservable } from "mobx";
import { getCurrentUserInfo } from "src/appStore";
import { getEditorInfo } from "src/common/attrEdit";
import { onError } from "src/common/onError";
import { getComponentEditor } from "src/pages/EntityCardPage/blockBuilder/createItem2";
import { EntityCardDataExt } from "src/pages/EntityCardPage/EntityCardStore";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { getRolesCachedRequest } from "../getRolesCachedRequest";

/**
 * Чтобы собрать воедино все атрибуты для показа
 */
const gatherPersonsViewAttrs = (
  userListAtts: ZAttribute[],
  typesMap: Record<number, string>,
) =>
  userListAtts.reduce(
    (acc, curr) => {
      const editorInfo = getEditorInfo(curr.viewStyles);
      const component = getComponentEditor(editorInfo, typesMap, curr);
      if (component?.editor !== "PersonSelect") return acc;
      const viewAtts = component?.rolesViewAtts;
      return { ...viewAtts, ...acc };
    },
    {} as Record<string, string[]>,
  );

const buildUserListAttrWithComplexViewAtts = (
  baseAttr: ZAttribute,
  userListAtts: ZAttribute[],
  typesMap: Record<number, string>,
) => {
  const editorInfo = getEditorInfo(baseAttr?.viewStyles);
  if (!baseAttr || !editorInfo) return null;
  const component = getComponentEditor(editorInfo, typesMap, baseAttr);
  if (component?.editor !== "PersonSelect") return null;
  component.rolesViewAtts = gatherPersonsViewAttrs(userListAtts, typesMap);
  editorInfo.component = component;
  return {
    ...baseAttr,
    viewStyles: JSON.stringify(editorInfo),
  };
};

export interface PersonComplexStore {
  cardData: EntityCardDataExt | null;
  loading: boolean;
  isValid(): boolean;
  getOptions(): DefaultOptionType[];
  init(data: EntityCardDataExt): void;
}

class PersonComplexStoreStd implements PersonComplexStore {
  constructor() {
    makeAutoObservable(this);
  }

  cardData: EntityCardDataExt | null = null;

  setCardData(data: EntityCardDataExt | null) {
    this.cardData = data;
  }

  firstAttr = null as ZAttribute | null;

  setFirstAttr(attr: ZAttribute | null) {
    this.firstAttr = attr;
  }

  uniqSelected = [] as string[];

  setUniqSelected(list: string[]) {
    this.uniqSelected = list;
  }

  userListAttsObj = [] as ZAttribute[];

  setUserListAttsObj(list: ZAttribute[]) {
    this.userListAttsObj = list;
  }

  options: DefaultOptionType[] = [];

  setOptions(list: DefaultOptionType[]) {
    this.options = list;
  }

  getOptions(): DefaultOptionType[] {
    return this.options;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  isValid(): boolean {
    return !!this.firstAttr;
  }

  async init(cardData: EntityCardDataExt) {
    this.setLoading(true);
    this.setCardData(cardData);
    const { entity, attributes } = cardData;
    const userListAttsObj = attributes.filter(
      ({ valueType }) =>
        cardData.typesMap[valueType] === AttrTypeName.usersList,
    );
    this.setUserListAttsObj(userListAttsObj);
    const userAttsIdsSet = new Set(userListAttsObj.map(({ id }) => id));
    const userListAttsEnt = entity?.attributeValues.filter(({ attributeId }) =>
      userAttsIdsSet.has(attributeId),
    );
    this.setUniqSelected(
      Array.from(
        new Map(
          userListAttsEnt
            ?.map((item) => item.values)
            .flat()
            .filter(Boolean)
            .map((v) => [v, v]),
        ).values(),
      ) as string[],
    );

    const firstAttr = userListAttsObj[0];
    if (firstAttr) {
      this.setFirstAttr(
        buildUserListAttrWithComplexViewAtts(
          firstAttr,
          userListAttsObj,
          cardData.typesMap,
        ),
      );
    }

    this.optionsLoader()
      .then((options) => this.setOptions(options))
      .finally(() => this.setLoading(false))
      .catch(onError);
  }

  get typesMap(): Record<number, string> {
    return this.cardData?.typesMap ?? {};
  }

  optionsLoader(): Promise<DefaultOptionType[]> {
    const { firstAttr, cardData, uniqSelected } = this;
    if (!firstAttr || !cardData) return Promise.resolve([]);
    return getRolesCachedRequest({
      attr: firstAttr,
      cardData,
      isDisabled: (id: string) => id === getCurrentUserInfo().id,
      selectedIds: uniqSelected,
    });
  }
}

export const makePersonComplexStore = (): PersonComplexStore =>
  new PersonComplexStoreStd();
