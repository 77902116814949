import { makeAutoObservable } from "mobx";
import {
  copyBomsToEntities,
  loadBom2Summary,
} from "src/businessServices/services/bsBom2/apiBom2";
import { ZBom2SummaryRow } from "src/businessServices/services/bsBom2/Bom2Types";
import { delay } from "src/common/delay";
import { RemoteData } from "src/common/RemoteData";
import { MultiStepTaskStore } from "src/components/MultiStepTask/MultiStepTaskStore";
import { singleLoader } from "src/components/tables/singleLoader";
import { TableStore } from "src/components/tables/TableStore";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { multiStepOnRowClick } from "src/components/MultiStepTask/utils";

export class BomPosCopyToStore {
  constructor(
    public readonly modelObjectId: number,
    public readonly objectServiceId: number,
    public readonly targetBomId: number,
    public readonly selectedPosIds: number[],
    private onFinish: () => void,
  ) {
    this.store1 = new EntityFiltersPageStore({
      actions: new Set([EntFilterActionType.changeFilterVisible]),
      objectId: this.modelObjectId,
      selectionSettings: { selectionType: "radio" },
      onRowClick: (row) => multiStepOnRowClick(row, this.store1),
    });
    makeAutoObservable(this);
  }

  taskStore = new MultiStepTaskStore();

  async init() {
    this.taskStore.open();
  }

  // --- step 1
  store1: EntityFiltersPageStore;

  get srcModelId(): number | undefined {
    return this.store1.tableStore?.selected[0]?.id;
  }

  get firstDisabled() {
    return !this.bomsStore;
  }

  // --- step 2
  get bomsStore() {
    const { srcModelId } = this;
    if (!srcModelId) return null;
    return new TableStore<ZBom2SummaryRow, {}>({
      rowKey: "id",
      fnLoad: singleLoader(
        () => loadBom2Summary(this.objectServiceId, srcModelId),
        () => true,
      ),
      selectionSettings: {
        keepSelected: true,
        selectionType: "checkbox",
      },
    });
  }

  get bomsSelected(): number[] {
    return this.bomsStore?.selected.map(({ id }) => id) ?? [];
  }

  get secondDisabled() {
    return !this.bomsStore?.selected.length;
  }

  // --- copy
  copyInfo: RemoteData<boolean> = { status: "none" };

  setCopyInfo(data: RemoteData<boolean>) {
    this.copyInfo = data;
  }

  async copy() {
    try {
      this.setCopyInfo({ status: "wait" });
      const { selectedPosIds, bomsSelected } = this;
      if (selectedPosIds.length === 0) throw Error("Empty positions list");
      await copyBomsToEntities(selectedPosIds, bomsSelected);
      this.setCopyInfo({ status: "ready", result: true });
      const selectedPositions = new Set(selectedPosIds);
      if (selectedPositions.has(this.targetBomId)) this.onFinish();
      await delay(500);
      this.taskStore.close();
    } catch (error) {
      this.setCopyInfo({ status: "error", error });
    }
  }
}
