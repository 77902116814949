import * as React from "react";
import { Spin } from "antd";
import { observer } from "mobx-react-lite";
import { SheetStore } from "./SheetStore";
import { onError } from "../../common/onError";
import { CellPos } from "./CellPos";
import styles from "./CellBox.module.less";

export type FnFocus = (() => void) | undefined;

export type PropsCellBoxChildren<TValue> = {
  value: TValue | undefined;
  onChange: (v?: TValue) => void;
  onBlur: () => void;
  getFocus: (fn: FnFocus) => void;
};

export type PropsCellBox<TValue, Res> = {
  cellKey: string; // Должен быть уникальным для таблицы. То есть, включает id строки и колонки
  value: TValue;
  store: SheetStore;
  // Так как сохранение каждой ячейки выполняется отдельным запросом (в отличие от традиционного сабмита),
  // то стала актуальной проблема, когда старые долгие запросы не должны перетирать более новые значения, которые сохранились быстрее.
  // Для этого при вызове save используется счётчик актуальности. А onSuccess вызывается только для актуальных результатов.
  save: (value: TValue | undefined) => Promise<Res>;
  // Необходимо обрабатывать onSuccess, чтобы проп value стал соответствовать внутреннему draft-значению. Иначе нарушится правильная работа сохранения при следующих изменениях.
  onSuccess: (result: Res) => void;
  checkValue?: (v?: TValue) => string | null;
  disableEnter?: boolean;
  pos?: CellPos;

  children: (props: PropsCellBoxChildren<TValue>) => React.ReactNode;
};

const arrowPosMap: Record<string, CellPos> = {
  ArrowUp: { x: 0, y: -1 },
  ArrowDown: { x: 0, y: 1 },
  ArrowLeft: { x: -1, y: 0 },
  ArrowRight: { x: 1, y: 0 },
};

export const CellBox = observer(
  <TValue, Res = void>(props: PropsCellBox<TValue, Res>) => {
    const {
      cellKey,
      value,
      store,
      save,
      checkValue,
      children,
      disableEnter,
      onSuccess,
      pos,
    } = props;
    const isActive = store.activeKey === cellKey;
    const getDraft = () => store.getCellValue<TValue>(cellKey);
    const setDraft = (v: TValue | undefined) => {
      store.setCellValue(cellKey, v);
    };

    React.useEffect(() => {
      setDraft(value);
    }, [value]);

    const error = store.getError(cellKey);
    const cancel = () => {
      setDraft(value);
      store.setError(cellKey, null);
    };
    React.useEffect(() => {
      const onKey = (e: KeyboardEvent) => {
        if (e.code === "Escape") {
          cancel();
          return;
        }
        if (!disableEnter && e.code === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          onBlur();
          return;
        }
        const delta = arrowPosMap[e.code];
        if (delta) {
          store.onChangePos(pos, delta.x, delta.y);
          e.stopPropagation();
        }
      };
      if (isActive) {
        window.addEventListener("keydown", onKey, { capture: false });
        return () => window.removeEventListener("keydown", onKey);
      }
      return undefined;
    }, [isActive]);

    const onChange = (newValue?: TValue) => {
      setDraft(newValue);
      store.setError(cellKey, checkValue?.(newValue) ?? null);
    };
    const saveCell = async () => {
      const valueToSave = getDraft();
      if (valueToSave !== value && !error) {
        store.setWait(cellKey);
        try {
          const ver = store.newRequestVersion(cellKey);
          const res = await save(valueToSave);
          if (onSuccess && ver === store.getRequestVersion(cellKey)) {
            onSuccess(res);
          }
        } catch (e) {
          onError(e);
          store.setError(cellKey, e.message);
        } finally {
          store.clearWait(cellKey);
        }
      }
    };
    const onBlur = () => {
      saveCell().catch(onError);
    };
    const fnFocus = React.useRef<FnFocus>();
    const focus = () => {
      fnFocus?.current?.();
    };
    React.useEffect(() => {
      if (pos)
        store.addPos(pos, {
          key: cellKey,
          focus,
        });
    }, [pos]);

    return (
      <div
        tabIndex={isActive ? undefined : 0}
        className={styles.cellBox}
        onFocus={() => {
          if (
            store.tryToSetActive({
              key: cellKey,
              focus,
            })
          ) {
            focus();
          }
        }}
      >
        {!!pos && (
          <div className={styles.pos}>
            {pos.x}, {pos.y}
          </div>
        )}
        <Spin spinning={store.waits.has(cellKey)}>
          {children({
            value: getDraft(),
            onChange,
            onBlur,
            getFocus(fn: FnFocus) {
              fnFocus.current = fn;
            },
          })}
        </Spin>
        {!!error && <div className={styles.cellError}>{error}</div>}
      </div>
    );
  },
);
