import React from "react";
import { SelectDictionaryStore } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectDictionary";
import { Form, Select } from "antd";
import { observer } from "mobx-react-lite";
import { filterOptionByName } from "src/common/filterOptionByName";
import { ZAsrtTableSettings } from "../../../AsrtTableTypes";

interface PropsAsrtTableDictSelect {
  store: SelectDictionaryStore;
  name: keyof ZAsrtTableSettings;
  label: string;
}

export const AsrtTableDictSelect: React.FC<PropsAsrtTableDictSelect> = observer(
  ({ store, name, label }) => (
    <Form.Item name={name} label={label} rules={[{ required: true }]}>
      <Select
        options={store.dictOptions}
        placeholder="Выбрать"
        showSearch
        filterOption={filterOptionByName}
      />
    </Form.Item>
  ),
);
