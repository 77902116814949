import React from "react";
import { SelectDictionaryStore } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectDictionary";
import { observer } from "mobx-react-lite";
import { Flex } from "antd";
import styles from "./AsrtTableDictSection.module.less";
import { AsrtTableDictSelect } from "./AsrtTableDictSelect";

interface PropsAsrtTableDictSection {
  store: SelectDictionaryStore;
}

export const AsrtTableDictSection: React.FC<PropsAsrtTableDictSection> =
  observer(({ store }) => (
    <Flex gap={12} vertical>
      <div className={styles.dict}>Dictionaries</div>
      <div className={styles.box}>
        <AsrtTableDictSelect
          store={store}
          name="dictCollection"
          label="Collection dictionary"
        />
        <AsrtTableDictSelect
          store={store}
          name="dictDrop"
          label="Drop dictionary"
        />
        <AsrtTableDictSelect
          store={store}
          name="dictCategory"
          label="Category dictionary"
        />
      </div>
    </Flex>
  ));
