import { Badge, Spin } from "antd";
import React, { PropsWithChildren } from "react";
import { CountersStore } from "./CountersStore";

type PropsCounterUnreadMessages = PropsWithChildren & {
  count: number;
  loading: boolean;
};
export const CounterUnreadMessages: React.FC<PropsCounterUnreadMessages> = ({
  count,
  loading,
  children,
}) => {
  if (loading) return <Spin spinning>...</Spin>;
  return (
    <Badge count={count} offset={[15, 0]}>
      {children}
    </Badge>
  );
};

export const makeCounterUnreadChatMessages = (
  store: CountersStore | null,
  chatId: string,
  children: React.ReactNode,
) => (
  <CounterUnreadMessages
    loading={!store}
    count={store?.getChatUnreadMessages(chatId) ?? 0}
  >
    {children}
  </CounterUnreadMessages>
);

export const makeCounterUnreadAllMessages = (
  store: CountersStore | null,
  children: React.ReactNode,
) => (
  <CounterUnreadMessages
    loading={!store}
    count={store?.getAllUnreadMessages() ?? 0}
  >
    {children}
  </CounterUnreadMessages>
);
