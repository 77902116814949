import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { MultiStepTaskStore } from "src/components/MultiStepTask/MultiStepTaskStore";
import { loadEntity } from "src/pages/EntityCardPage/apiEntityCard";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { delay } from "src/common/delay";
import { loadChildAttributes } from "src/pages/EntityFiltersPage/EntityList/EntityCopyButton/apiEntityCopy";
import { flattenItems } from "src/common/flattenItems";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { t } from "i18next";
import { ZIdName } from "src/types/ZIdName";
import { multiStepOnRowClick } from "src/components/MultiStepTask/utils";
import { copyEntities } from "../apiCopyEntities";
import { ChildEntsActionType } from "../../ChildEntities.types";
import { ChildEntitiesStore } from "../../ChildEntitiesStore";

const enum CopyStep {
  step1,
  step2,
  copy,
}

type Data1 = {
  srcObjectId: number;
};

export class CopyFromV2Store {
  constructor(
    public readonly entityId: number,
    public readonly parentObject: ZIdName,
  ) {
    makeAutoObservable(this);
  }

  taskStore = new MultiStepTaskStore();

  async init() {
    this.taskStore.open();
    if (this.data1.status === "none") {
      try {
        this.setData1({ status: "wait" });
        const [srcEntity, childAttrs] = await Promise.all([
          loadEntity(this.entityId, { translate: true }),
          loadChildAttributes(this.parentObject.id, { translate: true }),
        ]);
        const srcObjectId = srcEntity.objectId;
        this.createStore1(srcObjectId);
        this.setData1({
          status: "ready",
          result: {
            srcObjectId,
          },
        });
        this.setChildAttributes(
          flattenItems(childAttrs, "linkedAttributes")?.map(
            ({ attributeId }) => attributeId,
          ) ?? [],
        );
      } catch (error) {
        this.setData1({ status: "error", error });
      }
    }
  }

  childAttributes: number[] = [];

  setChildAttributes(attrs: number[]) {
    this.childAttributes = attrs;
  }

  // --- Шаг 1

  data1: RemoteData<Data1> = { status: "none" };

  setData1(data: RemoteData<Data1>) {
    this.data1 = data;
  }

  store1: EntityFiltersPageStore | null = null;

  createStore1(objectId: number) {
    this.store1 = new EntityFiltersPageStore({
      actions: new Set([EntFilterActionType.changeFilterVisible]),
      objectId,
      selectionSettings: { selectionType: "radio" },
      onRowClick: (row) => multiStepOnRowClick(row, this.store1),
    });
  }

  get srcEntity(): ZEntityRow | undefined {
    return this.store1?.tableStore?.selected[0];
  }

  get firstDisabled(): boolean {
    return !this.srcEntity;
  }

  get srcObjName(): string {
    return this.store1?.currObjName || "";
  }

  // --- Шаг 2

  store2 = new ChildEntitiesStore(new Set([ChildEntsActionType.select]));

  get listForCopy(): number[] | undefined {
    return this.store2.tableStore?.selected.map(({ id }) => id);
  }

  get secondDisabled(): boolean {
    return !this.listForCopy?.length;
  }

  get parentObjName(): string {
    return this.parentObject.name;
  }

  // --- Шаг 3

  get isFinalStep(): boolean {
    return this.taskStore.current === CopyStep.copy;
  }

  result: RemoteData<boolean> = { status: "none" };

  setResult(data: RemoteData<boolean>) {
    this.result = data;
  }

  async save(onFinish: () => void): Promise<boolean> {
    try {
      this.setResult({ status: "wait" });
      const { listForCopy } = this;
      if (!listForCopy?.length)
        throw Error(t("No records selected for copying"));
      await copyEntities({
        entities: listForCopy,
        attributes: this.childAttributes,
        parentEntityId: this.entityId,
      });
      this.setResult({ status: "ready", result: true });
      onFinish();
      await delay(600); // Небольшая задержка для показа сообщения об успешном завершении
      this.taskStore.close(); // и закрытие многошаговой задачи
      return true;
    } catch (error) {
      this.setResult({ status: "error", error });
      return false;
    }
  }
}
