import { z } from "zod";

export const zLinkInfoEntity = z.object({
  type: z.literal("ENTITY"),
  id: z.number(),
  // Если указан bsId, то нужно открыть вкладку соответствующего бизнес-сервиса
  bsId: z.number().nullish(),
});

/**
 * Универсальное описание ссылки на страницы приложения
 */
export const zLinkInfo = z.discriminatedUnion("type", [zLinkInfoEntity]);
export type ZLinkInfo = z.infer<typeof zLinkInfo>;
