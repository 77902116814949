import { makeAutoObservable } from "mobx";
import {
  copyBomsToEntity,
  loadBom2Summary,
} from "src/businessServices/services/bsBom2/apiBom2";
import { ZBom2SummaryRow } from "src/businessServices/services/bsBom2/Bom2Types";
import { delay } from "src/common/delay";
import { RemoteData } from "src/common/RemoteData";
import { MultiStepTaskStore } from "src/components/MultiStepTask/MultiStepTaskStore";
import { multiStepOnRowClick } from "src/components/MultiStepTask/utils";
import { singleLoader } from "src/components/tables/singleLoader";
import { TableStore } from "src/components/tables/TableStore";
import { EntFilterActionType } from "src/pages/EntityFiltersPage/EntityFiltersPage.types";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";

export class BomSummaryCopyFromStore {
  constructor(
    public modelObjectId: number,
    public objectServiceId: number,
    public targetModelEntityId: number,
  ) {
    this.store1 = new EntityFiltersPageStore({
      actions: new Set([EntFilterActionType.changeFilterVisible]),
      objectId: this.modelObjectId,
      selectionSettings: { selectionType: "radio" },
      onRowClick: (row) => multiStepOnRowClick(row, this.store1),
    });
    makeAutoObservable(this);
  }

  taskStore = new MultiStepTaskStore();

  async init() {
    this.taskStore.open();
  }

  // --- Step 1
  get selectedModelId(): number | undefined {
    return this.store1.tableStore?.selected[0]?.id;
  }

  get firstDisabled(): boolean {
    return !this.selectedModelId;
  }

  store1: EntityFiltersPageStore;

  // -- Step 2
  get store2() {
    const { objectServiceId, selectedModelId } = this;
    if (!selectedModelId) return null;
    return new TableStore<ZBom2SummaryRow, {}>({
      rowKey: "id",
      fnLoad: singleLoader(
        () => loadBom2Summary(objectServiceId, selectedModelId),
        () => true,
      ),
    });
  }

  get selectedBomIds(): number[] | undefined {
    return this.store2?.selected.map(({ id }) => id);
  }

  get secondDisabled(): boolean {
    return !this.selectedBomIds?.length;
  }

  // -- Step 3
  copyInfo: RemoteData<boolean> = { status: "none" };

  setCopyInfo(data: RemoteData<boolean>) {
    this.copyInfo = data;
  }

  async copy(onFinish: () => void) {
    const { selectedBomIds } = this;
    try {
      this.setCopyInfo({ status: "wait" });
      if (!selectedBomIds?.length) throw Error("No one BOM selected");
      await copyBomsToEntity(selectedBomIds, this.targetModelEntityId);
      this.setCopyInfo({ status: "ready", result: true });
      onFinish();
      await delay(600); // Пауза для показа сообщения об успешном завершении
      this.taskStore.close();
    } catch (error) {
      this.setCopyInfo({ status: "error", error });
    }
  }
}
