import { z } from "zod";
import { zNotifyTemplate } from "../NotifyTemplates/ZNotifyTemplate";

export const zDelayedNotify = zNotifyTemplate.omit({ roleIds: true }).extend({
  roleIds: z.number().array().nullish(),
  receiverAttrIds: z.number().array(),
  days: z.number(),
  time: z.string(),
});

export type ZDelayedNotify = z.infer<typeof zDelayedNotify>;

export type ZDelayedNotifyToSave = Omit<ZDelayedNotify, "id"> & {
  id?: string;
};
