// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-DelayedNotify-NotifyTimePicker-NotifyTimePicker-module__timePicker--amim3 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/DelayedNotify/NotifyTimePicker/NotifyTimePicker.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF","sourcesContent":[".timePicker {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timePicker": `src-pages-ManagementPage-Obj2Tab-DelayedNotify-NotifyTimePicker-NotifyTimePicker-module__timePicker--amim3`
};
export default ___CSS_LOADER_EXPORT___;
