export default {
  translation: {
    "Access denied": "Access denied",
    "Action is irreversible": "Action is irreversible",
    "Actual duration": "Actual duration",
    "Actual start date": "Actual start date",
    "Actual end date": "Actual end date",
    Add: "Add",
    "Add points": "Add points",
    "Add version": "Add version",
    Address: "Address",
    "Adding points": "Adding points",
    "Advanced settings": "Advanced settings",
    all: "all",
    "All data will be deleted": "All data will be deleted",
    "Appeal to technical support is registered":
      "Appeal to technical support is registered",
    Amount: "Amount",
    Apply: "Apply",
    "Are you sure to delete {{count}} persons?":
      "Are you sure to delete {{count}} persons?",
    "Are you sure to delete {{count}} persons?_one":
      "Are you sure to delete {{count}} person?",
    "Are you sure to delete version?": "Are you sure to delete version?",
    "Are you sure to delete selected positions?":
      "Are you sure you want to delete the selected items?",
    "Attached files": "Attached files",
    "Attention!": "Attention!",
    Attributes: "Attributes",
    'Attributes to display the role "{{role}}"':
      'Attributes to display the role "{{role}}"',
    "Authorization required": "Authorization required",
    "Base size": "Base size",
    "Bussiness dictionaries": "Bussiness dictionaries",
    Category: "Category",
    Cancel: "Cancel",
    "Cannot select the current model to copy":
      "Cannot select the current model to copy",
    Change: "Change",
    "Change of state": "Change of state",
    "Change password": "Change password",
    "Change state": "Change state",
    ChangingTheStateOfAnInstance_one: "Changing the state of an instance",
    ChangingTheStateOfAnInstance_other: "Changing the state of an instances",
    "Chat list": "Chat list",
    Clear: "Clear",
    "Click or drag the file/s into the area to upload":
      "Click or drag the file/s into the area to upload",
    Close: "Close",
    Color: "Color",
    "Color manual": "Color manual",
    "Color model": "Color model",
    "Color reference": "Color reference",
    "Collapse menu": "Collapse menu",
    "Columns visibility": "Columns visibility",
    Comment: "Comment",
    "Communication between objects is not configured. Contact your administrator.":
      "Communication between objects is not configured. Contact your administrator.",
    "Contacting tech support": "Contacting tech support",
    Copy: "Copy",
    "Copy to": "Copy TO",
    "Copy to another instance from current instance":
      "Copy TO another instance FROM current instance",
    "Copy from": "Copy FROM",
    "Copy from model": "Copy from model",
    "Copy from template": "Copy from template",
    "Copy to another model": "Copy to another model",
    Copying: "Copying",
    "Copying an instance": "Copying an instance",
    "Copying was successful": "Copying was successful",
    Count: "Count",
    Country: "Country",
    Create: "Create",
    "Create a chat": "Create a chat",
    "Create a task": "Create a task",
    "Create BOM": "Create BOM",
    "Create empty matType": "Create empty {{matType}}",
    "Create from template": "Create from template",
    "Create matType from library": "Create {{matType}} from library",
    "Create request": "Create request",
    "Creating BOM": "Creating BOM",
    "Creating matType from library": "Creating {{matType}} from library",
    "Current password": "Current password",
    "Current state": "Current state",
    Dashboards: "Dashboards",
    "Data not loaded": "Data not loaded",
    "Data updated": "Data update completed",
    "Data will be displayed after saving":
      "Data will be displayed after saving",
    DateFormat: "DD.MM.YYYY",
    DateTimeFormat: "DD.MM.YYYY HH:mm",
    "Delay / override": "Delay / override",
    Delete: "Delete",
    "Delete chat": "Delete chat",
    "Delete selected": "Delete selected",
    "Delete selected tasks?": "Delete selected tasks?",
    "Delete version": "Delete version",
    "Deletion is impossible": "Deletion is impossible",
    "Describe the question": "Describe the question",
    Description: "Description",
    Dictionaries: "Dictionaries",
    "Display available only for created instances":
      "Display available only for created instances",
    Division: "Division",
    "Do you really want to delete the chat ?":
      "Do you really want to delete the chat ?",
    "Do you really want to delete the selected instances?":
      "Do you really want to delete the selected instances?",
    "Do you want to delete the current entry?":
      "Do you want to delete the current entry?",
    "Doesn't match": "Doesn't match",
    "Do you want to delete the selected records?":
      "Do you want to delete the selected records?",
    Done: "Done",
    "Enter a name": "Enter a name",
    "Enter email": "Enter email",
    "Enter the Email you used when registering your account":
      "Enter the email you used to register your account. We will send you a link to reset your password.",
    "Enter information to register on the platform":
      "Enter information to register on the platform",
    "EntityId value not received": "entityId value not received",
    Edit: "Edit",
    Entity_one: "Entity",
    Entity_few: "Entities",
    Error: "Error",
    "Error when accessing the server": "Error when accessing the server",
    "Estimated start date": "Estimated start date",
    "Estimated end date": "Estimated end date",
    "Executed instance creation": "Executed instance creation",
    Filter: "Filter",
    "Find an attribute": "Find an attribute",
    Finish: "Finish",
    "Format: value": "Format: {{value}}",
    From: "From",
    "Full name": "Full name",
    "General information": "General information",
    "Groups and roles": "Groups and roles",
    Heading: "Heading",
    "Hierarchy structure": "Hierarchy structure",
    "History of changes": "History of changes",
    Image: "Image",
    "Image modification": "Image modification",
    "Incorrect current password":
      "The current password you entered is incorrect",
    "Incorrect format of received data": "Incorrect format of received data",
    "Instance of object": "An instance of the {{name}} object",
    "Instructions for working in the system":
      "Instructions for working in the system",
    "Invalid email format": "Invalid email format",
    "Invalid data format:": "Invalid data format for {{file}}",
    "Invalid page URL": "Invalid page URL",
    "Invalid row id in version key": "Invalid row id in version key: {{key}}",
    "Invalid version key": "Invalid version key: {{key}}",
    "Is not selected": "{{parameter}} is not selected",
    "It is required to correctly fill in the fields on the form":
      "It is required to correctly fill in the fields on the form",
    "Letter has been sent": "The letter has been sent",
    Link: "Link",
    Members: "Members",
    Message: "Message",
    Loading: "Loading",
    Location: "Location",
    Login: "Login",
    Logout: "Logout",
    Main: "Main",
    "Main title": "Main",
    Management: "Management",
    "Mark everything as read": "Mark everything as read",
    "Marked by reading": "Marked by reading",
    Material: "Material",
    "Material Type": "Material Type",
    "Maximum number: count": "Maximum number: {{count}}",
    "Maximum total size:": "Maximum total size: {{size}} MB",
    "Menu settings": "Menu Settings",
    "Missing value": "Missing value of {{parameter}}",
    Model: "Model",
    Models: "Models",
    Modify: "Modify",
    Name: "Name",
    "New instance": "New instance",
    "New instance has been created": "A new instance has been created",
    "New instance of object": "A new instance of the {{name}} object",
    "New instances were created": "{{count}} new instances were created",
    "New instances were created_one": "{{count}} new instance were created",
    "New password": "New password",
    "New position": "New position",
    "New task": "New task",
    Next: "Next",
    "No available states for transition": "No available states for transition",
    "No match for path {path}": "No match for path {path}",
    "No selected points to copy": "No selected points to copy",
    "No object specified": "No object specified",
    "No records selected for copying": "No records selected for copying",
    Notifications: "Notifications",
    "Number of hidden columns": "Number of hidden columns: {{count}}",
    "Objects and attributes": "Objects and attributes",
    OK: "OK",
    Or: "Or",
    Overwrite: "Overwrite",
    "Overwrite measurement chart changes?":
      "Overwrite measurement chart changes?",
    Password: "Password",
    "Password change completed": "Password change completed",
    "Password must contain": "Password must contain",
    "Password recovery": "Password recovery",
    Performer: "Performer",
    "Performer load per task per day": "Performer load per task per day",
    Persons: "Persons",
    Plan: "Plan",
    "Plan template": "Plan template",
    "Planned duration": "Planned duration",
    "Planned start date": "Planned start date",
    "Planned end date": "Planned end date",
    "Please, select a source model and click the Copy button.":
      "Please, select a source model and click the Copy button.",
    "Please, select a template instance and click the Copy button.":
      "Please, select a template instance and click the Copy button.",
    "Point name": "Point name",
    Positions: "Positions",
    "Press Ctrl+Enter/Cmd+Enter to apply":
      "Press Ctrl+Enter/Cmd+Enter to apply",
    "Previous step": "Previous step",
    "Previous task": "Previous task",
    Priority: "Priority",
    "Product article": "Product article",
    "Quality details": "Quality Details",
    Question: "Question",
    "Reference is empty": "{{object}} reference is empty",
    Refresh: "Refresh",
    Register: "Register",
    "Registration of object": "Registration of {{obj}}",
    Repeat: "Repeat",
    "Repeat new password": "Repeat new password",
    Reset: "Reset",
    "Restore password": "Restore password",
    "Return to the authorization page": "Return to the authorization page",
    Save: "Save",
    "Saving is not possible. Status: {{status}}":
      "Saving is not possible. Status: {{status}}",
    Scale: "Scale",
    "Scaling will change the dimension values relative to the base size.":
      "Scaling will change the dimension values relative to the base size.",
    Search: "Search",
    "Search by name or description": "Search by name or description",
    Send: "Send",
    "Send link": "Send link",
    "Server error": "Server error",
    Setting: "Setting",
    Select: "Select",
    "Select an instance to copy previously selected instances":
      "Select an instance to copy previously selected instances",
    "Select dependent objects to copy": "Select dependent objects to copy:",
    "Selected positions": "Selected positions",
    "Select positions to copy": "Select positions to copy",
    "Select the desired menu item to start operation":
      "Select the desired menu item to start operation",
    "Select the instance to copy": "Select the instance to copy",
    "Select the instances to copy": "Select the instance to copy",
    "Select the instances to copy previously selected instances":
      "Select the instances to copy previously selected instances",
    "Select the instance to which you want to copy the previously selected instances of the linked object with all relationships":
      "Select the instance to which you want to copy the previously selected instances of the linked object with all relationships",
    "Select the instances where to move the copied instances to":
      "Select the instances where to move the copied instances to",
    "Select the source instance to copy": "Select the source instance to copy",
    Selected: "Selected",
    "Selected instances": "Selected instances",
    "Selected lines count": "{{count}} lines are selected",
    "Selected lines count_one": "{{count}} line is selected",
    "Selecting a point": "Selecting a point",
    "Send message": "Send message",
    "Send registration request": "Send registration request",
    "Short name": "Short name",
    "Sign In": "Sign In",
    Size: "Size",
    "Size line": "Size line",
    "Size scale": "Size scale",
    "Show filter": "Show filter",
    "Show for the period": "Show for the period",
    "Specify the topic": "Specify the topic",
    "SSO Authorization": "SSO Authorization",
    State: "State",
    "State change performed": "State change performed",
    Status: "Status",
    Step: "Step {{count}}",
    Subject: "Subject",
    Submit: "Submit",
    Summary: "Summary",
    Supplier: "Supplier",
    "System is not ready": "System is not ready",
    "Table export": " Table export",
    Task: "Task",
    "Task group": "Task group",
    "Task group / task": "Task group / task",
    "Task group template": "Task group template",
    "Tasks have been deleted": "Tasks have been deleted",
    "Task list": "Task list",
    "Task template": "Task template",
    "Tech support": "Tech support",
    templates: "templates",
    "The field is mandatory": "The field is mandatory",
    "The instance is missing": "The instance is missing",
    "The instances have been deleted": "The instances have been deleted",
    "The link must start with": "The link must start with http:// or https://",
    "The measurement chart points has been copied successfully":
      "The measurement chart points has been copied successfully",
    "The message contains an attachment": "The message contains an attachment",
    "The state has been deleted": "The state has been deleted",
    "The service has not been configured":
      "The service has not been configured",
    "The dictionary must contain at least one entry":
      "The dictionary {{dict}} must contain at least one entry",
    "There are no messages in the chat": "There are no messages in the chat",
    "There is no user with the specified data":
      "There is no user with the specified data",
    "This file format is not supported": "This file format is not supported",
    TimeFormat: "HH:mm:ss",
    To: "To",
    "To home": "To home",
    Total: "Total",
    "Total files size exceeds": "Total files size exceeds {{size}} MB",
    "Total models: {{count}}": "Total models: {{count}}",
    "Total information": "Total information",
    "Total instances count": "Total instances: {{count}}",
    "Total persons count": "Total {{count}} users",
    "Total persons count_one": "Total {{count}} user",
    "Trim size": "Trim size",
    Upload: "Upload",
    "Upload a file": "Upload a file",
    User: "User",
    UserAccount: "User account",
    "Your request has been registered by the technical support service":
      "Your request has been registered by the technical support service",
    "Wait until saving finishes": "Wait until saving finishes",
    "Welcome to the Omnidata platform!": "Welcome to the Omnidata platform!",
    "When saving, multiple instances of the object will be created":
      'When saving, multiple instances of the "{{obj}}" object will be created, one for each selected "{{attr}}" attribute value.',

    actionType: {
      // see ActionTypeName
      ATTRIBUTE: "Attribute",
      OBJECT: "Object",
      ENTITY: "Instance",
    },
    attrType: {
      ARTICLE: "Article",
      BOOLEAN: "Boolean",
      CHILD_OBJECT_ID: "List of dependent objects",
      DATE: "Date",
      DATE_WITH_TIME: "Date and time",
      OBJECT_ID: "Link to another object",
      ONE_CHOICE_DICTIONARY: "Reference",
      FILE: "File",
      FINANCIAL: "Financial",
      FORMULA: "Formula",
      IMAGE: "Image",
      INT: "Integer",
      LINK_TO_EXTERNAL_SOURCE: "Link to external resource",
      LINKED_VALUE: "Link to value",
      MULTIPLE_CHOICE_DICTIONARY: "Multiple choice from a reference",
      NUMERIC: "Fractional number",
      STRING: "Text",
      SYSTEM_LANGUAGE: "System language",
      TIME: "Time",
      USER_FILTER: "Users list",
    },
    groupType: {
      ByDictionary: "By dictionary",
      Mnemonic: "Mnemonic",
      Dictionary: "Dictionary",
    },
    management: {
      AddGroup: "Add group",
      "Confirm deletion of dictionary group":
        "Are you sure you want to delete the dictionary group?",
      "Confirm deletion of dictionary":
        "Are you sure you want to delete the dictionary?",
      "Deleting a dictionary group": "Deleting a dictionary group",
      "Group name": "Group name",
      "New group of dictionaries": "New group of dictionaries",
      "Wrong group type": "Wrong group type",
    },
    Pages: {
      Plans: "Plans",
      Projects: "Projects",
      Reports: "Reports",
    },
    PasswordProps: {
      "at least N characters": "at least {{count}} characters",
      "contain numbers": "contain numbers {{n}}",
      "uppercase Latin letters": "uppercase Latin letters {{n}}",
      "lowercase Latin letters": "lowercase Latin letters {{n}}",
      "special characters": "special characters",
      "password cannot be the same as login":
        "password cannot be the same as login",
      "Not done": "Not done: {{n}}",
    },
    vaidation: {
      required: "Required field",
    },
  },
};
