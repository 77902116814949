import { z } from "zod";
import { zLinkInfo } from "./ZLinkInfo";

export const zExtNotificationStatus = z.enum(["READ", "UNREAD"]);
export type ZExtNotificationStatus = z.infer<typeof zExtNotificationStatus>;

export const zFileSource = z.enum(["CHAT", "CORE"]);

export const zFileInNotification = z.object({
  format: z.string(),
  name: z.string(),
  path: z.string().nullish(), // <-- Пока непонятен смысл, почему не приходит это поле
  source: zFileSource,
});
export type ZFileInNotification = z.infer<typeof zFileInNotification>;

/**
 * Формат сообщений, которые получаем от сервера (при участии WebSocket)
 */
export const zExtNotification = z.object({
  id: z.number(),
  userId: z.string(), // "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  type: z.string(),
  eventType: z.string(),
  subject: z.string(),
  text: z.string(),
  entityName: z.string().nullish(),
  status: zExtNotificationStatus,
  eventTimestamp: z.string(), // "2024-07-17T10:52:23.543Z"
  link: zLinkInfo.nullish(), // Необязательная ссылка, если сообщение связано с какой-то страницей приложения
  file: zFileInNotification.nullish(), // Информация о прикреплённом файле
});

export type ZExtNotification = z.infer<typeof zExtNotification>;
