import { Tag } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropsPersonSelect } from "src/components/PersonSelect";
import { SelectFromReference } from "src/components/SelectFromReference";
import { PersonComplexStore } from "./PersonComplexStore";

type PropsPersonSelectComplex = Omit<PropsPersonSelect, "attr"> & {
  store: PersonComplexStore;
};

export const PersonSelectComplex: React.FC<PropsPersonSelectComplex> = observer(
  ({ store, ...props }) => {
    if (!store.isValid())
      return (
        <Tag color="red">
          Объект не содержит атрибута с типом &ldquo;список пользователей&rdquo;
        </Tag>
      );
    return (
      <SelectFromReference
        {...props}
        loader={async () => store.getOptions()}
        mode="multiple"
        maxTagCount="responsive"
      />
    );
  },
);
