import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Alert } from "antd";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { DropdownChildButton } from "src/components/DropdownChildButton";
import { MultiStepTask } from "src/components/MultiStepTask";
import { MultiStepItem } from "src/components/MultiStepTask/MultiStepItem";
import { LoaderBox } from "src/components/LoaderBox";
import { onError } from "src/common/onError";
import { ZChildEntities } from "src/common/attrEdit/components";
import { ZIdName } from "src/types/ZIdName";
import { nextStep, prevStep } from "src/components/MultiStepTask/utils";
import { MultiStepEntsTable } from "src/components/MultiStepTask/utils/MultiStepEntsTable";
import { CopyFromV2Store } from "./CopyFromV2Store";
import { CopyFromV2Step1 } from "./CopyFromV2Step1";
import { CopyFromV2Step2 } from "./CopyFromV2Step2";

interface PropsCopyFromV2 {
  entityId: number;
  parentObject: ZIdName;
  editorInfo: ZChildEntities | undefined;
  onFinish: () => void;
}

export const CopyFromV2: React.FC<PropsCopyFromV2> = observer((props) => {
  const { entityId, parentObject, editorInfo, onFinish } = props;
  const store = React.useMemo(
    () => new CopyFromV2Store(entityId, parentObject),
    [],
  );
  const start = () => {
    store.init().catch(onError);
  };

  const steps: MultiStepItem[] = [
    {
      title: store.store1?.currObjName || t("Step", { count: 1 }),
      description: t("Select the source instance to copy"),
      next: nextStep(store.firstDisabled),
      drawContent: () => (
        <MultiStepEntsTable>
          <LoaderBox
            remoteData={store.data1}
            drawReady={() => <CopyFromV2Step1 store={store} />}
          />
        </MultiStepEntsTable>
      ),
    },
    {
      title: store.parentObjName || t("Step", { count: 2 }),
      description: t("Select positions to copy"),
      prev: prevStep(),
      next: {
        text: t("Copy"),
        disabled: store.secondDisabled,
      },
      drawContent: () => (
        <CopyFromV2Step2 store={store} editorInfo={editorInfo} />
      ),
    },
    {
      title: t("Copying"),
      icon: store.isFinalStep ? <LoadingOutlined /> : undefined,
      prev:
        store.result.status === "error"
          ? {
              text: t("Previous step"),
            }
          : undefined,
      onActive() {
        store.save(onFinish).catch(onError);
      },
      drawContent: () => (
        <LoaderBox
          remoteData={store.result}
          drawReady={() => (
            <Alert
              type="success"
              showIcon
              message={t("Copying was successful")}
            />
          )}
        />
      ),
    },
  ];

  return (
    <>
      <DropdownChildButton icon={<CopyOutlined />} onClick={start}>
        {t("Copy from")}
      </DropdownChildButton>
      <MultiStepTask
        title={t("Copy from")}
        taskStore={store.taskStore}
        steps={steps}
      />
    </>
  );
});
