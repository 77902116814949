import { makeAutoObservable } from "mobx";
import { SimpleKey } from "src/types/SimpleKey";

const filterFnStd = (opt: CheckListOption, filterKey: string) =>
  opt.label.toLowerCase().includes(filterKey.toLowerCase());

export type CheckListOption = {
  value: string;
  label: string;
};

export class CheckListStore {
  constructor() {
    makeAutoObservable(this);
  }

  filterKey: string = "";

  setFilterKey(key: string) {
    this.filterKey = key;
  }

  options: CheckListOption[] = [];

  setOptions(list: CheckListOption[]) {
    this.options = [...list];
  }

  get finalOptions() {
    return this.options
      .filter((opt) => filterFnStd(opt, this.filterKey))
      .map(({ label, value }) => ({ label: label.trim(), value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  selectedKeysSet = new Set<SimpleKey>();

  setSelectedKeysSet(list: SimpleKey[]) {
    this.selectedKeysSet = new Set<SimpleKey>(list);
  }

  check(key: SimpleKey) {
    this.selectedKeysSet.add(key);
  }

  uncheck(key: SimpleKey) {
    this.selectedKeysSet.delete(key);
  }

  isSelected(key: SimpleKey) {
    const keysStr = this.selectedKeys.map(String);
    return keysStr.includes(String(key));
  }

  isDisabled(value: string): boolean {
    return this.selected.length >= 5 && !this.isSelected(value);
  }

  get selectedKeys() {
    return Array.from(this.selectedKeysSet);
  }

  get selected(): CheckListOption[] {
    return this.options.filter((opt) => this.isSelected(opt.value));
  }

  clear() {
    this.setFilterKey("");
    this.setOptions([]);
    this.setSelectedKeysSet([]);
  }

  reset() {
    this.setSelectedKeysSet([]);
  }
}
