import { FinancialViewStore } from "./FinancialViewStore";
import {
  FinancialViewStoreStd,
  PropsFinancialViewStoreStd,
} from "./FinancialViewStoreStd";

export const createFinancialViewStore = (
  props: PropsFinancialViewStoreStd,
): FinancialViewStore => {
  const store = new FinancialViewStoreStd(props);
  store.init();
  return store;
};
