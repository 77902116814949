import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { loadEntity } from "src/pages/EntityCardPage/apiEntityCard";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import { loadChildAttributes } from "src/pages/EntityFiltersPage/EntityList/EntityCopyButton/apiEntityCopy";
import { flattenItems } from "src/common/flattenItems";
import { TableStore } from "src/components/tables/TableStore";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { selectOnRowClick } from "src/components/tables/selectOnRowClick";
import { ZEntityFiltersChild } from "../../ChildEntitiesStore";
import { copyEntitiesWithParents } from "../apiCopyEntities";

export class CopyInStore {
  constructor(
    public readonly entityId: number,
    public readonly chilEntTableStore: TableStore<
      ZEntityRow,
      ZEntityFiltersChild
    >,
    public readonly parentObjId: number,
  ) {
    makeAutoObservable(this);
  }

  objectId: number | null = null;

  setObjectId(id: number) {
    this.objectId = id;
  }

  childAttributes: number[] = [];

  setChildAttributes(attrs: number[]) {
    this.childAttributes = attrs;
  }

  loading: boolean = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  isDrawerOpen: boolean = false;

  setIsDrawerOpen(flag: boolean) {
    this.isDrawerOpen = flag;
  }

  pageStore: EntityFiltersPageStore | null = null;

  async init() {
    try {
      const [{ objectId }, childAttrs] = await Promise.all([
        loadEntity(this.entityId),
        loadChildAttributes(this.parentObjId),
      ]);
      this.setObjectId(objectId);
      this.setChildAttributes(
        flattenItems(childAttrs, "linkedAttributes")?.map(
          ({ attributeId }) => attributeId,
        ) ?? [],
      );
      this.pageStore = new EntityFiltersPageStore({
        actions: new Set([]),
        objectId,
        selectionSettings: { selectionType: "checkbox" },
        onRowClick: (row) => {
          const tableStore = this.pageStore?.tableStore;
          if (tableStore) selectOnRowClick(row, tableStore);
        },
      });
    } catch (error) {
      onError(error);
    }
  }

  onClose() {
    this.setIsDrawerOpen(false);
    this.pageStore?.tableStore?.setSelected([]);
  }

  async save() {
    try {
      const parentEntityIds = this.pageStore?.tableStore?.selected.map(
        ({ id }) => id,
      );
      if (!parentEntityIds?.length) return;
      const parenEntities = new Set(parentEntityIds);

      this.setLoading(true);
      await copyEntitiesWithParents({
        entities: this.chilEntTableStore.selected.map(({ id }) => id),
        attributes: this.childAttributes,
        parentEntities: parentEntityIds,
      });
      this.onClose();
      if (parenEntities.has(this.entityId)) {
        await this.chilEntTableStore.reload();
      }
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }
}
