import * as React from "react";
import { BusinessServiceDescriptor } from "src/businessServices/BusinessServiseDescriptor";
import { loadAsrtTableSettings, saveAsrtTableSettings } from "./apiAsrtTable";
import { zAsrtTableSettings } from "./AsrtTableTypes";
import { AsrtTableSettings } from "./AsrtTableSettings";

export const bsAssortmentTable: BusinessServiceDescriptor = {
  key: "Assortment table",
  viewer: () => <div>Assortment table under development</div>,
  renderSettings: (_info, store) => (
    <AsrtTableSettings typesMap={store.attrTypesDict} />
  ),
  loadSettings: async (info) => loadAsrtTableSettings(info.objectId),
  async saveSettings(info, values) {
    const settings = zAsrtTableSettings.parse(values);
    await saveAsrtTableSettings(info.objectId, info.id, 1, settings);
  },
};
