import { AxiosRequestConfig } from "axios";
import { t } from "i18next";
import React from "react";
import { apiObjUrl } from "src/common/apiUrl";
import { AttrTypeMap, loadAttrTypeMap } from "src/common/attributes";
import { ZAttrViewInfo, getViewInfo } from "src/common/attrView";
import { ObjectStateLabel } from "src/common/attrView/components/";
import { createItemView2 } from "src/common/attrView/createItemView/createItemView2";
import { loadRefDict } from "src/common/loadRefDict";
import { onError } from "src/common/onError";
import { rest, stdParamsSerializer } from "src/common/rest";
import { AColumn } from "src/components/tables/AsyncTable";
import {
  FnLoad,
  SelectionSetings,
  TableLoadParams,
  TableResponse,
  TableStore,
} from "src/components/tables/TableStore";
import { formatParams } from "src/components/tables/utils/formatParams";
import {
  loadObjectStates,
  makeObjectStatesMap,
} from "src/pages/ManagementPage/Obj2Tab/roles/rolesApi";
import {
  loadObjectAttrinbutesAll,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity, zEntity } from "src/types/ZEntity";
import { ZObjState } from "src/types/ZObjState";
import { z } from "zod";
import { GroupCell2 } from "./GroupCell/GroupCell2";

const preloadRefs = async (
  attrMetaMap: Record<number, ZAttribute>,
  typeMap: AttrTypeMap,
) => {
  try {
    const uniqueRefs: Record<number, Promise<unknown>> = {};
    Object.values(attrMetaMap).forEach((meta) => {
      const isDict =
        typeMap[meta.valueType] === AttrTypeName.dictMulti ||
        typeMap[meta.valueType] === AttrTypeName.dictSingle;
      if (meta.referenceId && isDict) {
        uniqueRefs[meta.referenceId] = loadRefDict(meta.referenceId, {
          translate: true,
        });
      }
    });
    const uniqueRefsList = Object.values(uniqueRefs);
    await Promise.all(uniqueRefsList);
  } catch (error) {
    onError(error);
  }
};

const schemaTableData = z.object({
  totalElements: z.number(),
  content: zEntity.array(),
});

/*
Данное соглашение достигнуто в результате диалога с бэкендом
договорились, что статус имеет фиктивный id = -1
*/
const createStateCol = (statesLabels: Record<number, string>) => ({
  key: "-1",
  title: t("State"),
  render(_: string[], record: ZEntity) {
    const { objectId, stateId } = record as ZEntity;
    return stateId ? (
      <ObjectStateLabel statesLabels={statesLabels} stateId={stateId} />
    ) : (
      objectId
    );
  },
});

export const loadTableData = async <TFilters extends object>(
  url: string,
  params: TableLoadParams<TFilters>,
  config?: AxiosRequestConfig,
) => {
  const respTableData = await rest.post(url, params.filters, {
    params: formatParams(params),
    ...config,
    paramsSerializer: stdParamsSerializer,
  });
  return schemaTableData.parse(respTableData.data);
};

const entityTableLoaderStd =
  <TRow extends {}, TFilters extends {}>(): FnLoad<TRow, TFilters> =>
  async (params: TableLoadParams<TFilters>): Promise<TableResponse<TRow>> => {
    // получаем список entity
    const entityTableData = await loadTableData(
      apiObjUrl("/entities/search"),
      params,
    );

    return {
      totalItems: entityTableData.totalElements,
      rows: entityTableData.content as unknown as TRow[],
    };
  };

type ColumnsConfig = {
  withoutStatus?: boolean;
};

export const createColsFromAttrMeta = (
  attrsList: ZAttribute[],
  attrTypeMap: AttrTypeMap,
  statesLabels: Record<number, string>,
  config?: ColumnsConfig,
): AColumn<ZEntity>[] => {
  const viewInfoMap = attrsList.reduce(
    (acc, curr) => {
      const info = getViewInfo(curr.viewType);
      if (info) acc[String(curr.id)] = info;
      return acc;
    },
    {} as Record<string, ZAttrViewInfo>,
  );

  const groupMap = attrsList.reduce(
    (acc, curr) => {
      const attrViewInfo = viewInfoMap[String(curr.id)];
      const key = attrViewInfo?.appearance?.column?.group.name;
      if (key) {
        if (!acc[key]) acc[key] = [];
        acc[key]?.push(curr);
      }
      return acc;
    },
    {} as Record<string, ZAttribute[]>,
  );

  const colsWithGroups = Object.values(groupMap).map((attrList) => {
    const key = attrList.map((attr) => attr.id).join("/");
    const title = attrList.map((attr) => attr.name).join("/");
    return {
      key,
      title,
      render(_: string[], row: ZEntity) {
        return (
          <GroupCell2
            attrsList={attrList}
            attrTypeMap={attrTypeMap}
            viewInfoMap={viewInfoMap}
            row={row}
          />
        );
      },
    };
  });
  const attsWithoutGroup = attrsList.filter(
    (attr) => !viewInfoMap[attr.id]?.appearance?.column?.group.name,
  );

  const hasSorter = (attr: ZAttribute) => !attr.referenceId;
  const cols = attsWithoutGroup.map((attribute) => {
    const viewInfo = getViewInfo(attribute.viewType);
    return {
      // сортировку добавляем только столбцам с простыми типами атрибутов
      sorter: hasSorter(attribute),
      key: String(attribute.id),
      dataIndex: String(attribute.id),
      title: attribute.name,
      render(_: string[], row: ZEntity) {
        return createItemView2(attribute, attrTypeMap, row, viewInfo, {
          entityId: row.id,
        });
      },
    };
  });
  const finalCols = [createStateCol(statesLabels), ...cols, ...colsWithGroups];
  if (config?.withoutStatus) finalCols.shift();
  return finalCols;
};

const createSettingKey = (id: number) => `entityTableSettings${id}`;

export const compoundEntityTableStore2 = async <
  TRow extends {},
  TFilters extends {},
>(
  objectId: number,
  rowKey: keyof TRow,
  initialParams?: Partial<TableLoadParams<TFilters>>,
  selectionSettings?: SelectionSetings<TRow>,
  onLoad?: () => void,
  statesLoader?: () => Promise<ZObjState[]>,
) => {
  const attrsTypeMap = await loadAttrTypeMap();
  const attrsMap = makeObjectAttributesMap(
    await loadObjectAttrinbutesAll(String(objectId), { translate: true }),
  );
  // Заранее загружаем справочники в кэш, чтобы не было постепенной загрузки внутри таблицы
  await preloadRefs(attrsMap, attrsTypeMap);
  const statesRes = await (statesLoader
    ? statesLoader()
    : loadObjectStates(objectId, { translate: true }));
  const statesMap = makeObjectStatesMap(statesRes);
  const columns = createColsFromAttrMeta(
    Object.values(attrsMap),
    attrsTypeMap,
    statesMap,
  );
  const tableStore = new TableStore<TRow, TFilters>({
    rowKey,
    initialParams,
    fnLoad: entityTableLoaderStd(),
    settingsKey: createSettingKey(objectId),
    selectionSettings,
    onLoad,
    resizableColumns: true,
  });
  tableStore.setColumns(columns);
  return tableStore;
};
