import React from "react";
import { observer } from "mobx-react-lite";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { SlideMenu } from "./SlideMenu";
import { EntityFiltersPageStore } from "../EntityFiltersPageStore";
import { CheckListWithOrder } from "../CheckListWithOrder/CheckListWithOrder";
import { ControlButtons } from "../AttsHierarchy/ControlButtons";

type PropsSlideMenuAttrList = {
  store: EntityFiltersPageStore;
  onClose: () => void;
  open: boolean;
};

export const SlideMenuAttrList: React.FC<PropsSlideMenuAttrList> = observer(
  ({ store, open, onClose }) => (
    <SlideMenu
      onClose={() => {
        onClose();
      }}
      open={open}
      title={t("Filter")}
      headerExtra={
        <Input
          allowClear
          size="large"
          placeholder={t("Find an attribute")}
          addonAfter={<SearchOutlined />}
          onChange={(str) => {
            store.cLOrderedStore.checkListStore.setFilterKey(str.target.value);
          }}
        />
      }
      footer={
        <ControlButtons
          onReset={() => {
            store.cLOrderedStore.reset();
            store.resetFilterTree();
          }}
          onOk={() => {
            store.resetFilterTree();
            store.saveEntitySearchState();
            onClose();
          }}
        />
      }
    >
      <CheckListWithOrder store={store.cLOrderedStore} />
    </SlideMenu>
  ),
);
