import React, { useState } from "react";
import { Button } from "antd";
import { SettingFilled } from "@ant-design/icons";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { ChatListStore } from "../../ChatListStore";
import { ChatSettingsModal } from "./ChatSettingsModal";

type PropsEditChatButton = {
  chatListStore: ChatListStore;
};

export const EditChatButton: React.FC<PropsEditChatButton> = observer(
  ({ chatListStore }) => {
    const {
      props: { personComplexStore },
    } = chatListStore;
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const openModal = () => setOpen(true);
    const chatFormStore = chatListStore.chats.find(
      ({ listenerId: chatId }) => chatId === chatListStore.activeChatId,
    );
    const chatInfo = chatFormStore?.chatInfo;
    if (!chatInfo) return null;
    return (
      <>
        <Button type="text" onClick={openModal}>
          <SettingFilled />
          {t("Edit")}
        </Button>
        {open && (
          <ChatSettingsModal
            modalTitle={t("Edit")}
            okBtnText={t("Save")}
            complexStore={personComplexStore}
            submit={(data) =>
              chatListStore.updateChat(chatInfo.id, data.name, data.userIds)
            }
            onClose={closeModal}
            initial={chatFormStore.initialData}
          />
        )}
      </>
    );
  },
);
