// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-Sheet-CellBox-module__cellBox--VEgT2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.src-components-Sheet-CellBox-module__cellBox--VEgT2 > .ant-spin-nested-loading {
  height: 100%;
}
.src-components-Sheet-CellBox-module__cellBox--VEgT2 > .ant-spin-nested-loading > .ant-spin-container {
  height: 100%;
}
.src-components-Sheet-CellBox-module__cellBox--VEgT2 .src-components-Sheet-CellBox-module__pos--omBfv {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  z-index: 1;
}
.src-components-Sheet-CellBox-module__cellError--qouit {
  color: red;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sheet/CellBox.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EAMA,kBAAA;AAJJ;AADI;EACI,YAAA;AAGR;AAFQ;EAAkC,YAAA;AAK1C;AAXA;EAWQ,kBAAA;EACA,oCAAA;EACA,YAAA;EACA,UAAA;AAGR;AACA;EACI,UAAA;EACA,eAAA;AACJ","sourcesContent":[".cellBox {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    & > :global(.ant-spin-nested-loading) {\n        height: 100%;\n        & > :global(.ant-spin-container) {height: 100%;}\n    }\n\n    position: relative;\n    .pos {\n        position: absolute;\n        background-color: rgba(0,0,0,0.1);\n        color: white;\n        z-index: 1;\n    }\n}\n\n.cellError {\n    color: red;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cellBox": `src-components-Sheet-CellBox-module__cellBox--VEgT2`,
	"pos": `src-components-Sheet-CellBox-module__pos--omBfv`,
	"cellError": `src-components-Sheet-CellBox-module__cellError--qouit`
};
export default ___CSS_LOADER_EXPORT___;
