import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { FormObjectSectionsStore } from "src/components/Forms/FormObjectSection";
import { SelectDictionaryStore } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/SelectDictionary";

export type AsrtTableObjType = "asrtTable";

export const asrtTableSettingsStore = makeAutoObservable({
  async init(attrTypesDict: Record<number, string>) {
    try {
      await Promise.all([
        this.formSectionsStore.init(attrTypesDict),
        this.selectDict.init(),
      ]);
    } catch (e) {
      onError(e);
    }
  },
  formSectionsStore: new FormObjectSectionsStore<AsrtTableObjType>(),
  selectDict: new SelectDictionaryStore(),
});

export type AsrtTableSettingsStore = typeof asrtTableSettingsStore;
