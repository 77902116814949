import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Dropdown, MenuProps } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { BomSummaryCopyFrom } from "./BomSummaryCopyFrom";
import { BomSummaryCopyTo } from "./BomSummaryCopyTo";

interface PropsBomSummaryActions {
  modelObjectId: number;
  modelEntityId: number;
  objectServiceId: number;
  selectedBomIds: number[];
  reloadSummary: () => void;
}

export const BomSummaryActions: React.FC<PropsBomSummaryActions> = observer(
  (props) => {
    const {
      modelObjectId,
      modelEntityId,
      objectServiceId,
      reloadSummary,
      selectedBomIds,
    } = props;
    const menuProps: MenuProps = {
      items: [
        {
          key: "copyIn",
          label: (
            <BomSummaryCopyTo
              modelObjectId={modelObjectId}
              modelEntityid={modelEntityId}
              selectedBomIds={selectedBomIds}
              onFinish={reloadSummary}
            />
          ),
        },
        {
          key: "copyFrom",
          label: (
            <BomSummaryCopyFrom
              modelObjectId={modelObjectId}
              targetModelEntityId={modelEntityId}
              objectServiceId={objectServiceId}
              onFinish={reloadSummary}
            />
          ),
        },
      ],
    };
    return (
      <Dropdown menu={menuProps} placement="bottomRight">
        <Button icon={<MoreOutlined />} />
      </Dropdown>
    );
  },
);
