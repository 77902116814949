import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { ZRCCounters, getRCCounters, readAllMessages } from "../apiChat";
import { CountersStore } from "./CountersStore";

type PropsCountersStoreStd = {
  entityId: string;
};
class CountersStoreStd implements CountersStore {
  constructor(readonly props: PropsCountersStoreStd) {
    makeAutoObservable(this);
  }

  rcCounters: ZRCCounters | null = null;

  setRСCounters(data: ZRCCounters) {
    this.rcCounters = data;
  }

  async init() {
    try {
      const { entityId } = this.props;
      this.setRСCounters(await getRCCounters(entityId));
    } catch (error) {
      onError(error);
    }
  }

  getCountersData() {
    return this.rcCounters;
  }

  getAllUnreadMessages(): number {
    return (
      this.getCountersData()?.unReads.reduce(
        (acc, curr) => acc + curr.unreadMessagesCount,
        0,
      ) ?? 0
    );
  }

  getChatUnreadMessages(chatId: string): number {
    return (
      this.getCountersData()?.unReads.reduce(
        (acc, curr) =>
          chatId === curr.roomId ? acc + curr.unreadMessagesCount : acc,
        0,
      ) ?? 0
    );
  }

  readAllChatMessages(chatId: string): Promise<void> {
    return readAllMessages(chatId)
      .then(() => {
        const data = this.rcCounters?.unReads.find(
          ({ roomId }) => roomId === chatId,
        );
        if (data) data.unreadMessagesCount = 0;
        if (this.rcCounters) this.setRСCounters({ ...this.rcCounters });
      })
      .catch(onError);
  }

  onChange(newData: ZRCCounters): void {
    this.setRСCounters(newData);
  }
}

export const makeCounterStoreStd = (
  props: PropsCountersStoreStd,
): CountersStore => {
  const rcCountersStore = new CountersStoreStd(props);
  rcCountersStore.init();
  return rcCountersStore;
};
