import { observer } from "mobx-react-lite";
import React from "react";
import { Space, Switch } from "antd";
import { t } from "i18next";
import { EntityFiltersPageStore } from "../../EntityFiltersPageStore";
import styles from "./FilterSwitch.module.less";

interface FilterSwitchProps {
  store: EntityFiltersPageStore;
}

export const FilterSwitch: React.FC<FilterSwitchProps> = observer(
  ({ store }) => (
    <Space className={styles.container}>
      <Switch
        onChange={(checked) => store.setSiderVisible(checked)}
        value={store.siderVisible}
      />
      {t("Show filter")}
    </Space>
  ),
);
