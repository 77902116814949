/**
 * Типы данных для атрибута запрашиваются из справочника при помощи getIdNames(attrType)
 * id напрямую не используются. Вместо этого идет привязка к name.
 */

import { ZIdName } from "./ZIdName";

export const enum AttrTypeName {
  article = "ARTICLE",
  boolean = "BOOLEAN",
  childEntities = "CHILD_OBJECT_ID",
  contentLanguage = "SYSTEM_LANGUAGE",
  date = "DATE",
  dateTime = "DATE_WITH_TIME",
  dictMulti = "MULTIPLE_CHOICE_DICTIONARY",
  dictSingle = "ONE_CHOICE_DICTIONARY",
  externalLink = "LINK_TO_EXTERNAL_SOURCE",
  file = "FILE",
  financial = "FINANCIAL",
  formula = "FORMULA",
  image = "IMAGE",
  int = "INT",
  linkedValue = "LINKED_VALUE",
  number = "NUMERIC",
  object = "OBJECT_ID",
  string = "STRING",
  time = "TIME",
  usersList = "USER_FILTER",
}

export const makeDictNameById = (list: ZIdName[]): Record<number, string> =>
  list.reduce(
    (dict, { id, name }) => ({ ...dict, [id]: name }),
    {} as Record<number, string>,
  );

/**
 * Является ли указанный тип атрибута словарём
 * @param valueType
 * @param types словарь типов id => name
 * @returns
 */
export const isAttrDictionary = (typeName: string | undefined) =>
  typeName === AttrTypeName.dictSingle || typeName === AttrTypeName.dictMulti;
