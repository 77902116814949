import * as React from "react";
import { t } from "i18next";
import { Flex } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { StepButton } from "../MultiStepItem";

export const nextStep = (disabled?: boolean): StepButton => ({
  text: (
    <Flex gap={8}>
      <span>{t("Next")}</span>
      <RightOutlined />
    </Flex>
  ),
  disabled,
});
