import { z } from "zod";

export const zAttrFormula = z.object({
  editor: z.literal("Formula"),
  formula: z.string(),
  // Поскольку в расчетах теперь участвуют атрибуты не только из текущего объекта, нужно предзаполнять доп поле, содержащее и ссылки на другие объекты
  // Для корректной работы формул их придется их пересохранить
  argumentAtts: z.number().array().optional(),
  precision: z.number().nullable().optional(),
  addonBefore: z.string().nullable().optional(),
  addonAfter: z.string().nullable().optional(),
});
export type ZAttrFormula = z.infer<typeof zAttrFormula>;
export const makeFormulaProps = (src: ZAttrFormula) => ({
  precision: src.precision ?? undefined,
  addonBefore: src.addonBefore ?? undefined,
  addonAfter: src.addonAfter ?? undefined,
});
