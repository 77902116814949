import * as React from "react";
import { observer } from "mobx-react-lite";
import { MultiStepTask } from "src/components/MultiStepTask";
import { t } from "i18next";
import { MultiStepItem } from "src/components/MultiStepTask/MultiStepItem";
import {
  lastStep,
  nextStep,
  prevStep,
} from "src/components/MultiStepTask/utils";
import { EntityFiltersTable } from "src/pages/EntityFiltersPage/EntityFiltersTable";
import { MultiStepEntsTable } from "src/components/MultiStepTask/utils/MultiStepEntsTable";
import { McCopyFromStore } from "./McCopyFromStore";
import { McSelectTable } from "../McSelectTable";

interface PropsMcCopyFrom {
  store: McCopyFromStore;
  onFinish(): void;
}

export const McCopyFrom: React.FC<PropsMcCopyFrom> = observer(
  ({ store, onFinish }) => {
    const { store1 } = store;
    const steps: MultiStepItem[] = [
      {
        title: t("Model"),
        next: nextStep(store.firstDisabled),
        drawContent: () =>
          store1 ? (
            <MultiStepEntsTable>
              <EntityFiltersTable store={store1} />
            </MultiStepEntsTable>
          ) : null,
      },
      {
        title: "Points",
        onActive(fromPrevStep) {
          if (!fromPrevStep) store.initStep2();
        },
        prev: prevStep(),
        next: nextStep(store.secondDisabled),
        drawContent: () => <McSelectTable store={store.store2} />,
      },
      lastStep(t("Copying"), store.copyInfo, () => store.copy(onFinish)),
    ];
    return (
      <MultiStepTask
        title={t("Copy from model")}
        steps={steps}
        taskStore={store.taskStore}
      />
    );
  },
);
